import React from 'react';
import { useSelector } from 'react-redux';

// const RoundCircle = ({ timeString }) => {
//     return (
//         <div className="position-relative mx-auto" style={{ width: "160px", height: "160px" }}>
//             {/* Full circle with turquoise border */}
//             <div
//                 className="rounded-circle border border-4 border-success"
//                 style={{ width: "100%", height: "100%" }}
//             ></div>
//             {/* Time string inside the circle */}
//             <div className="position-absolute top-50 start-50 translate-middle text-success">
//                 <span className="fs-4 fw-medium">{timeString}</span>
//             </div>
//         </div>
//     );
// };

const VideoCallWaiting = ({ time }) => {
    // console.log("11111")
    const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
    return (
        <>
            <div className='d-flex flex-column justify-content-around align-items-center' style={{ height: "100%" }}>
                <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                    <div className="max-w-md mx-auto px-4 py-8 text-center">
                        <h2 tyle={{ fontSize: "20px", fontWeight: "600" }}>
                            Thank you for your patience
                        </h2>
                    </div>

                    <p className="text-black-600 mb-8 text-center" style={{ fontSize: '14px',lineHeight: '18px' }} >
                        All our agents are currently engaged <br /> with other customers, but we'll be<br />
                        with you shortly in
                    </p>
                    {/* Round circle with timer */}
                    {time &&
                        <div style={{
                            background: cssProperties?.button?.color,
                            padding: "0.5rem 1rem",
                            borderRadius: '8px',
                            color: cssProperties?.button?.text_color

                        }}>
                            {/* <RoundCircle timeString={time} /> */}
                            {time}
                        </div>
                    }
                </div>
                <img src='/images/hourglass2.gif' style={{ width: "120px", height: "120px" }}/>        
                <div className='position-fixed' style={{ bottom: "2rem" }}>
                    <p className="text-xl text-gray-600 mt-8" >
                        Please wait...
                    </p>
                </div>
            </div>
        </>
    );
};

export default VideoCallWaiting;
