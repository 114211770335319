import {
    ACTION_100MS_START_RECORDING,
    ACTION_100MS_STOP_RECORDING,
    ACTION_CREATE_100MSLIVE_ROOM,
    ACTION_CREATE_MANAGEMENT_TOKEN,
    ACTION_CREATE_ROOM_CODE,
    ACTION_CREATE_VIDEO_SESSION_REQ,
    ACTION_CREATE_VIDEO_TOKEN_LIVEKIT_REQ,
    ACTION_CREATE_VIDEO_TOKEN_REQ,
    ACTION_CREATE_VIDEO_TOKEN_RES,
    ACTION_DISABLE_ROOM,
    ACTION_GET_SPEECH_PITCH,
    ACTION_LIVEKIT_DELETEROOM_REQ,
    ACTION_OPENVIDU_ISSUE,
    ACTION_STORE_AGENT_DISCREPACY_STATUS,
    ACTION_STORE_VCIPREF,
    ACTION_UPLOAD_PAN_CAPTURED_IMAGE_BY_AGENT_REQ
} from "./SagaActionTypes"

export const createVideoSessionSagaAction = (payload) => {
    return {
        type: ACTION_CREATE_VIDEO_SESSION_REQ,
        payload: payload,
        // videoUrl:videoUrl
    }
}

export const createVideoTokenSagaAction = (payload) => {
    return {
        type: ACTION_CREATE_VIDEO_TOKEN_REQ,
        payload: payload,
        // videoUrl:videoUrl
    }
}

export const createVideoTokenSagaActionResponse = (payload) => {
    return {
        type: ACTION_CREATE_VIDEO_TOKEN_RES,
        payload: payload
    }
}

export const openViduSessionIssues = (payload) => {
    return {
        type: ACTION_OPENVIDU_ISSUE,
        payload: payload
    }
}

export const createLivekitTokenSagaAction = (payload)=>{
    return {
        type: ACTION_CREATE_VIDEO_TOKEN_LIVEKIT_REQ,
        payload: payload
    }
}

//livekit deleteroom
export const livekitdeleteroom = (payload) => ({
    type: ACTION_LIVEKIT_DELETEROOM_REQ,
    payload
})

export const uploadPanCapturedImageSagaAction = (payload)=>{
    return {
        type: ACTION_UPLOAD_PAN_CAPTURED_IMAGE_BY_AGENT_REQ,
        payload: payload
    }
}

export const create100msLiveRoomSagaAction = (payload)=>{
    return {
        type: ACTION_CREATE_100MSLIVE_ROOM,
        payload: payload
    }
}

export const create100msLiveRoomCodeSagaAction = (payload)=>{
    return{
        type:ACTION_CREATE_ROOM_CODE,
        payload:payload
    }
}

export const disableRoom100msLiveSagaAction = (payload)=>{
    return{
        type:ACTION_DISABLE_ROOM,
        payload:payload
    }
}

export const actionCreateManagementToken = (payload)=>{
    return{
        type:ACTION_CREATE_MANAGEMENT_TOKEN,
        payload:payload
    }
}

export const action100msLiveStartRecording = (payload) => {
    return {
      type: ACTION_100MS_START_RECORDING,
      payload: payload
    }
  }
  
  export const action100msLiveStopRecording = (payload) => {
    return {
      type: ACTION_100MS_STOP_RECORDING,
      payload: payload
    }
  }

  export const storeAgentDiscrepanctStatus = (payload)=>{
    return {
        type:ACTION_STORE_AGENT_DISCREPACY_STATUS,
        payload:payload
    }
  }

  export const storeVcipref = (payload)=>{
    return {
        type:ACTION_STORE_VCIPREF,
        payload:payload
    }
  }




