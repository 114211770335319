import React, { useState, useEffect } from "react";
function TokenTimer(props) {
  const [timeInSec, setTimeinSec] = useState(props.expireTime || 20);
  let intervalCount;

  useEffect(() => {
    const expTimerInSession = sessionStorage.getItem("tokenEpireTime");
    if(expTimerInSession){
      setTimeinSec(expTimerInSession);
    }else {
      sessionStorage.setItem("tokenEpireTime",props.expireTime);
      setTimeinSec(props.expireTime);
    }
    const intervalCount = setInterval(() => {
      setTimeinSec(time => time ? time-1 : 0);
    }, 1000);
    // Cleanup function to clear the interval when component unmounts or effect re-runs
    return () => {
      clearInterval(intervalCount);
      sessionStorage.removeItem("tokenEpireTime");
    }
  }, [props.expireTime]);

  // useEffect(()=>{
  //   console.log("preint clear")
  //   return () => {
  //       clearInterval(intervalCount);
  //       sessionStorage.removeItem("tokenEpireTime");
  //   }
  // },[])

  useEffect(()=>{
    sessionStorage.setItem("tokenEpireTime",timeInSec);
  },[timeInSec])

  const getMinutes =() => ('0' + Math.floor(timeInSec / 60)).slice(-2);
  const getSeconds = () => ('0' + parseInt(timeInSec % 60)).slice(-2);
  return (
    <div className="timer-wrapper">
      {`${getMinutes()}:${getSeconds()}`}
    </div>
  );
}
export default TokenTimer;