import * as SagaActionTypes from "../SagaActions/SagaActionTypes";
import base64 from "base-64";

const user = sessionStorage.getItem("user");
const parsedData = JSON.parse(user);
const decodeColors = parsedData ? base64?.decode(parsedData?.colorcode) : null;
const storedClientName = parsedData ? parsedData?.acccode : "";

const defaultDynamicURLObj = {
  streamingType: "",
  streamingURL: "",
}
const dynamicURLObj = sessionStorage.getItem('dynamicObj');
const decData = base64?.decode(dynamicURLObj);
const dynamicStreamURLData = dynamicURLObj ? JSON.parse(decData) : defaultDynamicURLObj;

const initial = {
  isLoading: false,
  apiStatus: 0,
  cssProperties: decodeColors
    ? JSON.parse(decodeColors)
    : {
      global_font_family: "",
      theme_bg_color: "",
      pagetitle_heading: {
        font_color: "",
        header_tag: "",
        font_type: "",
        font_size: "",
      },
      sub_heading: {
        font_color: "",
        header_tag: "",
        font_type: "",
        font_size: "",
      },
      body: {
        font_color: "",
        header_tag: "",
        font_type: "",
        font_size: "",
      },
      input_label: {
        font_color: "",
        header_tag: "",
        font_type: "",
        font_size: "",
      },
      input_value: {
        font_color: "",
        header_tag: "",
        font_type: "",
        font_size: "",
      },
      input_box_size: "",
      checkbox_size: "",
      button: {
        color: "",
        text_color: "",
        font_type: "",
        font_size: "",
      },
      caret_color: "",
      tickmark_icon_color: "",
      radio_button_color: "",
      progressbar_color: "",
      list_bullet_point_color: "",
      close_button_color: "",
      toaster: {
        font_color: "",
        font_type: "",
        font_size: "",
      },
    },
  isPageDataAvailable: "",
  clientName: storedClientName ? storedClientName : "",
  dynamicSettingObj: dynamicStreamURLData,
  storeAgentDiscrepancyStatus:"",
  vcipref:"",
};

const HomeReducer = (state = initial, action) => {
  switch (action.type) {
    case SagaActionTypes.APISTATUS:
      if (action.payload) {
        return { ...state, apiStatus: state.apiStatus + 1 };
      } else {
        return { ...state, apiStatus: state.apiStatus - 1 };
      }

    case SagaActionTypes.DYNAMIC_CSS_PROPERTIES:
      return { ...state, cssProperties: action.payload };

    case SagaActionTypes.STORE_PAGE_DATA:
      return { ...state, isPageDataAvailable: action.payload };

    case SagaActionTypes.DYNAMIC_CLIENT_NAME:
      return { ...state, clientName: action.payload };

    case SagaActionTypes.ACTION_STORE_AGENT_DISCREPACY_STATUS:
      return {...state,storeAgentDiscrepancyStatus:action.payload}
    
    case SagaActionTypes.ACTION_STORE_VCIPREF:
      return {...state,vcipref:action.payload}

    case SagaActionTypes.ACTION_DYNAMIC_STREAMING_URL:
      // return { ...state, dynamicSettingObj: {...action.payload, enableGetCutomerKey:"true"} };
      return { ...state, dynamicSettingObj:action.payload}
    //   return { ...state, dynamicSettingObj:
    //     {
    //       "respcode": "200",
    //       "respdesc": "Success",
    //       "enableAgentLiveMonitoring": "false",
    //       "transactionCode": "VUlIRjM4NEhJRkw0OU8zOTRIRjlXTEVJOTQ5V09GSFc=",
    //       "enableEncryption": "false",
    //       "HundredMsTemplateId": "65dcce95b472e9c0b0cb6ef4",
    //       "streamingURL": "https://lq-uat-livekit.syntizen.app/stream-api/",
    //       "streamingType": "2",
    //       "gleapId": "Klm8RJ242nEvbGgXfWmpM2QrsQOddWeo",
    //       "dynamicUserConsent": "true",
    //       "openreplayKey": "2r47cawrA5OfVPWXC81F",
    //       "openreplayURL": "https://openreplay.syntizen.app/ingest",
    //       "app_env": "lq_uat",
    //       "is_ekyc": "true",
    //       "newCustomerWaitingNotification": "true",
    //       "sessionsBaseKEY": "T1BFTlZJRFVBUFA6TVlfU0VDUkVU",
    //       "sessionsBaseURL": "https://uat-streaming.syntizen.app:8443/openvidu/api",
    //       "clientAccount": "1",
    //       "isProteanClient": "false",
    //       "isNicClient": "0",
    //       "enableAgentLiveSocket": "false",
    //       "enableAccountAdmin": "true",
    //       "enableAdminSocket": "false",
    //       "enablePanRecapture": "true",
    //       "enableUserConsent": "true",
    //       "is_onlineKyc": "true",
    //       "is_concurentauditors": "true",
    //       "enableStreamLoadBalance": "false",
    //       "showCustomerDataOnPreview": "true",
    //       "enableGetCutomerKey": "true",
    //       "enableOccupationdropdown": "true",
    //       "getBankDetails":"true"
    //   }
    // };

    default:
      return state;
  }
};

export default HomeReducer;
