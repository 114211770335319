import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import base64 from "base-64";
import SelectPANProcessCmp from "../../Components/PanCmp";
import RouteNames from "../../Constants/RouteNames";
import Header from "../../Layout/Header";
import AppFooter from "../Common/AppFooter";
import { Text } from "../Language/Language";
import toast from "react-hot-toast";
import CleintNames from "../../Constants/ClientNames";
import { useSelector } from "react-redux";

const SelectPANProcess = () => {
  const [uploadimgPath, setImgPath] = useState("");
  const [base64URL, setBase64URL] = useState(false);
  const [fileimg, setFileimg] = useState(null);
  const [selctedPANStep, setSelctedPANStep] = useState("")
  const [epanPassword,setEpanPassword]=useState("")
  const [uploadPANImgError, setUploadPANImgError] = useState("");
  let navigate = useNavigate();
  window.onpopstate = () => {
    navigate(null);
  }
  const agentDiscrepancyStatus = useSelector((state)=>state.HomeReducer.storeAgentDiscrepancyStatus)
  const agentDiscrepancy = sessionStorage.getItem("agentDiscrepancyStatus")

  useEffect(()=>{
    if(agentDiscrepancyStatus == "0" || agentDiscrepancy =="0"){
      sessionStorage.setItem("backButtonHappenAferEnd",true)
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    }
  },[])
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { skipUploadPanInCustomer } = dynamicSettingObj
  const skipUploadPan = skipUploadPanInCustomer == "true" ? true : false

  // console.log("444444", CleintNames ?.abc)

  const handleFileInputChange = (target) => {
    const file = target.files[0];
    // if (!file?.name.match(/\.(jpg|jpeg|png|gif|jfif|gif|tiff|bmp|Eps|webp|svg)$/)) {
    //   toast('Please upload valid image.');
    //   setImgPath("");
    //   setFileimg("");
    //   return;
    // }
    setImgPath("");
    setFileimg("");
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        let img = file.base64.split(",")[1];
        let filename = file.name;
        setImgPath(img);
        setFileimg(filename);
        setBase64URL(true);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handelPasswordInputChange=(key)=>{
    // const encodePwd = base64.encode(key);
    setEpanPassword(key)
    // console.log(key)
  }

  const selectPanProcess = (e, selectedStep) => {
    const { name } = e.target;
    setSelctedPANStep(selectedStep);
    setEpanPassword("")
    setFileimg(null)
    setBase64URL(false)
    setImgPath("")
  }

  const goToNextStep = () => {
    if (selctedPANStep === "CAPTUREPAN") {
      if(iskinabank){
        navigate(RouteNames.KINA_OVD_CAPTURE)
      }else{
        navigate(RouteNames.PAN_CAPTURE)
      }
     
    } 

    if( (selctedPANStep === "UPLOADPAN" && uploadimgPath)) {
      if(iskinabank){
        navigate(RouteNames.KINA_OVD_PROCESS,{
          state:{uploadimg:uploadimgPath}
        })
      }else{
        navigate(RouteNames.PAN_KYC_PROCESS,{
          state:{uploadimg:uploadimgPath}
        })
      }
      
    }
      else if(selctedPANStep === "UPLOADPAN"){
      toast(<Text tid="upload_image_proceed"/>)
    }

    if( (selctedPANStep === "EPAN" && uploadimgPath && epanPassword)) {
      navigate(RouteNames.PAN_KYC_PROCESS,{
        state:{uploadimg:uploadimgPath,epanPassword:epanPassword}
      })
      console.log(uploadimgPath,epanPassword)
    }
      else if(selctedPANStep === "EPAN" && !uploadimgPath){
      toast.error("upload image & proceed")
    }
      else if(selctedPANStep === "EPAN" && !epanPassword){
      toast.error("Enter the Password & proceed")
    }
  }
  const vcipref = sessionStorage.getItem("vcipref")
  const homepage = `/d=${vcipref}`
  
  return (
    <>
      {<Header
        title={iskinabank?<Text tid="ovd_verification"/>:<Text tid="pan_capture" />}
        navigate={() => navigate(homepage, { replace: true })}
        hideHeader={false}
      />}
      <article className="app-body">
        <SelectPANProcessCmp
          fileimg={fileimg}
          selctedPANStep={selctedPANStep}
          selectPanProcess={selectPanProcess}
          handleFileInputChange={handleFileInputChange}
          iskinabank={iskinabank}
          uploadPANImgError={uploadPANImgError}
          handelPasswordInputChange={handelPasswordInputChange}
          skipUploadPan={skipUploadPan}
        />
        <div className="pankycbutton" style={{backgroundColor:"#FFFFFFF2",paddingBottom:"3%"}}>
        <AppFooter
          btnName={<Text tid="start"/>}
          navigate={goToNextStep}
          isDisabled={selctedPANStep  ? false : true}
        />
        </div>
      </article>
      {/* </>
            } */}
    </>
  );
};

export default SelectPANProcess;
