import React from "react";
import { useEffect, useRef, useState } from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../../Constants/RouteNames";
import "react-html5-camera-photo/build/css/index.css";
import Header from "../../../Layout/Header";
import P from "../../../Components/Elements/P";
import CleintNames from "../../../Constants/ClientNames";
import { useSelector } from "react-redux";
import "../../../Components/TideComponents/tideglobal.css";
import toast from "react-hot-toast";
import { Text } from "../../Language/Language";
const PanCapture = () => {
  const navigate = useNavigate();
  const [isCameraBtnEnabled, setIsCameraBtnEnabled] = useState(false)

  window.onpopstate = () => {
    navigate(null);
  }

  const agentDiscrepancyStatus = useSelector((state)=>state.HomeReducer.storeAgentDiscrepancyStatus)
  const agentDiscrepancy = sessionStorage.getItem("agentDiscrepancyStatus")

  useEffect(()=>{
    if(agentDiscrepancyStatus == "0" || agentDiscrepancy =="0"){
      sessionStorage.setItem("backButtonHappenAferEnd",true)
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    }
  },[])
  const goback = () => {
    // alert("go oback")
    // console.log("1111111111111");
    navigate(-1);
  };

  const retakePan = () => {
    // alert("sf")
  };
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false
  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;


  useEffect(() => {
    document.getElementById("container-circles").style.pointerEvents = "none";
    if (isCameraBtnEnabled) {
      document.getElementById("container-circles").style.pointerEvents = "all";
    }
  }, [isCameraBtnEnabled])


  const handleTakePhoto = (dataUri) => {
    const base64result = dataUri.split(",")[1];
    // setImgPath(base64result);
    // setBase64URL(true);
    if (iskinabank) {
      navigate(RouteNames.KINA_OVD_PROCESS, {
        state: { capturedImage: base64result },
      });
    } else {
      navigate(RouteNames.PAN_KYC_PROCESS, {
        state: { capturedImage: base64result },
      });
    }

  };

  function handleCameraError(error) {
    console.log('handleCameraError--------------->', { error });
    toast(<Text tid="please_close_other_AppsTabsSessions_that_are_accessing_the_Camera_and_Microphone_currently_and_Hit_below_refresh_button"/>);
  }


  function handleCameraStart(stream) {
    if (stream?.active) {
      setIsCameraBtnEnabled(true)
    } else {
      setIsCameraBtnEnabled(false)
    }
  }

  function handleCameraStop() {
    setIsCameraBtnEnabled(false)
  }
  return (
    <>
      {isClientTideAccess ? (
        <>
          <div className="pan_bg_tide">
            <div className="camerabackground">
              <Camera
                isImageMirror={false}
                onCameraError={(error) => handleCameraError(error)}
                onTakePhoto={(dataUri) => {
                  if (dataUri.length > 100) {
                    handleTakePhoto(dataUri);
                  }
                }}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                onCameraStart={(stream) => { handleCameraStart(stream); }}
                onCameraStop={() => { handleCameraStop(); }}
              />
            </div>
            <div className="camerapositioninfo">
              <div className="camerapositioninfomain">
                <img src="/images/Icon.png" />
                <div className="camerapositioninfotext"><Text tid="position_the_front_of_the_PAN_card_in_the_frame"/></div>
              </div>
              <div className="camerapositioninfomain">
                <img src="/images/Icon.png" />
                <div className="camerapositioninfotext">
                  <Text tid="please_ensure_you_capture_an_image_of_your_physical_PAN_card_ePAN_images_are_not_accepted" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header title={iskinabank ? "ID" : "PAN"} navigate={""} hideHeader={true} />
          <div className="pan_bg">
            <div className="">
              <Camera
                isImageMirror={false}
                onCameraError={(error) => handleCameraError(error)}
                onTakePhoto={(dataUri) => {
                  if (dataUri.length > 100) {
                    handleTakePhoto(dataUri);
                  }
                }}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                onCameraStart={(stream) => { handleCameraStart(stream); }}
                onCameraStop={() => { handleCameraStop(); }}
              />
            </div>
            <div className="VerificationTextStyled">
              <P className="txt text-center" txt={iskinabank?<Text tid="capture_id_card"/>:<Text tid="capture_pan_card"/>} color={isCashbook && "#FFFFFF"} fontWeight={isCashbook && "400"} />
            </div>
            <div className="VerificationSmallTextStyled">
              <P
                className="txt text-center"
                txt={iskinabank?<Text tid="position_the_front_of_the_ID_card_in_the_frame"/>:<Text tid="position_the_front_of_the_PAN_card_in_the_frame"/>}
                color={isCashbook && "#FFFFFF"}
              />
            </div>
          </div>
          <div className="camera-flex">
            <div className="camera-div">
              <div className="camera-content">
                <p onClick={goback}><Text tid="cancel"/></p>
                <p className="text-muted"> <Text tid="retake"/></p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PanCapture;
