import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { createLivekitTokenSagaAction, livekitdeleteroom, uploadPanCapturedImageSagaAction } from "../../../Store/SagaActions/VideoSagaActions";
import "./streaming.css";
// import Timer from "react-timer-wrapper";
// import Timecode from "react-timecode";

import {
  connect,
  Room,
  RoomEvent,
  RemoteParticipant,
  RemoteTrackPublication,
  RemoteTrack,
  Participant,
  Track,
  VideoPresets,
  createLocalTracks,
  LocalVideoTrack,
  EngineEvent,
} from "livekit-client";
import ChatCmp from "../../../Components/ChatCmp/ChatCmp";
import InitiatingVideoCallCmp from "../../../Components/VideoCallCmp/InitiatingVideoCallCmp";
import ChatCmpChime from "./ChatCmpChime";
import {
  actionNoficationListSaga,
  endVideoCallByAgentSagaAction,
  pushNotificationSaga,
  streamingEventByCustomerAction,
} from "../../../Store/SagaActions/CommonSagaActions";
import CleintNames from "../../../Constants/ClientNames";
import RouteNames from "../../../Constants/RouteNames";
import { Text } from "../../Language/Language";
import customeCodes from "../../../Constants/customeCodes";
import BackCameraAccessModal from "../../../Components/BackCameraAccessModal/BackCameraAccessModal";
import CallTimer from "../../../Components/TimeCounter";
import { actionGetSingleCustomer, deleteCustomerAction, updateCustomerRedisAction } from "../../../Store/SagaActions/InitiateVideoCallSagaActions";
import VideoCallWaiting from "../../../Components/VideoCallWaiting";
import moment from "moment";
import { useRef } from "react";
import { requestWakeLock, releaseWakeLock } from "../../../utils/wake";

const state = {
  isFrontFacing: false,
  encoder: new TextEncoder(),
  decoder: new TextDecoder(),
  // defaultDevices: new Map < MediaDeviceKind, string> (),
  // bitrateInterval: undefined as any,
};

const DataPacket_Kind = {
  RELIABLE: 0,
  LOSSY: 1,
  UNRECOGNIZED: -1,
};

var globalRoomData = "";
var _localVideotracksObj;
var isCallEndedByAgent = false;
var countImageCapture = 0;
var remotelementcount = 0;
var remoteelement = [];
var enabledEndCallBtnTimer;
var notifyEndCallMsg;
var getCustomerStatusInterval;
var isMinimizedScreen = false;
var autoEndCallTime;
var autoDropCount = "0";

const InitiatingVideoCall = (props) => {
  const [cameraerror, setCameraerror] = useState("");
  const [room, setRoom] = useState(undefined);
  const [customerendcall, setEndcall] = useState(undefined);
  const [track, setTrack] = useState("");
  const [videotracks, setVideotrack] = useState("");
  const [enablevideo, setEnablevideo] = useState(true);
  const [LocalTrackPublication, setLocalTrackPublication] = useState({});
  const [letbot, setletBot] = useState(false);
  const [notifymsgcount, setnotifymsgcount] = useState(0);
  const [message, setMessage] = useState("");
  const [bulk, setBulk] = useState([]);
  const [notifymsgstate, setNotifymsgstate] = useState(false);
  const [remotevideoelement, setRemotevideoelement] = useState([]);
  const [notificationList, setNotificationList] = useState({});
  const [isEnbledEndCallBtn, setisEnbledEndCallBtn] = useState(false);
  const [customerNetSpeed, setCustomerNetSpeed] = useState(0);
  const [agentRoomStatus, setAgentRoomStatus] = useState("");
  const [isRoomCreated, setIsRoomCreated] = useState(false);
  const [capturedPanImage, setCapturedPanImage] = useState("");
  const [iscapturedPanImageDone, setIsCapturedPanImageDone] = useState(0);
  const [showLiveImageCircleSvg, setShowLiveImageCircleSvg] = useState(false);


  const [localVideoState, setLocalVideoState] = useState(true);
  const [allowcameraflip, setAllowcameraflip] = useState(false);
  const [canvasdisplay, setCanvasdisplay] = useState(false);
  const [recapturepan, setRecapturepan] = useState(false);
  const [cameramirror, setCameramirror] = useState(true);
  const [cameraAccessError, setCameraAccessError] = useState("");
  const [imageName, setImageName] = useState("")
  const [customerJoinsCall, setCustomerJoinsCall] = useState(false)
  const [autoEndCallTimeForCustomerDrop, setAutoEndCallTime] = useState("00:00");
  const [agentJoinStatus, setAgentJoinStatus] = useState(false)
  const [getSingleCustomerResData, setGetSingleCustomerResData] = useState("")
  // const [flipCamera,setFlipCamera] = useState(false)
  // const [isMinimizedScreen,setIsMinimized] = useState(false)
  // const streamingType = process.env.REACT_APP_STREAMING_TYPE;

  const dispatch = useDispatch();
  // const params = useParams();
  const params = props?.params;
  var notificationIntervalId;
  const waittimer = sessionStorage.getItem("waittimer")
  autoEndCallTime = waittimer;
  var apiCount = 0;
  const navigate = useNavigate();
  const isPageDataAvailable = useSelector(
    (state) => state.HomeReducer.isPageDataAvailable
  );
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const isusfb = (clientName === CleintNames?.USFB || clientName === CleintNames?.USFBSYNTIZEN) ? true : false;
  const streamingEventObj = useSelector(
    (store) => store.VcipReducer.streamingEventObj
  );

  const videoRef = useRef(null);

  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { streamingType, streamingURL, enableStreamLoadBalance, enableGetCutomerKey, app_env,customerAutoDropCall } = dynamicSettingObj;
  const isEnableStreamLoadBalalnce = enableStreamLoadBalance == "true"
  const isEnableGetCutomerKey = enableGetCutomerKey == "true"
  const isCustomerAutoDropCall = customerAutoDropCall == "false" ? false : true; 
  const showAllowPopupFromCustomer = dynamicSettingObj?.videoCallConfig?.flow?.panReCap?.showAllowPopupFromCustomer
  const signatureCaptureAllowPopup = dynamicSettingObj?.videoCallConfig?.flow?.signatureCapture?.showAllowPopupFromCustomer

  useEffect(() => {
    remotelementcount = 0;
    remoteelement = []
    // isMinimizedScreen = false;
    console.log("*** initiating video call ");
    if (isPageDataAvailable) {
      // token();
      if (isEnableGetCutomerKey) {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        getCustomerStatus()
        getCameraStream()
      } else {
        const token = sessionStorage.getItem("token");
        const wssURL = sessionStorage.getItem("wssURL");
        initiateLiveKit(token, wssURL);
      }
      if(isCustomerAutoDropCall){
        notifyEndCallEvent();
      }
    } else {
      clearNotificationInterval();
      // clearInterval(enabledEndCallBtnTimer);
      // clearInterval(notifyEndCallMsg);
      clearTimeout(enabledEndCallBtnTimer)
      clearTimeout(notifyEndCallMsg)
      if (sessionStorage.getItem("videoconfsessionid") && !sessionStorage.getItem("AgentSubscribed")) {
        if (isEnableGetCutomerKey) {
          deleteCustomerData()
        }
        if (isMinimizedScreen) {
          navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
        }
        endCallByCustomer("Customer Reloaded");
      }
      // navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    }
    return async () => {
      if (globalRoomData) {
        await globalRoomData?.disconnect();
      }
      if (sessionStorage.getItem("videoconfsessionid") && !sessionStorage.getItem("AgentSubscribed") && isEnableGetCutomerKey) {
        deleteCustomerData()
      }
      if (videoRef.current) {
        await videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        videoRef.current.srcObject = null;
        console.log("local track stopped")
      }
      clearNotificationInterval();
      // clearInterval(getCustomerStatusInterval)
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      // clearInterval(enabledEndCallBtnTimer);
      // clearInterval(notifyEndCallMsg);
      clearTimeout(enabledEndCallBtnTimer)
      clearTimeout(notifyEndCallMsg)
      remotelementcount = 0
      remoteelement = [];
      setShowLiveImageCircleSvg(false);
    };
  }, []);

  // const flipCameraCode = async(cameraturn)=>{
  //   try {
  //     (await _localVideotracksObj) &&
  //     _localVideotracksObj?.restartTrack({
  //       facingMode: cameraturn ? "environment" : "user",
  //       resolution: VideoPresets.h720.resolution,
  //     }).then((res)=>{
  //       console.log(cameraturn,"back camera accesed successfully",res)
  //       if(cameraturn){
  //         toast("back camera accessed")
  //       }else{
  //         toast("front camera accessed")
  //       }
  //     })
        
  //     .catch((err)=>{
  //       console.log("5555",err)
  //       toast.error("Unable to Access Back Camera",{
  //         duration: 5000,
  //       })
  //     })
  //   }
  //    catch (error) {
  //     console.log("11111111111",error)
  //   }
  // }

  // useEffect(()=>{
  //   if(flipCamera){
  //     flipCameraCode(true)
  //   }else{
  //     flipCameraCode(false)
  //   }
  // },[flipCamera])

  // useEffect(() => {
  //   const activeStatus = isMinimizedScreen ? "400" : "200";
  //   updateCustomerFunc(activeStatus);
  // }, [isMinimizedScreen]);


  const updateCustomerFunc = (activeStatus)=>{
    const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
    const model = {
      streamingURL:  isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
      body: {
        videoconfsessionid: sessionStorage.getItem("videoconfsessionid"),
        ref1:activeStatus
      }
    }
    dispatch(updateCustomerRedisAction({
      model: model,
      callback:()=>{}
    }))
  }

  const getCameraStream = async()=>{
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing the camera', err);
    }
  }

  const handleVisibilityChange = () => {
    const activeStatus = document?.hidden ? "400" : "200";
    
    isMinimizedScreen = document?.hidden
    // if (document.hidden) {
    //   // console.log("hidden");
    //   setIsMinimized(true)
    //   // clearInterval(getCustomerStatusInterval)
    // } else {
    //   // console.log("alive");
    //   setIsMinimized(false)
    //   // getCustomerStatus()
    // }

    updateCustomerFunc(activeStatus);
  };

  const getCustomerStatus = () => {
    // getCustomerStatusInterval = setInterval(() => {
      // if (isMinimizedScreen == false) {
        const model = {
          streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
          body: {
            videoconfsessionid: sessionStorage.getItem("videoconfsessionid")
          }
        }
        dispatch(actionGetSingleCustomer({
          model,
          callback: getSingleCustomerRes,
          handleError:handleGetSingleCustomerErr
        }))
      // }
    // }, 2000)
  }




  const getSingleCustomerRes = async (data) => {  
    if (data?.respcode == 404) {
      navigate(RouteNames?.KYC_PROCESS_COMPLETED)
      return;
    }  
    setGetSingleCustomerResData(data?.data)
    if (data?.respcode == "200" && data?.data?.customerJoinStatus == "1" && isMinimizedScreen == false && remotevideoelement?.length <= 1 ) {
      if (videoRef.current) {
        await videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        videoRef.current.srcObject = null;
        console.log("local video track stopped")
      }
      setCustomerJoinsCall(true)
      // clearInterval(getCustomerStatusInterval)
      const token = sessionStorage.getItem("token");
      const wssURL = sessionStorage.getItem("wssURL");
      initiateLiveKit(token, wssURL);
    }else{
      setTimeout(()=>{
        getCustomerStatus()
      },2000)
    }
  }

  const handleGetSingleCustomerErr = (err)=>{
    if(err){
      setTimeout(()=>{
        getCustomerStatus()
      },2000)   
    }
  }

  useEffect(() => {
    requestWakeLock();

    return () => {
      releaseWakeLock();
    };
  }, []);

  useEffect(() => {
    if ((showAllowPopupFromCustomer == false && notificationList?.notificationid == "4") || (signatureCaptureAllowPopup == false && notificationList?.notificationid == "4")) {
      setAllowcameraflip(false)
      setAllowcameraflip(false)
      flipCameraMode(true)
      setCameramirror(false)
      setCanvasdisplay(true)
      setRecapturepan(true)
    }
  }, [showAllowPopupFromCustomer, notificationList?.notificationid, signatureCaptureAllowPopup])

  useEffect(() => {
    if(notificationList?.streamingValue == "finalendcall"){
      sessionStorage.setItem("finalendcall", "finalendcall")
    }
    if (notificationList?.notificationid === "2") {
      endVideoCall();
    }
    if (notificationList?.notificationid === "6" || notificationList?.notificationid == "1" && notificationList?.streamingValue == "instructions") {
      if (notificationList?.streamingValue == "Image captured successfully" || notificationList?.streamingValue == "PAN Image captured successfully") {
        setShowLiveImageCircleSvg(false);
      }
      // setRecapturepan(false);
      // setCanvasdisplay(false);
      var audio = new Audio("/tone.mp3");
      audio.play();
      toast.success(notificationList.notifications, {
        position: "bottom-center",
        duration: 2000,
      });
    }
    if (notificationList?.notificationid == "7") {
      setCanvasdisplay(false);
      if (recapturepan == true) {
        flipCameraMode(false);
        setCameramirror(true)
      }
    }
    if (notificationList?.notificationid == "1" && notificationList?.streamingValue == "Fetching Customer Location") {
      customerlocationsend()
    }
  }, [notificationList]);

  window.onpopstate = () => {
    navigate(null);
  };

  useEffect(() => {
    if (isEnbledEndCallBtn) {
      if (isEnableGetCutomerKey) {
        deleteCustomerData();
      }
      if (isMinimizedScreen) {
        navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      }
      endCallByCustomer("Customer Auto Drop");
      sessionStorage.setItem("isAutoCallEnded", true);
    }
  }, [isEnbledEndCallBtn, isMinimizedScreen]);

  useEffect(() => {
    if (remotevideoelement?.length > 0) {
      console.log(
        "enabledEndCallBtnTimer---> joined",
        enabledEndCallBtnTimer,
        notifyEndCallMsg
      );
      // clearInterval(enabledEndCallBtnTimer);
      // clearInterval(notifyEndCallMsg);
      clearTimeout(enabledEndCallBtnTimer)
      clearTimeout(notifyEndCallMsg)
    }
  }, [remotevideoelement]);

  // STREAMING BASED QUESTION NOTIFICATION SEND
  useEffect(() => {
    if (
      streamingEventObj?.streamingKey ===
      customeCodes.SEND_CUSTOMER_NET_SPEED_TO_AGENT
      // || streamingEventObj?.streamingKey ===
      // customeCodes.CAPTURE_CUSTOMER_PAN_DONE
    ) {
      // console.log("_____streamingEventObj", streamingEventObj);
      sendMessage(streamingEventObj);
    }
  }, [streamingEventObj]);

  //panrecature and livephoto
  useEffect(() => {
    if (iscapturedPanImageDone > 0) {
      countImageCapture = iscapturedPanImageDone;
      const model = {
        streamingKey: customeCodes.CAPTURE_CUSTOMER_PAN_DONE,
        streamingValue: isEnableGetCutomerKey ? imageName : capturedPanImage,
        notificationid: "30",
        notifications: isEnableGetCutomerKey ? imageName : capturedPanImage
      };
      sendMessage(model);
    }
  }, [capturedPanImage, iscapturedPanImageDone, imageName]);

  const sendMessage = (notify) => {
    // console.log("=====>send", notify);
    const msg = state.encoder.encode(JSON.stringify(notify));
    room?.localParticipant?.publishData(msg, DataPacket_Kind.RELIABLE);
  };

  const notifyEndCallEvent = () => {
    const autoDropBeforeFiveSeconds = (autoEndCallTime-5)
    notifyEndCallMsg = setTimeout(() => {
      var audio = new Audio("/tone.mp3");
      audio.play();
      toast.success(
        <Text tid="As_an_agent_could_not_connect_the_call_will_be_ending_in_5_sec_Please_try_again" />,
        {
          position: "bottom-right",
          duration: 4000,
        }
      );
    }, 1000 * (autoDropBeforeFiveSeconds));

  };

  useEffect(() => {
    const duration = moment.duration(autoEndCallTime,'seconds');
    const autoDropMinutes = Math.floor(duration.asMinutes());
    const autoDropSeconds = duration.seconds();
  // Return the formatted time string
    const minutes = autoEndCallTimeForCustomerDrop.split(":")[0]
    const seconds = autoEndCallTimeForCustomerDrop.split(":")[1]
    if (minutes >= autoDropMinutes && seconds >= autoDropSeconds && !agentJoinStatus && isCustomerAutoDropCall) {
      setisEnbledEndCallBtn(true)
    }
  }, [autoEndCallTimeForCustomerDrop])

  const clearNotificationInterval = () => {
    if (notificationIntervalId) {
      clearInterval(notificationIntervalId);
    }
  };

  // END VIDEO CALL
  const endVideoCall = async (data) => {
    if (notificationList?.notificationid !== "2" && apiCount === 0 && data) {
      // setIsPushNtfyDone(true);
      const model = {
        notificationid: "3",
        vcipkey: sessionStorage.getItem("vcipkey"),
        notifymsg: data,
      };
      dispatch(
        pushNotificationSaga({ model, callback: pushNotificationRespData })
      );
    } else if (notificationList?.streamingValue == "finalendcall") {
      sessionStorage.setItem("finalendcall", "finalendcall")
      if (room) {
        await room?.disconnect();
      }
      if (globalRoomData) {
        await globalRoomData?.disconnect();
      }
      // await window.location.reload()
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    }
    else {
      if (room) {
        await room?.disconnect();
      }
      if (globalRoomData) {
        await globalRoomData?.disconnect();
      }
      // await window.location.reload()
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    }
  };

  const pushNotificationRespData = async (data) => {
    // localStorage.setItem("reloaderr","customer ended call")
    const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
    const model = {
      streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
      body: {
        roomName: params.id
      }
    }
    sessionStorage.removeItem("videoconfsessionid")
    navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    if (isEnableGetCutomerKey) {
      deleteCustomerData()
    } else {
      dispatch(livekitdeleteroom({ model: model, callback: deleteroomdata }))
    }
    // apiCount += 1;
    // // setTimeout(() => {
    // // sessionStorage.setItem("agentendcall","agentendcall")
    // if (room) {
    //   await room?.disconnect();
    // }
    // if (globalRoomData) {
    //   await globalRoomData?.disconnect();
    // }
    // await window.location.reload()
    // }, 300);
  };

  const deleteroomdata = () => {
  }

  const deleteCustomerData = () => {
    
    const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
    const model = {
      streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
      body: {
        "videoconfsessionid": params?.id
      }
    }
    dispatch(deleteCustomerAction({
      model,
      callback: deleteCustomerRes
    }))
  }

  const deleteCustomerRes = () => {
    // window.location.reload()
    // navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
  }

  const endCallByCustomer = (data) => {
    dispatch(endVideoCallByAgentSagaAction({ callback: (res) => getEndCallRespData(res, data) }));
  };

  const getEndCallRespData = (res, data) => {
    if (res?.respcode === "200") {
      endVideoCall(data);
    }
  };

  const token = () => {
    if (params.id) {
      const model = {
        streamingURL: streamingURL,
        body: {
          room: params.id,
          participantName: `customer_${params.id}`,
          canSubscribe: true,
        }
      };
      dispatch(
        createLivekitTokenSagaAction({
          model: model,
          callback: getLivekitToken,
        })
      );
    } else {
      toast.error("room id not found");
    }
  };

  const getLivekitToken = (data) => {
    initiateLiveKit(data?.token, data?.wssURL);
  };

  const initiateLiveKit = async (token, wssURL) => {
    const roomOptions = {
      // automatically manage subscribed video quality
      adaptiveStream: true,
      // optimize publishing bandwidth and CPU for published tracks
      dynacast: true,
      // default capture settings
      videoCaptureDefaults: {
        resolution: VideoPresets.h720.resolution,
      },
      audioCaptureDefaults: {
        autoGainControl: true,
        //   deviceId: '',
        echoCancellation: true,
        noiseSuppression: true,
      },
    };
    const _roomData = new Room(roomOptions);
    // set up event listeners
    _roomData
      .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
      .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
      .on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
      .on(RoomEvent.LocalTrackUnpublished, handleLocalTrackUnpublished)
      .on(RoomEvent.MediaDevicesError, handleMediaDeviceError)
      .on(RoomEvent.Disconnected, handleDisconnect)
      .on(RoomEvent.RecordingStatusChanged, startRecording)
      .on(RoomEvent.TrackMuted, micmute)
      .on(RoomEvent.ConnectionQualityChanged, handleConnectionQualityChanged)
      .on(RoomEvent.DataReceived, handleData);

    let isRoomCreated = false;
    await _roomData
      .connect(wssURL, token)
      .then((res) => {
        console.log("________ROOM CONNECTED!");
        isRoomCreated = false;
        setIsRoomCreated(false);
      })
      .catch((err) => {
        console.log("______ROOM NOT CONNECTED", err);
        isRoomCreated = true;
        setIsRoomCreated(true);
      });
    // const devices = _roomData?.getLocalDevices('videoinput');
    // console.log("++++++++++++++++globalRoomDataglobalRoomData", _roomData, tracks);
    setRoom(_roomData);
    // console.log("connected to room", _roomData?.name, _roomData);
    globalRoomData = _roomData;
    if (isRoomCreated) {
      toast("Unabled to Proceed. Please try again!");
      return;
    }
    _roomData?.engine?.on(EngineEvent.Connected, (e) =>
      handleRoomStatus(e, "Connected")
    );
    _roomData?.engine?.on(EngineEvent.Closing, (e) =>
      handleRoomStatus(e, "Closing")
    );
    _roomData?.engine?.on(EngineEvent.Disconnected, (e) =>
      handleRoomStatus(e, "Disconnected")
    );
    _roomData?.engine?.on(EngineEvent.Restarted, (e) =>
      handleRoomStatus(e, "Restarted")
    );
    _roomData?.engine?.on(EngineEvent.Restarting, (e) =>
      handleRoomStatus(e, "Restarting")
    );
    _roomData?.engine?.on(EngineEvent.Resumed, (e) =>
      handleRoomStatus(e, "Resumed")
    );
    _roomData?.engine?.on(EngineEvent.Resuming, (e) =>
      handleRoomStatus(e, "Resuming")
    );
    _roomData?.engine?.on(EngineEvent.SignalResumed, (e) =>
      handleRoomStatus(e, "SignalResumed")
    );
    let retrylocaltrackcount = 0
    const localtracks = async () => {
      retrylocaltrackcount += 1;
    
      try {
        // console.log(`Attempt ${retrylocaltrackcount}: Creating local tracks...`);
        const tracks = await createLocalTracks({
          audio: true,
          video: {
            facingMode: "user" // Set this to 'user' for the front camera
          },
        });
    
        // console.log("Created tracks:", tracks);
    
        if (tracks.length > 0) {
          for (let track of tracks) {
            try {
              const videotrack = await _roomData.localParticipant.publishTrack(track);
              // console.log("Published track:", videotrack);
    
              if (
                videotrack.kind === Track.Kind.Video &&
                videotrack.source === Track.Source.Camera &&
                videotrack.source !== Track.Source.ScreenShare
              ) {
                _localVideotracksObj = track;
                // console.log("Local video track:", _localVideotracksObj);
                const v = document.getElementById("us");
    
                if (v) {
                  track.attach(v);
                  // console.log("Attached video track to element:", v);
                } else {
                  // console.error("Video element not found in DOM.");
                }
              }
              if (videotrack.kind === Track.Kind.Audio) {
                setTrack(track);
                // console.log("Set local audio track.");
              }
            } catch (publishError) {
              console.error("Error publishing track:", publishError);
            }
          }
        } else {
          console.error("No tracks created.");
          throw new Error("No tracks created.");
        }
      } catch (err) {
        console.error("Error while creating or publishing local tracks:", err);
    
        if (retrylocaltrackcount < 5) {
          console.log(`Retrying to create local tracks. Attempt ${retrylocaltrackcount}`);
          return localtracks();
        }
    
        if (err.name === "NotAllowedError") {
          toast("Please Enable video permissions!");
        } else if (
          err.name === "DOMException" &&
          err.message === "Permission denied"
        ) {
          toast("Please Enable Microphone permissions!");
        } else if (
          err.name === "DOMException" &&
          err.message === "Could not start video source"
        ) {
          toast("Please Enable Microphone permissions!");
        } else {
          toast.error("Unable to publish video or audio");
          if (isEnableGetCutomerKey) {
            deleteCustomerData();
          }
          endCallByCustomer();
        }
      }
    };
    
    
    localtracks()
  };

  const handleTrackSubscribed = (track, publication, participant) => {
    setisEnbledEndCallBtn(false);
    setAgentJoinStatus(true)
    // clearInterval(enabledEndCallBtnTimer);
    // clearInterval(notifyEndCallMsg);
    clearTimeout(enabledEndCallBtnTimer)
    clearTimeout(notifyEndCallMsg)
    // console.log("===========>", "handleTrackSubscribed", track);
    if (
      (track.kind === Track.Kind.Video &&
        track.source === Track.Source.Camera) ||
      track.kind === Track.Kind.Audio
    ) {
      sessionStorage.setItem("AgentSubscribed", true)
      // attach it to a new HTMLVideoElement or HTMLAudioElement
      const element = track.attach();
      if (track.kind === Track.Kind.Video) {
        element.setAttribute("id", "them");
        element.setAttribute("class", "local");
        element.style.objectFit = "cover";
        element.style.transform = "rotateY(180deg)"
      }
      if (track.kind === Track.Kind.Audio) {
        element.setAttribute("id", "audio-them");
      }
      let remoteEle = document.getElementById("remoteStreaming");
      if (
        track.kind === Track.Kind.Video &&
        track.source == Track.Source.Camera
      ) {
        remoteEle?.appendChild(element);
        setRemotevideoelement(element);
        remoteelement.push(element)
        remotelementcount += 1
      }
      if (
        track.kind === Track.Kind.Audio &&
        track.source == Track.Source.Microphone
      ) {
        remoteEle?.appendChild(element);
      }
    }
  };

  const handleTrackUnsubscribed = async (track, publication, participant) => {
    remoteelement = []
    if (
      (track.kind === Track.Kind.Video &&
        track.source === Track.Source.Camera) ||
      track.kind === Track.Kind.Audio
    ) {
      let remoteVideoEle = document.getElementById("them");
      let remoteAudioEle = document.getElementById("audio-them");
      // console.log("xxxxxxxxxxxxx",remoteVideoEle)
      // console.log("yyyyyyyyyyyyyyy",remoteAudioEle)
      if (remoteVideoEle) {
        track.detach(remoteVideoEle);
        remoteVideoEle?.remove();
      }
      if (remoteAudioEle) {
        track.detach(remoteAudioEle);
        remoteAudioEle?.remove();
      }
      setRemotevideoelement([]);
    }

  };


  const handleLocalTrackUnpublished = (track, participant) => {

    console.log("*********************", "handleLocalTrackUnpublished", track);
    // if (remotelementcount >= 1 && remoteelement?.length < 1) {
    //   endVideoCall()
    // }
  };

  const handleActiveSpeakerChange = (speakers, room) => {
    // console.log("======speakersspeakersspeakers", speakers, room);
    // console.log("2222222222", remotelementcount,remoteelement.length)
    // if (remotelementcount >= 1 && remoteelement?.length < 1) {
    //   endVideoCall()
    // }

  };

  const handleDisconnect = async () => {
    let remoteVideoEle = document.getElementById("them");
    let remoteAudioEle = document.getElementById("audio-them");
    if (remoteVideoEle) {
      track.detach(remoteVideoEle);
      remoteVideoEle?.remove();
    }
    if (remoteAudioEle) {
      track.detach(remoteAudioEle);
      remoteAudioEle?.remove();
    }
    // if (remotelementcount >= 1 && remoteelement?.length < 1) {
    // console.log("sssssssssssssssssss")
    if (room) {
      await room?.disconnect();
    }
    if (globalRoomData) {
      await globalRoomData?.disconnect();
    }
    // console.log("kkkkkkkkkkkk")
    // await window.location.reload()
    navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    // }
  };

  const handleMediaDeviceError = (err) => {
    setCameraerror(
      "another camera app is used camera please close those and come again"
    );
  };

  const startRecording = (recording) => {
    let isRecording = recording;
    isRecording = true;
  };

  const micmute = (muted) => {
    console.log("444444444", muted);
  };

  // const Endsession = async () => {
  //   setEndcall(true);
  //   console.log("ssssssssssssssssssssssss", room);
  //   await room.disconnect();
  //   //   navigate(-1);
  // };

  // TO GET NETWORK QUALITY
  const handleConnectionQualityChanged = (e, a, c) => {
    // setCustomerNetSpeed(e);
    // const model = {
    //   streamingKey: customeCodes.SEND_CUSTOMER_NET_SPEED_TO_AGENT,
    //   streamingValue: e,
    //   notificationid: "2023",
    //   notifications: e
    // };
    // sendMessage(model)
  };

  // TO GET ROOM IS CONNECTED/DISCONNECTED/CLOSING/RESUMING/RESUMED
  const handleRoomStatus = (e, status) => {
    setAgentRoomStatus(status);
    if (
      status === "Resumed" ||
      status === "Connected" ||
      status === "Restarted"
    ) {
      setTimeout(() => {
        setAgentRoomStatus("");
      }, 2000);
    }
  };

  const customerlocationsend = () => {
    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition((this.showPosition));
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position.coords.latitude && position.coords.longitude) {
            const LatandLong = {
              streamingKey: "CUSTOMER_LATITUDE_AND_LONGITUDE",
              streamingValue: `${position.coords.latitude},${position.coords.longitude}`,
              notificationid: "CUSTOMER_LATITUDE_AND_LONGITUDE",
              notifications: "Successfully fetched the customer Location",
            }
            // console.log("ssssssssssssssssssss",position.coords.latitude,position.coords.longitude),
            sendMessage(LatandLong)
            toast.success("Location shared successfully", {
              position: "bottom-center",
              duration: 2000,
            });
          } else {
            const Latlongerror = {
              streamingKey: "CUSTOMER_LOCATION_FAILED",
              streamingValue: "",
              notificationid: "CUSTOMER_LATITUDE_AND_LONGITUDE",
              notifications: "Unable to fetch the customer Location",
            }
            sendMessage(Latlongerror)
            toast.error("Unable to fetch location", {
              position: "bottom-center",
              duration: 2000,
            });
          }
        },
        (error) => {
          const Latlongerror = {
            streamingKey: "CUSTOMER_LOCATION_FAILED",
            streamingValue: "",
            notificationid: "CUSTOMER_LATITUDE_AND_LONGITUDE",
            notifications: "Unable to fetch the customer Location",
          }
          sendMessage(Latlongerror)
          toast.error("Unable to fetch location", {
            position: "bottom-center",
            duration: 2000,
          });
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        }
      );
    } else {
      const Latlongerror = {
        streamingKey: "CUSTOMER_LOCATION_FAILED",
        streamingValue: "",
        notificationid: "CUSTOMER_LATITUDE_AND_LONGITUDE",
        notifications: "Geolocation is not supported by customer browser",
      }
      sendMessage(Latlongerror)
      toast.error("Geolocation is not supported by this browser", {
        position: "bottom-center",
        duration: 2000,
      });
    }
  }

  const handleData = async (msg, participant) => {
    const str = state.decoder.decode(msg);
    // console.log("=====str", str); //
    if (str) {
      let parseObj = JSON.parse(str);
      // console.log("parseObj11111111111", parseObj)
      if (parseObj?.notificationid === "99") {
        var audio = new Audio("/tone.mp3");
        audio.play();
        const temp = {
          sender: 1,
          message: parseObj,
        };
        setBulk((prev) => [...prev, temp]);
        if (!letbot) {
          setnotifymsgcount((prev) => prev + 1);
          setNotifymsgstate(true);
        }
      }
      // if (parseObj?.notificationid && parseObj?.notifications) {
      //   var audio = new Audio("/tone.mp3");
      //   audio?.play();
      // }
      if (
        parseObj?.streamingKey !== customeCodes.SEND_CUSTOMER_NET_SPEED_TO_AGENT
      ) {
        if (parseObj?.notificationid === "2") {
          isCallEndedByAgent = true;
          // if (globalRoomData) {
          //   await globalRoomData?.disconnect();
          // }
          // navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
          // return;
        }
        if (
          parseObj?.notificationid == "4" &&
          parseObj?.notifications == "Flip Camera for PanCapture"
        ) {
          // if(isusfb == false){
          setAllowcameraflip(true);
          setRecapturepan(false);
          // }else{
          //   setRecapturepan(true);
          //   flipCameraMode(true);
          //   setCanvasdisplay(true);
          // }
        }
        if (parseObj?.notificationid === "6") {
          // setRecapturepan(false);
          setCanvasdisplay(false);
          // setNotificationList(parseObj);
          let video = "";
          if (streamingType === customeCodes.STREAMING_TYPE_LIVEKIT_VALUE) { // LIVEKIT
            video = document.getElementById("us");
          } else {
            video = document.querySelector(".streamcomponent .video-play");
          }
          captureCustomerImg(video);
        }
        setNotificationList(parseObj);
      }

      // if (notificationList?.notificationid === "6") {
      //   // setRecapturepan(false);
      //   // setCanvasdisplay(false);
      //   setNotificationList(parseObj);
      //   // setCapturedPanImage("");
      // }
      if (parseObj?.notificationid == "8") {
        setLocalVideoState(!localVideoState);
        setShowLiveImageCircleSvg(true);
      }
    }
  };

  const Disablevideo = async () => {
    console.log("11111111", videotracks);
    setEnablevideo(false);
    await videotracks.stop();
  };
  const Enablevideo = async () => {
    setEnablevideo(true);
    console.log("11111111", videotracks);
    await room.localParticipant.publishTrack(videotracks);
  };

  // Chatting Function
  const handelBotfunction = () => {
    setletBot(!letbot);

    if (letbot) {
      setNotifymsgstate(false);
      setnotifymsgcount(0);
    }
  };

  const changeMessage = (e) => {
    setMessage(e.target.value);
  };

  const sendChatMessage = (event) => {
    const chatMessage = {
      notificationid: "99",
      notifymsg: message,
      msgtime: Date.now(),
    };
    const temp = {
      sender: 0,
      message: chatMessage,
    };
    setBulk((prev) => [...prev, temp]);
    setMessage("");
    // meetingManager?.audioVideo?.realtimeSendDataMessage("signal", chatMessage);
    console.log("=====>send");
    const msg = state.encoder.encode(JSON.stringify(chatMessage));
    room.localParticipant.publishData(msg, DataPacket_Kind.RELIABLE);
  };

  // CHANGE CAMERA FACING MODE
  const flipCameraMode = async (isFacingModeChanged) => {
    if (isFacingModeChanged == true) {
      setCameramirror(false);
    }
    console.log("----------isFacingModeChanged", isFacingModeChanged);
    setCameraerror("");
    try {

      // const localVideo = room?.localParticipant?.getTrackPublication(
      //   Track.Source.Camera
      // );
      // if (!localVideo) {
      //   console.log("No local video track found");
      // }

      // // Stop existing track before switching
      // if (localVideo.videoTrack) {
      //   await localVideo.videoTrack.stop();
      // }

      // Stop the existing track before switching
      if (_localVideotracksObj) {
        await _localVideotracksObj.stop();
      }

      // Add delay before switching camera to avoid NotReadableError
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // const options = {
      //   resolution: VideoPresets.h720.resolution,
      //   facingMode: isFacingModeChanged ? "environment" : "user",
      // };
      // ((await localVideo) && localVideo.videoTrack?.restartTrack(options))
      (await _localVideotracksObj) &&
        _localVideotracksObj?.restartTrack({
          resolution: VideoPresets.h720.resolution,
          facingMode: isFacingModeChanged ? "environment" : "user",
        }).then((res) => console.log("back camera accesed successfully"))
        .catch(async (err) => {
          console.log("camera error", err);
          if (err.name === "NotReadableError" || err.name === "AbortError") {
            // Wait longer and try one more time
            await new Promise((resolve) => setTimeout(resolve, 1000));
            try {
              await  _localVideotracksObj?.restartTrack({
                resolution: VideoPresets.h720.resolution,
                facingMode: isFacingModeChanged ? "environment" : "user",
              })
            } catch (retryErr) {
              console.log("camera retryErr", retryErr);
              toast.error("Unable to Access Back Camera", {
                duration: 5000,
              });
              const backcamissue = {
                streamingKey: "CUSTOMER_BACK_CAMERA_ISSUE",
                streamingValue: "Customer back camera issue",
                notificationid: "CUSTOMER_BACK_CAMERA_ISSUE",
                notifications: "Customer back camera issue",
              };
              sendMessage(backcamissue);
              const model = {
                notificationid: "7",
                vcipkey: sessionStorage.getItem("vcipkey"),
                notifymsg: "Unable to Access Back Camera",
              };
              dispatch(pushNotificationSaga({ model, callback: () => {} }));
              flipCameraMode(false);
              setCameraAccessError(
                "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
              );
            }
          }
        });
    } catch (error) {
      console.log("11111111111", error);
      if (error) {
        setCameraAccessError(
          "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
        );
      }
      // else {
      //   throw error;
      // }
    }
    // try {

    //   console.log("_localVideotracksObj",_localVideotracksObj);
      
    //   (await _localVideotracksObj) &&
    //   _localVideotracksObj?.restartTrack({
    //     facingMode: isFacingModeChanged ? "environment" : "user",
    //     resolution: VideoPresets.h720.resolution,
    //   }).then((res)=>console.log(isFacingModeChanged,"back camera accesed successfully",res))
    //   .catch((err)=>{
    //     console.log("unable to restart tracks",err)
    //     toast.error("Unable to Access Back Camera",{
    //       duration: 5000,
    //     })
    //     const backcamissue= {
    //       streamingKey: "CUSTOMER_BACK_CAMERA_ISSUE", 
    //       streamingValue: "Customer back camera issue",
    //       notificationid: "CUSTOMER_BACK_CAMERA_ISSUE",
    //       notifications: "Customer back camera issue",
    //   }
    //     sendMessage(backcamissue)
    //     flipCameraMode(false)
    //     setCameraAccessError(
    //       "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
    //     );
    //   })
    // }
    //  catch (error) {
    //   console.log("catch error for restart track",error)
    //   if (error) {
    //     setCameraAccessError(
    //       "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
    //     );
    //   } 
    // }
    // try {
    //   const localVideo = room?.localParticipant?.getTrackPublication(Track.Source.Camera)
    //   const options = {
    //     resolution: VideoPresets.h720.resolution,
    //     facingMode: isFacingModeChanged ? "environment" : "user",
    //   };
    //     (await localVideo && localVideo.videoTrack?.restartTrack(options))
    //     .then((res) => console.log("33333", res))
    //       .catch((err) => {
    //         console.log("5555", err)
    //         toast.error("Unable to Access Back Camera", {
    //           duration: 5000,
    //         })
    //         const backcamissue = {
    //           streamingKey: "CUSTOMER_BACK_CAMERA_ISSUE",
    //           streamingValue: "Customer back camera issue",
    //           notificationid: "CUSTOMER_BACK_CAMERA_ISSUE",
    //           notifications: "Customer back camera issue",
    //         }
    //         sendMessage(backcamissue)
    //         const model = {
    //           notificationid: "7",
    //           vcipkey: sessionStorage.getItem("vcipkey"),
    //           notifymsg: "Unable to Access Back Camera",
    //         };
    //         dispatch(
    //           pushNotificationSaga({ model, callback: () => { } })
    //         );
    //         flipCameraMode(false)
    //         setCameraAccessError(
    //           "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
    //         );
    //       })
    // }
    // catch (error) {
    //   console.log("11111111111", error)
    //   if (error) {
    //     setCameraAccessError(
    //       "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
    //     );
    //   }
    // }
  };


  // CAPTURE PAN IMAGE AT CUSTOMER SIDE
  const captureCustomerImg = async (video) => {
    try {
      const canvas = document.getElementById("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      const data = canvas.toDataURL("image/png");
      console.log("complressing ");

      // Function to compress live image
      const compressedImage = canvas.toDataURL('image/jpeg', 0.95);
      const compressedImageTo70 = canvas.toDataURL('image/jpeg', 0.7);
      // console.log("compressed img", compressedImage);
      const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
      const model = {
        streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
        body: {
          name: params.id + "-" + countImageCapture + ".png",
          base64Image: compressedImage,
        }
      };
      const redisUploadPan = {
        redisUploadPan: true
      }
      if (isEnableGetCutomerKey) {
        dispatch(
          uploadPanCapturedImageSagaAction({
            model: model,
            redisUploadPan: redisUploadPan,
            callback: getUploadedPanCapturedImage,
          })
        );
      } else {
        dispatch(
          uploadPanCapturedImageSagaAction({
            model: model,
            callback: getUploadedPanCapturedImage,
          })
        );
      }

    } catch (e) {
      setCapturedPanImage("");
      setIsCapturedPanImageDone(prev => prev + 1);
      // console.log(e, 'try catch error')
    }
  };

  const getUploadedPanCapturedImage = (data) => {
    setCapturedPanImage(data);
    if (isEnableGetCutomerKey) {
      const capturedImageName = params.id + "-" + countImageCapture + ".png"
      setImageName(capturedImageName)
    }
    setIsCapturedPanImageDone(prev => prev + 1);
    // const model = {
    //   streamingKey: customeCodes.CAPTURE_CUSTOMER_PAN_DONE,
    //   streamingValue: data,
    //   notificationid: "30",
    //   notifications: data
    // };
    // sendMessage(model);
  }

  // const flipCameraButtonFunction = ()=>{
  //   setFlipCamera(!flipCamera)
  // }


  return (
    <>
      <article className="app-body" style={{ height: "100vh" }}>
        <canvas
          id="canvas"
          style={{
            objectFit: "fill",
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "none",
          }}
        />
        {
          (app_env === "tc_uat" || app_env === "tc_prod") && 
          isEnableGetCutomerKey ?
            <>
              <div className="mt-2 d-flex justify-content-between align-items-center">
                {/* <i class="fa-solid fa-arrow-left"></i> */}
                <h5 className="text-center m-auto fw-bold text-xl">Initiating video call</h5>
              </div>
              {/* <div className="timer">
              <CallTimer setAutoEndCallTime={setAutoEndCallTime} />
            </div> */}
            </>
            :
            <div className="timer">
              <CallTimer setAutoEndCallTime={setAutoEndCallTime} />
            </div>
        }
        <>
          {remotevideoelement?.length === 0 || !room ? (
            ((app_env == "tc_uat" || app_env == "tc_prod") && isEnableGetCutomerKey ) ?
            <div className="d-flex align-items-center justify-content-center h-100">
                {isEnableGetCutomerKey ? (
                  <>
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <video
                        id="us"
                        className="remote test_rmt_1"
                        ref={videoRef}
                        autoPlay
                        controls={false}
                        playsInline
                      ></video>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div
                  className={`badge position-relative d-flex align-items-center greyStyleBackground`}
                  style={{ zIndex: 9999 }}
                >
                  <VideoCallWaiting
                    time={getSingleCustomerResData?.estimate_averageWaitTime2}
                  />
                </div>
            </div>

                // <VideoCallWaiting
                //   time={getSingleCustomerResData?.estimate_averageWaitTime2}
                // />
              : 
              <div className="d-flex align-items-center justify-content-center h-100">
                <>
                {
                      isEnableGetCutomerKey ?
                      <>
                        <div className="d-flex align-items-center justify-content-center h-100">
                          <video id="us" className="remote test_rmt_1" ref={videoRef} autoPlay controls={false}
                            playsInline></video>
                        </div>
                      </>:""
                    }
                  <div
                    className={`badge position-relative d-flex align-items-center ${room ? "" : "bg-dark"}`}
                    style={{ zIndex: 9999 }}
                  >
                    {/* {
                      isEnableGetCutomerKey ?
                      <>
                        <div className="d-flex align-items-center justify-content-center h-100">
                          <video id="us" className="remote test_rmt_1" ref={videoRef} autoPlay controls={false}
                            playsInline></video>
                        </div>
                      </>:""
                    } */}
                    {!room &&<div className="clock" style={{ borderColor: "green" }}></div>}
                    <span
                      style={{
                        wordWrap: "wrap",
                        padding: "5px",
                        fontSize: "12px",
                        backgroundColor: room ? "#2333376b" : "dark",
                      }}
                    >
                      Waiting for the Agent
                      <br />
                      <br />
                      Do not close/refresh the page or access any other app
                    </span>
                  </div>
                </>
              </div>
          ) 
          : null
          }
        </>
        {/* )} */}
        {LocalTrackPublication ? (
          <div id="agent">
            {agentRoomStatus && (
              <div className="vd-bx-agnt-resume-vd">
                <img src="../../images/refreshGrad.svg" alt="" />
                <p className="text-white mb-0">{agentRoomStatus}</p>
              </div>
            )}
            <video id="us" className="remote test_rmt_2"
              style={{ transform: cameramirror ? "rotateY(180deg)" : "" }}
              playsInline></video>
            {/* <video id="us" className="local"   playsInline></video> */}
          </div>
        ) : null}
        {/* <div className="d-flex align-items-center justify-content-center position-absolute text-danger">
          {cameraAccessError}
        </div> */}

        {(notificationList?.notificationid == "4" &&
          canvasdisplay == true &&
          notificationList?.notifications == "Flip Camera for PanCapture") ||
          canvasdisplay == true ||
          (notificationList?.notificationid == "4" &&
            notificationList?.notifications ==
            "Flip Camera for PanCapture retake") ? (
          <>
            <div className="ovel-canvas">
              {" "}
              <img
                src="../../images/Pancanvas.svg"
                className="ovel-canvas-img"
              />
            </div>
          </>
        ) : null}

        {notificationList && Object.values(notificationList)?.length > 0 ? (
          notificationList?.notificationid === "1" && notificationList?.streamingValue != "Fetching Customer Location" && notificationList?.streamingValue != "instructions" &&
            notificationList?.notifications ? (
            <div className="display-qtn">
              <span>{notificationList?.notifications}</span>
            </div>
          ) : null
        ) : null}

        {(notificationList?.notificationid == "8" || showLiveImageCircleSvg) ? (
          <>
            <div className="ovel-canvas">
              {" "}
              <img
                src="../../images/Ovel-canvas.svg"
                className="ovel-canvas-img"
              />
            </div>
          </>
        ) : null}

        {<div id="remoteStreaming"></div>}
      </article>

      {allowcameraflip
        // && isusfb == false 
        ? (
          <BackCameraAccessModal
            flipCameraMode={flipCameraMode}
            setCameramirror={setCameramirror}
            setAllowcameraflip={setAllowcameraflip}
            setCanvasdisplay={setCanvasdisplay}
            setRecapturepan={setRecapturepan}
            sendMessage={sendMessage}
            Livekit={"Livekit"}
          />
        ) : null}


      {remotevideoelement?.length === 0 ? null : (
        <>
        <div className={letbot ? "bot botactive" : "bot"}>
          {letbot ? null : (
            <button className="chat-bot" id="bot" onClick={handelBotfunction}>
              {notifymsgcount !== 0 && (
                <span
                  className=""
                  style={{
                    width: "22px",
                    height: "22px",
                    background: "red",
                    borderRadius: "60%",
                    color: "white",
                    textAlign: "center",
                    zIndex: "2147483647",
                    position: "absolute",
                    fontWeight: "bold",
                    fontSize: "11px",
                    top: "-4px",
                    display: "flex",
                    alignItems: "center",
                    right: "48px",
                    justifyContent: "center",
                  }}
                >
                  {notifymsgcount}
                </span>
              )}

              <span className="close-chat">
                <i
                  className="far fa-comment-alt"
                  style={{ fontSize: "4vh" }}
                ></i>
              </span>
            </button>
          )}
          <div className={letbot ? "bot-box chatshow" : "bot-box"} id="chat-id">
            <ChatCmpChime
              sendChatMessage={sendChatMessage}
              changeMessage={changeMessage}
              message={message}
              setMessage={setMessage}
              bulk={bulk}
              handelBotfunction={handelBotfunction}
            />
          </div>
        </div>
        {/* <button type="button" 
        style={{position:"absolute",display:"flex",justifyContent:"center",alignItems:"center",left:"50%",top:"60%",
          transform:"translate(-50%)",zIndex:"9999",height:"40px",width:"200px"
        }}
        onClick={flipCameraButtonFunction}> Flip Camera Button</button> */}
        </>
      )}
    </>
  );
};

export default InitiatingVideoCall;
