// import { useFeatureFlagEnabled } from "posthog-js/react";

export const isFeatureFlagEnabled = (flagName, environment = "" , dynamicFlag) => {
  return dynamicFlag === "true" || dynamicFlag === true;
  // let flag = flagName;

  // if (environment?.includes("uat")) {
  //   flag = `${flagName}-uat`;
  // }

  // return useFeatureFlagEnabled(flag);
};


export function validateEmail(value) {
  let error;
  if (!value || value === undefined || value.toString().trim() === "") {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid Email Address";
  }
  return error;
}

export function validateIsRequired(value) {
  let error;
  if (value === null || value == undefined || value.toString().trim() === "") {
    error = "Required";
  }

  return error;
}

export function validateIsNumeric(value) {
  const numericPattern = new RegExp(/^[0-9]*$/);

  let error;
  if (!numericPattern.test(value)) {
    error = "Invalid Number";
  }

  return error;
}

export function minOrMaxLength(value, minOrMax, length) {
  let error;

  if (
    value !== undefined
    && minOrMax === "minlength"
    && value.length < length
  ) {
    error = `Minimum ${length
        .toString()
        .split("")
        .reduce((prevValue, currentValue) => `${prevValue}${currentValue}`, "")} charecters`

    // error = `Passwords should get accepted as minimum ${
    //   length
    //     .toString()
    //     .split("")
    //     .reduce((prevValue, currentValue) => `${prevValue}${currentValue}`, "")} characters & above ${
    //       length
    //         .toString()
    //         .split("")
    //         .reduce((prevValue, currentValue) => `${prevValue}${currentValue}`, "")} is also acceptable.`;
    //       ${
    // length
    //   .toString()
    //   .split("")
    //   .reduce((prevValue, currentValue) => `${prevValue}${currentValue}`, "")}`;
  } else if (
    value !== undefined
    && minOrMax === "maxlength"
    && value.length > length
  ) {
    error = `Maximum Length ${length
        .toString()
        .split("")
        .reduce((prevValue, currentValue) => `${prevValue}${currentValue}`, "")}`;
  }

  return error;
}

export function fileSizeError(value) {
  let error;
  if (value.size > 10e6) {
    error = "Image size limit exceeded, Please upload ";
    return false;
  }
  return error;
}

export function validateIsNumAndAlp(value) {
  let error;
  const numericPattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
  if (!numericPattern.test(value)) {
    error = 'Password should have one upper-case, one lower-case, a digit and a non-alphanumeric character'
    // error = 'Password should be 8 characters or longer with at least one upper-case, one lower-case, a digit, and a alphanumeric number'
  } else {
    error = ""
  }

  return error;
}

// Function to check if occupation and occupation codes are empty
export const checkIfOccupationIsEmpty = (occupation) => {
  if (!occupation) {
    return true;
  }
  const [occupationName, occupationCode] = occupation.split("@");
  return !occupationName?.trim() || !occupationCode?.trim() || occupationName.trim().length <= 1;
};