const hi = {
    select_language: "भाषा का चयन करें",
    // instruction:"हम अनुशंसा करते हैं कि आप अपनी वीडियो कॉल जारी रखने के लिए नीचे सूचीबद्ध <b> भाषा</b> का चयन करें",
    instruction: "हम अनुशंसा करते हैं कि आप नीचे सूचीबद्ध एक का चयन करें ",
    language: "भाषा ",
    continue: "अपनी वीडियो कॉल जारी रखने के लिए",

    // =================================
    //        Commence your video KYC
    // =================================
    verify_your_identity: "अपनी पहचान सत्यापित करें",
    handy_documents: "अपनी पूर्ण केवाईसी प्रक्रिया के साथ आगे बढ़ने से पहले कृपया अपने निम्नलिखित दस्तावेजों को संभाल कर रखें।",
    aadhaar_verify: "आधार सत्यापन",
    video_call: "वीडियो कॉल (प्रश्नोत्तर)",

    // =================================
    //              Modal
    // =================================

    user_consent: "उपयोगकर्ता की सहमति",
    request: "आईपी परिवर्तन अनुरोध",
    close: "बंद करें",
    user_instruction: "पर क्लिक करके",
    user_agree: "'सहमत',",
    user_here: "इसके द्वारा आप:",
    // user_instruction:"<b>'सहमत'</b> पर क्लिक करके, आप एतद्द्वारा:",
    // user_instruction1:"व्यक्तिगत विवरण प्रदान करने के लिए सिंटिज़न टेक्नोलॉजीज प्राइवेट लिमिटेड द्वारा किए गए अनुरोध को स्वीकार करें।",
    // user_instruction2:"सूचना साझा करके सभी सूचनाओं को एक्सेस, कॉपी और स्टोर करने के लिए मेरी बिना शर्त सहमति प्रदान करें।",
    // user_instruction3:"यह भी वचन दें कि मैं/हम अनुरोधकर्ता संगठन की ओर से ऐसा करने के लिए अधिकृत हैं और इसके लिए एकमात्र और पूर्ण जिम्मेदारी है।",
    user_instruction1: "बैंकर के साथ आपका वीडियो बातचीत सत्र रिकॉर्डिंग मोड में होगा।",
    user_instruction2: "बैंकर के साथ वीडियो बातचीत सत्र के दौरान एक लाइव फोटोग्राफ कैप्चर किया जाएगा।",
    user_instruction3: "आपके आधार विवरण का उपयोग वी-सीआईपी प्रक्रिया में आधार सत्यापन के लिए किया जाएगा।",
    user_instruction4: "वी-सीआईपी प्रक्रिया में पैन सत्यापन करने के लिए आपके पैन कार्ड की एक तस्वीर एकत्र की जाएगी।",
    user_instruction5: "आपका लाइव स्थान वी-सीआईपी प्रक्रिया में कैप्चर किया जाएगा।",
    user_instruction6: "आपको वीडियो बातचीत सत्र के दौरान सभी विवरण सही होने चाहिए।",
    user_instruction7: "आधार एक्सएमएल पैकेट या आधार सुरक्षित क्यूआर कोड 3 दिनों से अधिक पुराना नहीं होना चाहिए।",
    user_instruciton8: "उपर्युक्त सभी कदम आरबीआई के दिशानिर्देशों के अनुसार हैं।",
    // content_cdtn1: "यह, वी-सीआईपी आईडी ",
    // content_cdtn2: " धारक उपर्युक्त सभी बिंदुओं से सहमत है और इसके बाद, मेरी सहमति की पुष्टि करता है।",
    accept_cdtn1: "मैं, ",
    accept_cdtn2: " वीसीआईपी आईडी के साथ - ",
    accept_cdtn3: " उपरोक्त सभी बिंदुओं से सहमत हूं और एतद्द्वारा, मेरी सहमति की पुष्टि करता हूं।",
    // disagree: "असहमत",
    agree: "आगे बढ़ना",

    // =================================
    //              Token Number
    // =================================



    token_number: "आपसे अनुरोध है कि आपका टोकन नंबर शून्य होने तक प्रतीक्षा करें।",
    wait_time: "आपका अनुमानित प्रतीक्षा समय",
    wait_patinently: "चूंकि हमारे सभी एजेंट इस समय व्यस्त हैं, हम अनुशंसा करते हैं कि आप धैर्यपूर्वक प्रतीक्षा करें और कुछ समय बाद वापस आएं",



    // =================================
    //              Headers
    // =================================


    initiating_call: "वीडियो कॉल शुरू करना",
    choose_language: "एक भाषा चुनें",
    commence_video: "अपना वीडियो केवाईसी शुरू करें",
    aadhaar_xml: "आधार एक्सएमएल",
    kyc_details: "केवाईसी विवरण",
    aadhaar_offline: "आधार ऑफलाइन केवाईसी",
    download_sucess: "सफलतापूर्वक डाउनलोड करें",
    pan_verification: "पैन सत्यापन",
    Keep_things_handy: "क्या आप हमारे एजेंट के साथ वीडियो कॉल के लिए तैयार हैं?",
    title_token: "टोकन नंबर",
    reschedule: "पुनर्निर्धारित",
    status: "स्थिति",
    details:"विवरण",
    


    // =================================
    //              PAN
    // =================================


    Pan_heading: "पैन कार्ड फोटो कैप्चर करें",
    capture_photo: "फोटो कैप्चर करें",
    retake: "फिर से लेना",
    submit: "प्रस्तुत",
    upload_id: 'अपलोड आईडी',
    upload_pan: 'अपलोड पैन',
    please_select_below_option_to_submit_your_id_card: 'अपना आईडी कार्ड सबमिट करने के लिए कृपया नीचे दिए गए विकल्प का चयन करें',
    please_select_below_option_to_submit_your_pan_card: 'अपना पैन कार्ड जमा करने के लिए कृपया नीचे दिए गए विकल्प का चयन करें',
    capture_id: 'कैप्चर आईडी',
    Captuer_pan: 'कैप्चर पैन',
    upload_from_gallery: 'गैलरी से अपलोड करें',
    date_of_birth: 'जन्म तिथि',
    pan_number: 'पैन नंबर',
    please_enter_your_name: 'कृपया अपना नाम दर्ज करें',
    please_enter_father_name: "कृपया पिता का नाम दर्ज करें",
    please_enter_dob: 'कृपया जन्म तिथि दर्ज करें',
    please_check_pan: 'कृपया पैन की जांच करें',
    please_enter_gender: 'कृपया लिंग दर्ज करें',


    // capture_photo: "फोटो कैप्चर करें",
    // retake: "रिटेक",
    // submit: "सबमिट करें"

    // ================================
    //          Digilocker
    // ================================

    connection_you_to_digilocker: 'आपको डिजिलॉकर से कनेक्ट कर रहा है',
    Proce_with_digilocker_for_aadhar_verification: 'आधार सत्यापन के लिए डिजिलॉकर के साथ आगे बढ़ें',
    Proce_with_digilocker: "डिजिलॉकर के साथ आगे बढ़ें",
    if_you_are_unable_to_complete_aadhar_verification_with_digilocker: 'यदि आप Digilocker के माध्यम से आधार सत्यापन पूरा करने में असमर्थ हैं',
    click_here: 'यहां क्लिक करें',
    aadhar_kyc_details: 'आधार केवाईसी विवरण',
    uuid: 'यूयूआईडी',
    // ================================
    // Tide labels
    // ================================
    For_a_smooth_identification_process: "एक सहज पहचान प्रक्रिया के लिए",
    do_not_refresh_page_or_access_other_app: 'पेज को रिफ्रेश न करें या किसी अन्य ऐप को एक्सेस न करें',
    ensure_your_internet_connectivity_is_stable: 'सुनिश्चित करें कि आपकी इंटरनेट कनेक्टिविटी स्थिर है',
    do_not_let_your_mobile_enter_sleep_mode: 'अपने मोबाइल को स्लीप मोड में न जाने दें',
    please_ensure_you_have_following_in_hand: 'कृपया सुनिश्चित करें कि आपके पास निम्नलिखित हैं',
    aadhar_id: 'आधार आईडी',
    Physical_pan_card_not_e_pan_card: 'भौतिक PAN कार्ड (ePAN कार्ड नहीं)',
    as_part_of_verifi_process_you_will_need_to: 'सत्यापन प्रक्रिया के भाग के रूप में, आपको इसकी आवश्यकता होगी',
    connect_to_one_of_our_agents_who_will_record_video_of_your_conversation: 'हमारे एक एजेंट से जुड़ें, जो आपकी बातचीत का वीडियो रिकॉर्ड करेगा',
    permit_our_agent_to_capture_live_photo_during_video: 'हमारे एजेंट को इंटरैक्टिव वीडियो सत्र के दौरान आपकी एक लाइव तस्वीर लेने की अनुमति दें',
    provide_your_aadhar_details_who_will_be_use_in_video_call: "अपना आधार विवरण प्रदान करें, जिसका उपयोग वीडियो पहचान प्रक्रिया के भाग के रूप में किया जाएगा",
    provide_a_pic_of_pan_card_which_will_be_used_in_video_call_process: "अपने पैन कार्ड की एक फोटो प्रदान करें, जिसका उपयोग वीडियो पहचान प्रक्रिया के हिस्से के रूप में किया जाएगा",
    share_your_live_locaiton_which_will_be_capture_in_video_call_process: 'अपना लाइव स्थान साझा करें, जिसे वीडियो पहचान प्रक्रिया के हिस्से के रूप में कैप्चर किया जाएगा',
    sure_all_your_details_are_correctly_submitted_in_video_call_process: "सुनिश्चित करें कि आपके सभी विवरण इंटरैक्टिव वीडियो सत्र में सही ढंग से सबमिट किए गए हैं",
    sure_your_aadhar_xml_pacaket_is_not_older_than_3_days: "सुनिश्चित करें कि आपका आधार XML पैकेट 3 दिनों से अधिक पुराना नहीं है",
    with_vcip_id: 'वीसीआईपी आईडी के साथ',
    agree_with_all_the_above_points_and_confirm_my_consent: 'उपर्युक्त सभी बिंदुओं से सहमत हूं और इसके द्वारा मेरी सहमति की पुष्टि करता हूं।',

    // =================================
    //              PAN-CAPTURE
    //=================================
    pan_capture: "पैन सत्यापन",


    // =================================
    //              PAN Details
    // =================================



    ocr_info: "ओसीआर जानकारी",
    name: "नाम",
    father_name: "पिता का नाम",
    dob: "जन्म तारीख",
    please_wait: "कृपया प्रतीक्षा कीजिये...",
    fetching_aadhar: "आधार विवरण प्राप्त किया जा रहा है...!",
    uid: "यूआईडी",
    gender: "लिंग",
    Address: "पता",
    xml_file: "क्या आपके पास आधार एक्सएमएल फाइल है?",
    yes: "हां",
    no: "नहीं",
    // =================================
    //              Details
    // =================================


    waiting_agent: "एजेंट की प्रतीक्षा में",
    end_call: "कॉल बंद",
    book_slot: "एक स्लॉट बुक करें",
    id_number: "ID Number",



    // =================================
    //              Kyc completed 
    // =================================


    kyc_update: "केवाईसी जमा किया।",
    verification: "आपका सत्यापन पूरा हो गया है और आपका सारा डेटा सुरक्षित रूप से संग्रहीत है।",

    // =================================
    //           Permission check
    // =================================

    need_access_to_location_camera_mic: "हमें आपके स्थान कैमरा और माइक्रोफ़ोन तक पहुंच की आवश्यकता है",
    unblock_camera_mic_in_settings: 'सेटिंग्स में जाकर अपना कैमरा या माइक्रोफ़ोन अनब्लॉक करें',
    enable_permission_in_browser_settings: "ब्राउज़र सेटिंग और फ़ोन सेटिंग में अनुमतियाँ सक्षम करें।",
    click_unblock_btn_below: 'नीचे "अनब्लॉक" बटन पर क्लिक करें।',
    when_prompted_click_allow: 'संकेत मिलने पर, "अनुमति दें" पर क्लिक करें',
    need_locaiton_cam_mic_permission_for_kyc: 'केवाईसी जांच करने के लिए, हमें आपके वर्तमान स्थान, कैमरा और माइक्रोफ़ोन तक पहुंचने की आवश्यकता है।',
    camera_is_used_by_another_app: 'कैमरा किसी अन्य एप्लिकेशन (ज़ूम, स्काइप) या ब्राउज़र टैब (Google मीट, मैसेंजर वीडियो) द्वारा उपयोग में है',



    // ================================
    //      Buttons
    // ================================
    allow_access: 'एक्सेस की अनुमति दें',
    unblock: 'अनब्लॉक',


    // ================================
    //      Lables
    // ================================


    video_kyc_chat: 'वीडियो केवाईसी चैट',
    video_kyc: 'वीडियो केवाईसी',
    note: 'नोट:',
    do_not_refresh_warning: 'पेज को रिफ्रेश न करें या किसी अन्य ऐप को एक्सेस न करें, अन्यथा, आप वीडियो केवाईसी प्रक्रिया से लॉग आउट हो जाएंगे।',
    enable_cam_mic_location_settings: 'अपने डिवाइस पर कैमरा, माइक्रोफ़ोन और स्थान सेटिंग सक्षम करें।',
    scan_qr_code: 'स्कैन क्यूआर कोड',
    scar_qr_and_complete_kyc_process: 'कृपया इस क्यूआर को अपने मोबाइल पर स्कैन करें और वीडियो केवाईसी प्रक्रिया को पूरा करें',
    or: 'या',
    copy_link: 'कॉपी लिंक',
    payroll_id: 'पेरोल आईडी',
    Member_id: 'सदस्य आईडी',
    file_download_successfully: "फ़ाइल डाउनलोड सफलतापूर्वक",
    kindly_reconfirm_your_share_code_to_access_xml_file: "अपनी XML फ़ाइल तक पहुँच प्रदान करने के लिए कृपया अपने शेयर कोड की पुनः पुष्टि करें।",
    ovd_verification: "ओवीडी सत्यापन",
    i: "मैं",
    // ===============================
    //          instructions
    // ===============================


    do_not_open_same_link_warning: 'कृपया एक ही लिंक को कई टैब में न खोलें।',
    ensure_internet_connectivity: 'कृपया सुनिश्चित करें कि आपकी इंटरनेट कनेक्टिविटी स्थिर है।',
    enable_camera_mic_location_settings_on_device: 'अपने डिवाइस पर कैमरा, माइक्रोफ़ोन और स्थान सेटिंग सक्षम करें।',
    sleep_mode_warning: 'अपने मोबाइल की स्क्रीन को स्लीप मोड में न जाने दें।',
    do_not_go_back_in_any_kyc_step: 'केवाईसी प्रक्रिया के किसी भी चरण में पीछे न हटें।',
    do_not_refresh_page_or_you_will_be_logged_out_of_the_digital_kyc_process: "पेज को रीफ्रेश न करें या किसी अन्य ऐप को एक्सेस न करें, अन्यथा, आप डिजिटल केवाईसी प्रक्रिया से लॉग आउट हो जाएंगे।",
    do_not_refresh_page_or_you_will_be_log_out_of_the_vkyc_process: "पेज को रीफ्रेश न करें या किसी अन्य ऐप को एक्सेस न करें, अन्यथा, आप वीडियो केवाईसी प्रक्रिया से लॉग आउट हो जाएंगे।",


    // ===============================
    //          KYC status
    // ===============================
    kyc_processing: 'केवाईसी प्रोसेसिंग',
    kyc_interrupted: 'केवाईसी बाधित',
    kyc_completed_and_under_review: 'केवाईसी प्रक्रिया पूरी हो चुकी है और समीक्षाधीन है।',
    successfully_completed_kyc_process_check_after_24hrs: 'आपने केवाईसी प्रक्रिया सफलतापूर्वक पूरी कर ली है। कृपया 24 घंटे बाद देखें।',
    kyc_under_review: 'केवाईसी समीक्षाधीन',
    kyc_rejected: 'केवाईसी अस्वीकृत',
    kyc_completed: 'केवाईसी पूर्ण',
    screen_has_been_reloaded: 'स्क्रीन फिर से लोड हो गई है।',
    reinitiate_your_kyc_to_continue: 'जारी रखने के लिए अपना KYC फिर से शुरू करें।',
    oops_looks_like_page_is_reloaded: 'उफ़! ऐसा लगता है कि पृष्ठ पुनः लोड किया गया था।',
    we_recommend_you_reinitiate_kyc: 'हम अनुशंसा करते हैं कि आप अपना केवाईसी फिर से शुरू करें।',
    oops_looks_like_video_call_was_dropped: 'उफ़! ऐसा लगता है कि वीडियो कॉल ड्रॉप हो गई थी।',
    unable_to_connect_with_agent_right_now: 'हम अभी आपको किसी एजेंट से जोड़ने में असमर्थ हैं।',
    we_recommend_you_to_reinitiate_kyc_process: 'हम अनुशंसा करते हैं कि आप अपनी KYC प्रक्रिया फिर से शुरू करें।',
    look_like_agent_could_not_take_the_call: 'लगता है एजेंट कॉल नहीं उठा सका।',
    your_call_was_disconnected: 'आपका कॉल डिस्कनेक्ट हो गया था।',
    reinitiate_your_kyc: 'जारी रखने के लिए अपना केवाईसी फिर से शुरू करें।',
    we_will_notify_once_your_kyc_is_approved_and_your_data_is_stored: "हमारी ओर से आपका केवाईसी स्वीकृत हो जाने के बाद हम आपको सूचित करेंगे। आपका सारा डेटा सुरक्षित रूप से संग्रहीत है।",
    digital_kyc_is_verified_by_agent_waiting_for_auditor: 'डिजिटल केवाईसी एजेंट द्वारा सत्यापित, और ऑडिटर समीक्षा की प्रतीक्षा कर रहा है।',
    your_verification_is_success_and_data_stored: 'आपका सत्यापन पूरा हो गया है और आपका सारा डेटा सुरक्षित रूप से संग्रहीत है।',
    your_kyc_got_rejected: 'आपका KYC नामंजूर हो गया है।',


    // ===================================
    //          Employement Details
    // ===================================

    occupation_details: "पेशा विवरण",
    occupation_details_subtext: "अपना एक विकल्प चुनें",

    twelve_digit_aadhaar: "12 अंकों का आधार",
    sixteen_digin_aadhar: "16 अंकों का वीआईडी",
    aadhaar: "आधार",
    aadhaar_number: "आधार संख्या",
    enter_your_12_digit_Aadhaar_number_to_begin: "शुरू करने के लिए अपना 12 अंकों का आधार नंबर दर्ज करें",
    refresh: "फिर से करो",
    type_the_character_you_see_in_the_picture: "चित्र में दिखाई देने वाले वर्ण को टाइप करें",
    send_otp: "OTP भेजें",
    get_captcha: "कैप्चा प्राप्त करें",
    Enter_your: "अपना दर्ज करें",
    number_to_begin: "संख्या शुरू करने के लिए",
    get_otp: "OTP प्राप्त करें",
    submit_otp: "ओटीपी सबमिट करें",
    verification_code: "सत्यापन कोड",
    Please_enter_the_verification_code_we_sent_to_your_phone_number: "कृपया हमारे द्वारा आपके फ़ोन नंबर पर भेजा गया सत्यापन कोड दर्ज करें",
    enter_share_code: "शेयर कोड दर्ज करें",
    create_a_4_digit_code_to_secure_your_offline_eKYC: "अपना ऑफ़लाइन eKYC सुरक्षित करने के लिए 4 अंकों का कोड बनाएँ",
    check_network_please_wait: "नेटवर्क की जाँच कर रहा है कृपया प्रतीक्षा करें ...",
    स्थान_सक्षम: "स्थान सक्षम",
    token_has_been_expired_please_close_and_Try_Again: "टोकन समाप्त हो गया है, कृपया बंद करें और पुनः प्रयास करें",
    if_the_Proceed_button_does_not_enable_in_30_seconds_कृपया_close_the_popup_and_join_the_call_again: "अगर प्रोसीड बटन 30 सेकंड में सक्षम नहीं होता है। कृपया पॉप-अप बंद करें और कॉल में फिर से शामिल हों",
    ip_threat_detected: "आईपी खतरे का पता चला",
    audio_video_check: "ऑडियो / वीडियो चेक",
    please_check_if_your_camera_and_mic_is_working_and_proceed: "कृपया जांचें कि आपका कैमरा और माइक काम कर रहा है या नहीं और आगे बढ़ें।",
    video_preview: "वीडियो पूर्वावलोकन",
    please_close_other_Apps_Tabs_sessions_that_are_accessing_the_Camera_and_Microphone_currently_and_Hit_below_refresh_button: "कृपया अन्य ऐप्स/टैब/सत्रों को बंद करें जो वर्तमान में कैमरा और माइक्रोफ़ोन तक पहुंच रहे हैं और नीचे ताज़ा करें बटन दबाएं।",
    toggle_your_video_call_screen_with_agent_screen_for_photo_capture: "फोटो कैप्चर करने के लिए एजेंट स्क्रीन के साथ अपनी वीडियो कॉल स्क्रीन को टॉगल करें",
    page_not_found: "पृष्ठ नहीं मिला",
    We_are_verify_your_identity: "हम आपकी पहचान की पुष्टि कर रहे हैं",
    identity_verification_process: "पहचान सत्यापन प्रक्रिया",
    start_your_video_KYC: "अपना वीडियो KYC शुरू करें",
    commence_your_digital_kyc: "अपना डिजिटल केवाईसी शुरू करें",
    cancel: "रद्द करें",
    your_token_expires_in: "आपका टोकन समाप्त हो रहा है",
    please_select_a: "कृपया एक चुनें",
    please_connect_to_another_network: "कृपया दूसरे नेटवर्क से कनेक्ट करें।",
    as_your_ip_address_is_not_healthy: "क्योंकि आपका आईपी पता स्वस्थ नहीं है।",
    date_time: "तारीख और समय",
    to_reschedule_a_video_call_session_with_our_agent: "हमारे एजेंट के साथ एक वीडियो कॉल सत्र को फिर से शेड्यूल करने के लिए",
    please_switch_off_the_iCloud_relay: "कृपया आईक्लाउड रिले बंद करें",
    if_using_a_iPhone_for_doing_the_VKYC: "यदि VKYC करने के लिए iPhone का उपयोग कर रहे हैं।",
    inspect: "निरीक्षण",
    number: "संख्या",
    to: "को",
    use: "उपयोग करें",
    phone: "फ़ोन",
    check_browser_details: "ब्राउज़र विवरण की जाँच करना",
    you_are_using: "आप उपयोग कर रहे हैं",
    email_id: "ईमेल आईडी",
    schedule_details: "अनुसूची विवरण",
    get_ready_for_video_KYC_call_with_our_agent: "हमारे एजेंट के साथ वीडियो केवाईसी कॉल के लिए तैयार रहें",
    agents_unavailable: "एजेंट अनुपलब्ध",
    agents_currently_unavailable_Please_try_again_between: "एजेंट वर्तमान में अनुपलब्ध हैं। कृपया बीच में पुनः प्रयास करें",
    or_Book_a_slot_for_your_Video_KYC: "या अपने वीडियो KYC के लिए एक स्लॉट बुक करें।",
    holiday: "छुट्टी",
    sorry_we_missed_you: "क्षमा करें, हमने आपको याद किया!",
    please_open_the_app_at_a_time_that_works_best_for_you_between: "कृपया ऐप को ऐसे समय में खोलें जो आपके बीच सबसे अच्छा काम करे",
    to_complete_the_sign_up: "साइन अप पूरा करने के लिए।",
    or_Book_a_new_time_slot: "या एक नया समय स्लॉट बुक करें।",
    //===================================
    //Greetings
    //=====================================

    hi: "हाय",
    while_we_are_searching_for_the_agent_to_initiate_the_video_call: "जबकि हम वीडियो कॉल शुरू करने के लिए एजेंट की खोज कर रहे हैं",
    VID: "वीआईडी",
    select_an_available_time_slot: "उपलब्ध समय स्लॉट का चयन करें",
    select_time: "समय चुनें",
    select_available_time_slots: "उपलब्ध समय स्लॉट का चयन करें",
    video_settings: "वीडियो सेटिंग",
    select_the_language_youd_like_to_chat_to_our_agent_in_on_your_upcoming_video_call: "वह भाषा चुनें, जिसमें आप अपने आगामी वीडियो कॉल पर हमारे एजेंट से चैट करना चाहते हैं।",
    agents_currently_unavailable_please_try_again_in_sometime: "एजेंट वर्तमान में अनुपलब्ध हैं, कृपया कुछ समय में पुन: प्रयास करें।",
    if_you_speak: "यदि आप बोलते हैं",
    bengali: "बंगाली",
    please_choose: "कृपया चुनें",
    hindi: "हिंदी",
    you_can_wait_a_few_minutes_to_join_the_call_or_open_the_app_at_a_time: "आप कॉल में शामिल होने के लिए कुछ मिनट प्रतीक्षा कर सकते हैं या फिर से प्रयास करने के लिए 10 AM से 10 PM के बीच ऐप खोल सकते हैं जो आपके लिए सबसे अच्छा काम करता है।",
    ON: "ऑन",
    copy_link_here: "लिंक यहां कॉपी करें",
    android: "एंड्रॉइड",
    google_chrome_V70: "गूगल क्रोम V70+",
    iPhone: "आईफोन",
    Safari_V14: "सफारी वी14+",
    safari_V12: "सफारी वी12+",
    this_browser_and_device_combination_does_not_support_video_calling: "यह ब्राउज़र और उपकरण संयोजन, वीडियो कॉलिंग का समर्थन नहीं करता है।",
    please_copy_the_link_and_open_this_link_will_experire_in_10_मिनट: "कृपया लिंक कॉपी करें और इसे नीचे दिए गए किसी भी ब्राउज़र संयोजन में खोलें। यह लिंक 10 मिनट में समाप्त हो जाएगा।",
    for_android: "एंड्रॉइड के लिए",
    for_iPhone: "iPhone के लिए",
    invalid_url: "अमान्य यूआरएल",
    sorry_the_page_not_to_found: "क्षमा करें पृष्ठ नहीं मिला",
    connecting_you_with_an_available_agent: "आपको एक उपलब्ध एजेंट के साथ जोड़ना",
    on: "ऑन",
    do_not_refresh_page_or_you_will_be_logged_out_of_the_vkyc_process:"पेज को रिफ्रेश न करें या किसी अन्य ऐप को एक्सेस न करें, अन्यथा आप वीडियो केवाईसी प्रक्रिया से लॉग आउट हो जाएंगे।",
    next :"आगे बड़े",
    cancel_schedule:"शेड्यूल रद्द करें",
    join_now:"ज्वाइन करे",
    generate_now:"जेनेरेट करे",
    retry:"पुनः प्रयास करे",
    continue_button:"जारी करे",
    done:"पूर्ण",
    retry_button:"पुनः प्रयास करे",
    Retry_KYC_button:"KYC के लिए पुनः प्रयास करे",
    book_slot_for_later:"बाद के लिए स्लॉट बुक करें",
    captuer_pan:"पैन कैप्चर करे",
    capture_pan_card:"पैन कैप्चर करे",
    position_the_front_of_the_PAN_card_in_the_frame:"पैन कार्ड का अगला हिस्सा फ्रेम में दिखाए",
    confirm_PAN_proof:"पैन प्रूफ की पुष्टि करें",
    select_language_img_alt:"भाषा का चयन",
    im_ready:"मै तैयार हूँ",
    instructions:"निर्देश:",
    start:"शुरू करें",
    get_started:"शुरू करें",
    digital_KYC:"डिजिटल केवाईसी",
    proceed_with_digilocker_for_aadhar_verification:"आधार सत्यापन के लिए डिजिलॉकर के साथ आगे बढ़ें",
    aadhaar_number_for_placeholder:"आधार संख्या*",
    proceed_with_digilocker:"डिजिलॉकर के साथ आगे बढ़ें",
    if_the_Proceed_button_does_not_enable_in_30_seconds_Please_close_the_popup_and_join_the_call_again:"यदि प्रोसीड बटन 30 सेकंड में सक्षम नहीं होता है। कृपया पॉप-अप को बंद करें और कॉल में दोबारा शामिल हों",
    token_has_been_expired:"टोकन समाप्त हो गया है",
    unable_to_find_your_location_please_try_again:"आपका लोकेशन खोजने में असमर्थ। कृपया पुनः प्रयास करें।",
    please_try_agin:"पुनः प्रयास करें",
    OCR_extraction_failed_please_try_again:"ओसीआर निकालने में विफल कृपया पुनः प्रयास करें।",
    As_an_agent_could_not_connect_the_call_will_be_ending_in_5_sec_Please_try_again:"जैसे की एजेंट कनेक्ट नहीं हो सका, कॉल 5 सेकंड में समाप्त हो जाएगा। कृपया पुनः प्रयास करें",
    please_check_the_box:"कृपया बॉक्स को चेक करें",
    poor_internet_connection_detected_Switch_to_other_network:"खराब इंटरनेट कनेक्शन का पता चला, दूसरे नेटवर्क पर स्विच करें।",
    loading_the_image_please_wait:"छवि लोड हो रही है, कृपया प्रतीक्षा करें...",
    aadhaar_details:"आधार विवरण",
    geolocation_is_not_supported_by_this_browser:"जियोलोकेशन इस ब्राउज़र द्वारा समर्थित नहीं है।",
    please_select_check_box_and_proceed:"कृपया चेक बॉक्स चुनें और आगे बढ़ें",
    upload_image_proceed:"छवि अपलोड करें और आगे बढ़ें",
    agent_disconnected_due_to_network_disconnect_issue:"नेटवर्क डिस्कनेक्ट समस्या के कारण एजेंट डिस्कनेक्ट हो गया!",
    please_close_other_AppsTabsSessions_that_are_accessing_the_Camera_and_Microphone_currently_and_Hit_below_refresh_button:"कृपया अन्य ऐप्स/टैब/सत्रों को बंद करें जो वर्तमान में कैमरा और माइक्रोफ़ोन तक पहुंच रहे हैं और रिफ्रेश बटन के नीचे हिट करें।",
    Join_Start_Video_Call:"वीडियो कॉल में शामिल हों/शुरू करें",
    unblock_your_location:"अपना लोकेशन अनब्लॉक करें",
    unblock_your_location_camera_or_microphone:"अपना लोकेशन, कैमरा या माइक्रोफ़ोन अनब्लॉक करें",    
    re_upload:"फिर से अपलोड करें",
    confirm:"पुष्टि करें",
    All_our_agents_are_busy_right_now_We_request_you_to_be_with_us_for_few_mins:"हमारे सभी एजेंट अभी व्यस्त हैं। हम आपसे कुछ मिनट हमारे साथ रहने का अनुरोध करते हैं।",
    this_video_cannot_be_seen_by_anyone_except_you:"यह वीडियो आपके अलावा कोई नहीं देख सकता",
    submit_ePAN:"ई-पैन जमा करें",

    // NEW INSTRUCTIONS
    Do_not_refresh_the_page_or_access_any_other_app_else_you_will_be_logged_out_of_the_Digital_KYC_process:"पेज को रिफ्रेश न करें या किसी अन्य ऐप को एक्सेस न करें, अन्यथा आप डिजिटल केवाईसी प्रक्रिया से लॉग आउट हो जाएंगे।",
    Do_not_go_back_in_any_step_of_the_KYC_process:"केवाईसी प्रक्रिया के किसी भी चरण में पीछे न हटें।",
    Do_not_let_your_mobile_screen_go_into_sleep_mode:"अपने मोबाइल की स्क्रीन को स्लीप मोड में न जाने दें।",
    Enable_camera_microphone_and_location_settings_on_your_device:"अपने डिवाइस पर कैमरा, माइक्रोफ़ोन और स्थान सेटिंग सक्षम करें।",
    Please_ensure_your_internet_connectivity_is_stable:"कृपया सुनिश्चित करें कि आपकी इंटरनेट कनेक्टिविटी स्थिर है।",
    Please_do_not_open_the_same_link_in_multiple_tabs:"कृपया एक ही लिंक को कई टैब में न खोलें।",
    Instructions:"निर्देश:",
    Get_Started:"शुरू हो जाओ",
    please_have_your_Original_Pan_card_during_VKYC_call:"कृपया वीकेवाईसी कॉल के दौरान अपना मूल पैन कार्ड रखें।" ,

    // ======================================
    //          Tide Camera Access 
    // ======================================
    title: "ओह! ऐसा लगता है कि अनिवार्य अनुमतियाँ गायब हैं",
    description: "vKYC प्रक्रिया को पूरा करने के लिए, कृपया सुनिश्चित करें कि सफारी को निम्नलिखित अनुमतियों तक पहुँच प्राप्त है:",
    camera: "कैमरा",
    microphone: "माइक्रोफ़ोन",
    location: "स्थान",
    instructionHeader: "आपको मैन्युअल रूप से सफारी को इन अनुमतियों तक पहुँच की अनुमति देनी होगी।",
    instructionFollow: "इन सरल चरणों का पालन करें:",
    step_1: "अपने iPhone पर सेटिंग्स ऐप खोलें।",
    step_2: "नीचे स्क्रॉल करें और Safari चुनें।",
    step_3: "Safari सेटिंग्स में, आवश्यक अनुमतियों को खोजने के लिए नीचे स्क्रॉल करें।",
    step_4: "स्थान अनुमति चुनें और इसे पूछें पर सेट करें।",
    step_5: "कैमरा और माइक्रोफ़ोन के लिए भी यही कदम दोहराएँ।",
    step_6: "डिवाइस सेटिंग्स में भी यही करें, इस बार गोपनीयता सेटिंग्स देखें और सभी अनुमतियों को सक्षम करें।",
    step_7: "सभी अनुमतियाँ सक्षम होने के बाद, Safari पर वापस जाएँ और पेज को रीफ्रेश करें।",
    allowAccess: "अनुमति दें",
    reload: "पुनः लोड करें"
}

export default hi;