import React from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import H1 from "../Elements/H1";
import H4 from "../Elements/H4";
import P from "../Elements/P";
import Instructions from "../InstructionsCmp/Instructions";
import "./clock.css";
import TideHomePageInstructions from "../TideComponents/TideHomePageInstructions/TideHomePageInstructions";
import CleintNames from "../../Constants/ClientNames";
import { Text } from "../../Pages/Language/Language";
import TideMergeScreen from "../TideComponents/TideMerge/TideMergeScreen";
const Id = sessionStorage.getItem("link");


const HomeCmp = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { app_env, applicationConfig } = dynamicSettingObj;
  const applicationConfigExists = Object.keys(applicationConfig || {})?.length
  const isTCMergedScreen = applicationConfigExists ? (applicationConfig?.isTCMergedScreen == "true" || applicationConfig?.isTCMergedScreen == undefined) : true
  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;
  return (
    <section
      className={"app-start" + (props?.isLargeDevice ? " l-app-start" : "")}
    >
      {/* <h1 className="main-heading">Video KYC</h1> */}
      <div className="row justify-content-center align-content-center bgWhite">
        <div className="col">
          {!isTCMergedScreen &&
            <H1
              title={iskinabank ? <Text tid="digital_KYC" /> : ((window.location.pathname.includes("d=")) ? "Video KYC" : <Text tid="video_kyc" />)}
              color={cssProperties?.pagetitle_heading?.font_color}
              isClientTideAccess={props?.isClientTideAccess}
            // fontSize={cssProperties?.pagetitle_heading?.font_size}
            />}
          {props?.statusTxt && (
            <P
              className="txt text-center"
              txt={props?.customerVcipDetails?.respdesc}
              color={cssProperties?.body?.font_color}
            // fontSize={cssProperties?.body?.font_size}
            />
          )}
          {props?.waitingTimeTxt && (
            <>
              <div className="text-center mb-4">
                <div
                  className="clock"
                  style={{
                    borderColor: cssProperties?.button?.color,
                    margin: "10px auto",
                  }}
                ></div>
                <div style={{ flex: 1, marginLeft: 4 }}>
                  <P
                    className="txt"
                    txt={props?.waitingTimeTxt}
                    color={cssProperties?.body?.font_color}
                  />
                </div>
              </div>
            </>
          )}
          {/* <H4
            className="title mb-2"
            title={"Note:"}
            color={cssProperties?.pagetitle_heading?.font_color}
            fontSize={cssProperties?.pagetitle_heading?.font_size}
          /> */}
          {/* <TideHomePageInstructions app_env={app_env} /> */}
          {
            props?.isClientTideAccess ? (
              <>
                {isTCMergedScreen ?
                  <TideMergeScreen isChecked={props?.isChecked} handleCheckbox={props?.handleCheckbox} vcipid={props?.vcipid}
                    cust_name={props?.cust_name} waitingTimeTxt={props?.waitingTimeTxt}/> 
                  :
                  <TideHomePageInstructions app_env={app_env} />
                }
              </>
            ) : (
              <Instructions iskinabank={iskinabank} cssProperties={cssProperties} />
            )
          }

        </div>
      </div>
    </section>
  );
};

export default HomeCmp;
