let wakeLock = null;

export async function requestWakeLock() {
  if ('wakeLock' in navigator && wakeLock === null) {
    try {
      wakeLock = await navigator.wakeLock.request('screen');
      console.log('Wake Lock is active');

      wakeLock.addEventListener('release', () => {
        console.log('Wake Lock was released');
        wakeLock = null;
      });
    } catch (err) {
      console.error(`Failed to acquire wake lock: ${err}`);
    }
  }
}

export function releaseWakeLock() {
  if (wakeLock !== null) {
    wakeLock.release().then(() => {
      console.log('Wake Lock has been released');
      wakeLock = null;
    });
  }
}