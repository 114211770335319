import React from "react";
import { Text } from "../../Pages/Language/Language";
import { MdOutlineErrorOutline } from "react-icons/md";
import "./TideCameraAccess.css";

const TideCameraAccess = ({ language = "en", isCameraUsed }) => {
  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="camera-access-container">
      <h2 className="title">
        <Text tid="title" />
      </h2>
      <p className="description">
        <Text tid="description" />
      </p>
      
      <div className="icons-container">
        <div className="icon-item">
          <img src="../images/Camera.svg" width={24} height={24} />
          <div className="error-icon">
            <MdOutlineErrorOutline color="#D92D20" />
          </div>
        </div>

        <div className="icon-item">
          <img src="../images/mic.svg" width={24} height={24} />
          <div className="error-icon">
            <MdOutlineErrorOutline color="#D92D20" />
          </div>
        </div>

        <div className="icon-item">
          <img src="../images/location_on.svg" width={24} height={24} />
          <div className="error-icon">
            <MdOutlineErrorOutline color="#D92D20" />
          </div>
        </div>
      </div>

      <p className="instruction-header">
        <Text tid="instructionHeader" />
      </p>
      <p className="instruction-follow">
        <Text tid="instructionFollow" />
      </p>
      
      <ol className="instructions-list-tide">
        {[...Array(7).keys()].map((index) => (
          <li key={index}>
            <Text tid={`step_${index + 1}`} />
          </li>
        ))}
      </ol>
      
      <div className="button-container">
        <button className="access-button" onClick={reload}>
          <Text tid="reload" />
        </button>
      </div>
    </div>
  );
};

export default TideCameraAccess;