import React, { useEffect, useRef, useState } from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import base64 from "base-64";
import moment from "moment";

import RouteNames from "../../../Constants/RouteNames";
import {
  actionPanCapture,
  actionSavePanDetails,
  actionVerifyPanNumber,
} from "../../../Store/SagaActions/PanSagaActions";
import AppFooter from "../../Common/AppFooter";
import PanDetailsEdit from "./PanDetailsEdit";
import toast from "react-hot-toast";
import { Text } from "../../Language/Language";
import PanCapture from "../PanUpload/PanCapture";
import Button from "../../../Components/Elements/Button";
import CleintNames from "../../../Constants/ClientNames";
import Tideheader from "../../../Components/TideComponents/Tideheaderforpan/Tideheader";
import { checkIfOccupationIsEmpty } from "../../../utils/validations";

var parsedUser;
const PanKYC = () => {
  var imagebase64 = "";

  const [isCaptureDisabled, setisCaptureDisabled] = useState(false);
  const [panDetails, setPanDetails] = useState({});
  const [dateDisplay, setDateDisplay] = useState("");
  const [vcipkey, setVcipkey] = useState("");

  const [imgPath, setImgPath] = useState("");
  const [uploadimgpath, setUploadimagepath] = useState("");
  const [base64URL, setBase64URL] = useState(false);
  const [fileimg, setFileimg] = useState(null);
  const [errorState, setErrorState] = useState(false);
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  // CleintNames.TRANSCORP == parsedUser?.issuername

  // console.log(state,"-------------------------->")

  const isLoading = useSelector((state) => state.HomeReducer.apiStatus);
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const clientName = useSelector((state) => state.HomeReducer.clientName);

  const isClientAccess =
    clientName === CleintNames?.TIDEDEV ||
    clientName === CleintNames?.TIDEQA ||
    clientName === CleintNames?.TIDE
      ? true
      : false;
  // const iskinabank = clientName === CleintNames?.KINABANK ? true : false;
  const isusfb =
    clientName === CleintNames?.USFB || clientName === CleintNames?.USFBSYNTIZEN
      ? true
      : false;
      const agentDiscrepancyStatus = useSelector((state)=>state.HomeReducer.storeAgentDiscrepancyStatus)
      const agentDiscrepancy = sessionStorage.getItem("agentDiscrepancyStatus")
  useEffect(() => {
    if(agentDiscrepancyStatus == "0" || agentDiscrepancy =="0"){
      sessionStorage.setItem("backButtonHappenAferEnd",true)
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    }
    const user = sessionStorage.getItem("user");
    parsedUser = JSON.parse(user);

    const { capturedImage, uploadimg, epanPassword } = state;
    // console.log( capturedImage, uploadimg, epanPassword ,"INSSSSSSSSSSSSIDE THE USEEFFFECT")
    if (capturedImage) {
      // console.log("capturedImage", capturedImage);
      setImgPath(capturedImage);
      setBase64URL(true);
    } else if (epanPassword && uploadimg) {
      // console.log("IT is inside");
      setUploadimagepath(uploadimg);
      setBase64URL(true);
      setPassword(epanPassword);
    } else if (uploadimg) {
      // navigate(RouteNames.PAN_CAPTURE);
      setUploadimagepath(uploadimg);
      setBase64URL(true);
    }

    const vcipkeyData = sessionStorage.getItem("vcipkey");
    setVcipkey(vcipkeyData);
  }, []);
  // pan UI start
  // const [base64, setBase64] = useState("")

  const videoRef = useRef(null);
  const photoRef = useRef(null);

  const goback = () => {
    navigator.mediaDevices.getUserMedia().then((stream) => {
      const video = videoRef.current;
      video.srcObject = stream;
      video.pause();
    });
    navigate(RouteNames.CHOOSE_LANGAUGE);
  };

  const retakePan = () => {
    setImgPath("");
    setisCaptureDisabled(false);
  };

  const submitCapturedPAN = () => {
    // dispatch(actionReqResStatusLoaderSagaAction(true))
    const model = {
      vcipkey: vcipkey,
      panimage: imgPath ? imgPath : uploadimgpath,
      pwd: password == "" ? "" : password,
      rrn: "1",
    };
    // console.log(model)
    dispatch(actionPanCapture({ model: model, callback: getPanCapturedData }));
  };

  const getPanCapturedData = (data) => {
    // setImgPath("");

    if (data.respcode == "409" && data.respdesc == "incorrect password") {
      // navigate(RouteNames.EMPLOYEMENT_DETAILS);
      navigate(-1);
    }

    // console.log(data,"-----------------------")
    setPanDetails(data);

    if (data.respcode == "304") {
      setErrorState(true);
      return;
    }
    if (!isClientTideAccess && data.respcode == "200") {
      // navigate(Rou) {
      setisCaptureDisabled(true);
    }
    dateFormat(data?.pandetails?.dob);
    if (data.respcode == "200" && isClientTideAccess) {
      if (data?.pandetails?.pannumber && vcipkey) {
        const panEncode = base64.encode(data?.pandetails?.pannumber);
        const model2 = {
          vcipkey: vcipkey,
          pannumber: panEncode,
          rrn: "1",
        };
        dispatch(
          actionVerifyPanNumber({
            model: model2,
            callback: tidepanverify,
          })
        );
      } else {
        toast.error(<Text tid="OCR_extraction_failed_please_try_again" />);
      }
    }
  };
  const tidepanverify = (data) => {
    if (data.respcode == "200") {
      if (checkIfOccupationIsEmpty(parsedUser?.custoccupname) && !isusfb) {
        navigate(RouteNames.EMPLOYEMENT_DETAILS);
      } else {
        navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
      }
    } else {
      toast(data?.respdesc);
    }
  };

  const dateFormat = (val) => {
    var newdate = val.split("/").reverse().join("-");
    setDateDisplay(newdate);
  };

  const retake = () => {
    setErrorState(false);

    if (isClientAccess) {
      navigate(RouteNames.PAN_CAPTURE, { replace: true });
    } else {
      if (imgPath || uploadimgpath) {
        if (imgPath) {
          if (iskinabank) {
            navigate(RouteNames.KINA_OVD_CAPTURE, { replace: true });
          } else {
            navigate(RouteNames.PAN_CAPTURE, { replace: true });
          }
        } else {
          navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
        }

        // navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
      }
    }
    // else if(uploadimgpath){
    //   navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
    // }
    // setImgPath("");
    // setisCaptureDisabled(false);
    // getVideo();
  };

  const saveKinaIDDetails = (
    edtname,
    edtfname,
    edtdob,
    edtpannumber,
    edtgender,
    edtdateofexpiry,
    edtcountry,
    ref3,
    ref4,
    ref5,
    ref6,
    ref7,
    ref8
  ) => {
    const model = {
      vcipkey: vcipkey,
      edtname: edtname,
      edtfname: edtfname,
      edtdob: edtdob,
      edtpannumber: edtpannumber,
      edtgender: edtgender,
      ref2: edtdateofexpiry,
      ref1: edtcountry,
      ref3: ref3,
      ref4: ref4,
      ref5: ref5,
      ref6: ref6,
      ref7: ref7,
      ref8: ref8,
    };
    dispatch(
      actionSavePanDetails({ model: model, callback: getSaveDetailsRes })
    );
  };

  const savePanDetails = (
    defname,
    defdob,
    defpannumber,
    deffname,
    edtname,
    edtfname,
    edtdob,
    edtpannumber
  ) => {
    const panEncode = base64.encode(
      edtpannumber ? edtpannumber : panDetails?.pandetails?.pannumber
    );
    var newdate=""
    if(defdob.includes("/")){
      newdate =  defdob?.split("/")?.reverse()?.join("/");
    }else if(defdob.includes(".")){
      newdate =  defdob?.split(".")?.reverse()?.join("/");
    }else if(defdob.includes("-")){
      newdate =  defdob?.split("-")?.reverse()?.join("/");
    }

    if(!defname || !newdate){
      return
    }
    const model2 = {
      vcipkey: vcipkey,
      pannumber: panEncode,
      rrn: "1",
      dob: newdate,
      name: defname,
    };
    dispatch(
      actionVerifyPanNumber({
        model: model2,
        callback: (data) =>
          getVerifyPanData(data, edtname, edtfname, edtdob, edtpannumber),
      })
    );
  };

  // const verifyEdit = (edtname,
  //   edtfname,
  //   edtdob,
  //   edtpannumber) => {
  //   const { pandetails: {
  //     name,
  //     fname,
  //     dob,
  //     pannumber
  //   } } = panDetails
  //   if (edtname !== name) return true
  //   if (edtfname !== fname) return true
  //   if (edtdob !== moment(dob,'DD-MM-YYYY').format("YYYY-MM-DD")) return true
  //   if (edtpannumber !== pannumber) return true
  //   return {
  //     isEdited: true,

  //   }
  //   return false;
  // }

  const getVerifyPanData = (
    data,
    edtname,
    edtfname,
    edtdob,
    edtpannumber,
    edtgender
  ) => {
    if (edtname || edtfname || edtdob || edtpannumber || edtgender) {
      const model = {
        vcipkey: vcipkey,
        edtname: edtname.length > 0 ? edtname : "",
        edtfname: edtfname.length > 0 ? edtfname : "",
        edtdob: edtdob.length > 0 ? edtdob : "",
        edtpannumber: edtpannumber.length > 0 ? edtpannumber : "",
      };
      // const isEdited=verifyEdit(edtname, edtfname, edtdob, edtpannumber)
      // if (isEdited===true) {
      dispatch(
        actionSavePanDetails({ model: model, callback: getSaveDetailsRes })
      );
      // }
      // else{
      //   getSaveDetailsRes()
      // }
    } else {
      // if (parsedUser?.custoccupname == "" && !isusfb) {
      //   navigate(RouteNames.EMPLOYEMENT_DETAILS);
      // } else {
      //   navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
      // }
      getSaveDetailsRes();
    }
  };

  const getSaveDetailsRes = (data) => {
    if (checkIfOccupationIsEmpty(parsedUser?.custoccupname) && !isusfb) {
      navigate(RouteNames.EMPLOYEMENT_DETAILS);
    } else {
      navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
    }
  };

  // const panCapture_pic = () => {
  //   navigate(RouteNames.PAN_CAPTURE)
  // }

  const handleTakePhoto = (dataUri) => {
    const base64result = dataUri.split(",")[1];
    setImgPath(base64result);
    setBase64URL(true);
  };

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
    clientName === CleintNames?.TIDEQA ||
    clientName === CleintNames?.TIDE
      ? true
      : false;
  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;
  return (
    <>
      {(imgPath || uploadimgpath) && !isCaptureDisabled ? (
        <>
          <div
            style={{
              height: "calc(100vh - 55px)",
              position: "relative",
              zIndex: 99,
            }}
          >
            {isClientTideAccess ? (
              <Tideheader
                noarrow={"noarrow"}
                text={"Confirm ID"}
                navigate={() => {
                  navigate(-1);
                }}
              />
            ) : (
              <div className="pandisplaytext">
                {iskinabank ? (
                  <Text tid="confirm_id" />
                ) : (
                  <Text tid="confirm_PAN_proof" />
                )}
              </div>
            )}
            <article className="display-img app-body">
              <div className=" text-center capture-photo mb-3">
                {base64URL && imgPath && password == "" && (
                  <img
                    src={"data:image/png;base64," + imgPath}
                    className="panimg"
                  />
                )}
                {base64URL && uploadimgpath && password == "" && (
                  <img
                    src={"data:image/png;base64," + uploadimgpath}
                    className="panimg"
                  />
                )}

                {base64URL && uploadimgpath && password != "" && (
                  <img src="images/pdflogo.png" className="panimg1" />
                )}
              </div>
              {isClientTideAccess ? (
                <div style={{ marginTop: "20px",marginBottom:"20px" }}>
                  <Text tid="please_ensure_you_capture_an_image_of_your_physical_PAN_card_ePAN_images_are_not_accepted" />
                </div>
              ) : null}
              <div
              className={
                "panverifybutton pan-bx-btns text-center mt-3 display-btn"
              }
              style={{
                backgroundColor: isClientTideAccess
                  ? "rgb(241, 243, 244)"
                  : "FFFFFFF2",
              }}
            >
              <Button
                className="btn w-auto mx-2"
                type="button"
                disabled={isLoading || errorState}
                click={submitCapturedPAN}
                title={
                  isClientTideAccess ? (
                    <Text tid="looks_good" />
                  ) : (
                    <Text tid="submit" />
                  )
                }
                isClientTideAccess={isClientTideAccess}
                color={cssProperties?.button?.text_color}
                fontSize={cssProperties?.button?.font_size}
                backgroundColor={cssProperties?.button?.color}
              />
              <Button
                className={
                  isClientTideAccess
                    ? "tidepanbtn w-auto retake mx-2"
                    : "panbtn w-auto retake mx-2"
                }
                border={cssProperties?.button?.color}
                type="button"
                disabled={isLoading}
                color={cssProperties?.button?.color}
                click={retake}
                title={
                  isClientTideAccess ? (
                    <Text tid="try_again" />
                  ) : base64URL && imgPath ? (
                    <Text tid="retake" />
                  ) : (
                    <Text tid="re_upload" />
                  )
                }
              />
              
              {/* <button className='btn w-auto retake mx-2' disabled={isLoading} onClick={retake}>
              <Text tid="retake" />
            </button>
            <button className='btn w-auto mx-2' disabled={isLoading} onClick={submitCapturedPAN}>
              <Text tid="submit" />
            </button> */}
            </div>
            </article>
            {/* <button className='btn w-100' onClick={submitCapturedPAN}>Submit</button> */}
            
          </div>
        </>
      ) : null}

      {isCaptureDisabled && (
        <PanDetailsEdit
          panDetails={panDetails?.pandetails}
          dateDisplay={dateDisplay}
          savePanDetails={savePanDetails}
          saveKinaIDDetails={saveKinaIDDetails}
          navigate={navigate}
        />
      )}

      {/* {imgPath && <AppFooter
        btnName="Submit"
        navigate={submitCapturedPAN}
      />} */}
    </>
  );
};

export default PanKYC;
