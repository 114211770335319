import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import './styles.css';
import Button from '../Elements/Button';

const Modal = ({ isOpen, onClose, bodyContent, footerButtons }) => {

  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className='modalbody ' style={{margin:"2%"}}>
          {/* <span className="close-icon" onClick={onClose}>&times;</span> */}
          <div className='pep-modal-wrapper'>
            {bodyContent}
            <Button
            className="btn btn-white mt-2 pep-info-btn"
            type="button"
            datatoggle="modal"
            datatarget="#verification"
            click={()=>onClose(false)}
            title={"OK"}
            color={cssProperties?.button?.text_color}
            fontSize={cssProperties?.button?.font_size}
            backgroundColor={cssProperties?.button?.color}
        />
          </div>
          {/* <div className="modal-footer">
          {footerButtons.map((button, index) => (
            <button
              key={index}
              className={`button ${button.className}`}
              onClick={button.onClick}
            >
              {button.label}
            </button>
          ))}
        </div> */}
         {/* <Button
            className="btn btn-white mb-3"
            type="button"
            datatoggle="modal"
            datatarget="#verification"
            click={onClose}
            title={"OK"}
            color={cssProperties?.button?.text_color}
            fontSize={cssProperties?.button?.font_size}
            backgroundColor={cssProperties?.button?.color}
        /> */}
        </div>
    </div>
  );
};

Modal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    bodyContent: PropTypes.node,
    footerButtons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
  };
  
  Modal.defaultProps = {
    isOpen: false,
    onClose: () => {},
    bodyContent: <p>Modal Body Content</p>,
    footerButtons: [
      { label: 'Cancel', className: 'secondary', onClick: () => {} },
      { label: 'Confirm', className: 'primary', onClick: () => {} },
    ],
  };

export default Modal;