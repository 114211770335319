import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import KYCCompletedCmp from "../../Components/KYCCompletedCmp";
import AppFooter from "../Common/AppFooter";
import animation from "./Abc";
import Header from "../../Layout/Header";
import { Text } from "../Language/Language";
import {
  actionGetVcipDetails,
  getVcipStatusesAction,
} from "../../Store/SagaActions/GetVcipDetailsSagaActions";
import Button from "../../Components/Elements/Button";
import StatusCodes from "../../Constants/StatusCodes";
import CleintNames from "../../Constants/ClientNames";
import { useLocation } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import customeCodes from "../../Constants/customeCodes";
import { storeAgentDiscrepanctStatus } from "../../Store/SagaActions/VideoSagaActions";

var intervalClearId = null;
var timeinterval= null;
const KYCCompleted = () => {
  let windowopenStatus;

  const [counter, setCounter] = useState(3);
  const [ischoose, setIsChoose] = useState(false);
  const [customerVcipDetails, setCustomerVcipDetails] = useState({});
  const [vcipStatuses, setVcipStatuses] = useState({});

  const user = sessionStorage.getItem("user");
  const parsedUser = JSON.parse(user);

  const location = useLocation();
  const reloaderr = localStorage.getItem("reloaderr");
  const isReloaded = sessionStorage.getItem("isReloaded");
  const isAutoCallEnded = sessionStorage.getItem("isAutoCallEnded");
  const tokenSessionExpire = sessionStorage.getItem("tokenSessionExpire");
  const isVideoStreamDisconnected = sessionStorage.getItem("isVideoStreamDisconnected");
  const agentendcall= sessionStorage.getItem("agentendcall");
  const finalendcall = sessionStorage.getItem("finalendcall");
  const [clearApi, setClearApi] = useState(false)

  const dispatch = useDispatch();
  let navigate = useNavigate();
  window.onpopstate = () => {
    navigate(null);
  }

  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const clientName = useSelector((state) => state.HomeReducer.clientName);

  
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { streamingType, streamingURL } = dynamicSettingObj;
  const vciprefvalue = useSelector((state)=>state.HomeReducer.vcipref)

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
    clientName === CleintNames?.TIDEQA ||
    clientName === CleintNames?.TIDE
      ? true
      : false;
  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false
  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;
  // const isusfb = clientName === CleintNames?.USFB ? true : false; 
  const isusfb = (clientName === CleintNames?.USFB || clientName === CleintNames?.USFBSYNTIZEN) ? true : false; 


  useEffect(() => {
    sessionStorage.removeItem("InitiateVCFQStorage");
    sessionStorage.removeItem("scheduleDetailsStorage");
    timeinterval = setInterval(()=>{
      if(clearApi == true){
        clearInterval(timeinterval)
      }else{
        getInitialVcipDetails();
      }
    },2000)
    return () => {
      clearInterval(intervalClearId);
      clearInterval(timeinterval)
      // sessionStorage.clear()
      // localStorage.clear()
    };
  }, []);

  const getInitialVcipDetails = () => {
    const _refId = sessionStorage.getItem("vcipref");
    const _model = {
      vcipref: _refId || vciprefvalue,
    };
    // first api 'GetVcipLinkDetails'
    dispatch(
      actionGetVcipDetails({ 
        model: _model, 
        callback: getInitialVcipDetailsData,
        isFinalScreen: true,
       })
    );
  };

  const getInitialVcipDetailsData = (data) => {
    if(data?.respcode == "200"){
      clearInterval(timeinterval)
      setClearApi(true);
      // calling second api 'GetCustomerVCIPStatus'
      getVcipStatusesUpdate();
    }
    const isPendingStatus = data?.vcipstatus !== StatusCodes.VCIPSTATUS_PENDING;
    setCustomerVcipDetails(data);
    if (isPendingStatus) {
      clearInterval(intervalClearId);
      return;
    }
    // intervalClearId = setInterval(() => {
    //   getVcipDetails();
    // }, 2000);
  }

  const getVcipDetails = () => {
    const refId = sessionStorage.getItem("vcipref");
    const model = {
      vcipref: refId,
    };
    dispatch(
      actionGetVcipDetails({ model: model, callback: getVcipDetailsData })
    );
  };

  const getVcipStatusesUpdate = () => {
    const model = {
      refid: parsedUser?.vcipid,
      reftype: "1",
      acccode: "",
    };
    // second API 'GetCustomerVCIPStatus'
    dispatch(
      getVcipStatusesAction({ model: model, callback: getVcipStatusesData })
    );
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 5000);
    // setTimeout(() => setIsChoose(false)
    // , 2000);
    setIsChoose(false);
  }, [counter]);

  useEffect(()=>{
    const backButtonHappenAferEnd = sessionStorage.getItem("backButtonHappenAferEnd")
    if(Object.values(customerVcipDetails)?.length == 0 && backButtonHappenAferEnd){
      sessionStorage.removeItem("backButtonHappenAferEnd")
      window?.location?.reload()
    }
  },[customerVcipDetails])

  const getVcipStatusesData = (data) => {
    setVcipStatuses(data);
    const agentStatus = data?.agent_discrepancy_status
    sessionStorage.setItem("agentDiscrepancyStatus",data?.agent_discrepancy_status)
    dispatch(storeAgentDiscrepanctStatus(agentStatus))
  };

  const getVcipDetailsData = (data) => {
    const isPendingStatus = data?.vcipstatus !== StatusCodes.VCIPSTATUS_PENDING;
    if (isPendingStatus) {
      clearInterval(intervalClearId);
    }
    setCustomerVcipDetails(data);
  };

  // console.log(customerVcipDetails);
  const nextPage = (e) => {
    e.preventDefault()
    // localStorage.clear()
    // sessionStorage.clear()
    clearInterval(intervalClearId);
    if(isClientTideAccess && isCompleted){
      setIsChoose(true);
    }else if(!isClientTideAccess){
      setIsChoose(true);
    }
    var app = {
      launchApp: function () {
        const url = "vkycapp://syntizen";
        window.location.replace(url);
        this.timer = setTimeout(this.openWebApp, 3000);
      },
      openWebApp: function () {
        // window.location.replace(customerVcipDetails?.redirecturl);
        if (customerVcipDetails?.acccode === CleintNames?.LOGIPE || customerVcipDetails?.acccode === CleintNames?.CASHBOOK ) {
          window.location.replace(customerVcipDetails?.ref3);
        } else {
          window.location.replace(customerVcipDetails?.redirecturl);
        }
      },
    };

    if (customerVcipDetails?.redirectflag === "0") {
      // 0 -default no navigate 1-web url 2- mobile redirect
      // app.launchApp();
      setTimeout(() => {
        // sessionStorage.clear();
        setIsChoose(false);
        // app.launchApp();
        // navigate("/", { replace: true });
      }, 2000);
    } else if (customerVcipDetails?.redirectflag === "1") {
      setTimeout(() => {
        // sessionStorage.clear();
        setIsChoose(false);
        if (customerVcipDetails?.acccode === CleintNames?.LOGIPE) {
          window.location.replace(customerVcipDetails?.ref3);
        } else {
          window.location.replace(customerVcipDetails?.redirecturl);
        }
      }, 2000);
    } else if (customerVcipDetails?.redirectflag === "2") {
      setTimeout(() => {
        // sessionStorage.clear();
        setIsChoose(false);
        app.openWebApp();
      }, 2000);
    }
  };

  const closeTab = () => {};
  // const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: animation,

  //     // here is where we will declare lottie animation
  //     // "animation" is what we imported before animationData: animation,
  //     rendererSettings: {
  //        preserveAspectRatio: "xMidYMid slice",
  //     },
  //  };

  const retryKYC = () => {
    clearInterval(intervalClearId);
    const url = sessionStorage.getItem("link");
    sessionStorage.removeItem("isReloaded");
    // window.location.replace(url);
    const urlArr = url?.split('=');
    localStorage.clear()
    sessionStorage.clear()
    navigate("/d="+urlArr[1], { replace: true });
  };

  let isAgentStatusUdpatedDiscrepancy = false; // agentstatus ===> 0 - NO STATUES UPDATED, 1 - approved, 2 - rejected, 3 - DISCREPANCY
  if (vcipStatuses?.agent_discrepancy_status === "1") {
    // agent_discrepancy_status ===> 0 - NO_DISCREPANCY, 1 - DISCREPANCY
    isAgentStatusUdpatedDiscrepancy = true;
  } else {
    isAgentStatusUdpatedDiscrepancy = false;
  }

  // let isLiveCaptured = vcipStatuses?.livecapturestatus !== "0";

  const isUnderReview =
    customerVcipDetails?.vcipstatus === StatusCodes.VCIPSTATUS_UNDERREVIEW ||
    customerVcipDetails?.vcipstatus === StatusCodes.VCIPSTATUS_ONHOLDBYAGENT;
  const isCompleted =
    customerVcipDetails?.vcipstatus === StatusCodes.VCIPSTATUS_COMPLETED;
  const isPending =
    customerVcipDetails?.vcipstatus === StatusCodes.VCIPSTATUS_PENDING;
  const isRejected =
    customerVcipDetails?.vcipstatus === StatusCodes.VCIPSTATUS_REJECTED;

  let kycStatusTitle;
  let kycStatusTxt;
  let kycStatusImg;
  
  if (!isClientTideAccess) {
    kycStatusTitle = "kyc_processing";
    kycStatusTxt = <Text tid="kyc_processing" />
    // kycStatusImg = "../images/incomplete.png";
    kycStatusImg = "../images/kyc-processing-clock.png";
  }

  // console.log(agentendcall,"in kyc completed screen ---------------- *** isLiveCaptured", isLiveCaptured, 2)

  if (isPending) {
    if (
      isAgentStatusUdpatedDiscrepancy == true ||
      isAgentStatusUdpatedDiscrepancy == false ||
      isReloaded ||
      isAutoCallEnded ||
      tokenSessionExpire ||
      isVideoStreamDisconnected ||
      agentendcall || finalendcall
    ) {
      // clearInterval(intervalClearId);
       kycStatusTitle = "kyc_interrupted";
       kycStatusImg = "../images/incomplete.png";
       if(agentendcall && isAgentStatusUdpatedDiscrepancy == false && !isClientTideAccess && !isReloaded && !isAutoCallEnded && !isVideoStreamDisconnected && finalendcall){
        // kycStatusTitle = "KYC Processing";
        // kycStatusTitle = "KYC Interrupted";
        kycStatusTxt = "";
        kycStatusTitle = "kyc_completed_and_under_review"
        kycStatusImg = "images/KYC_process_icon.svg";
       }else if(agentendcall && isAgentStatusUdpatedDiscrepancy == false && isClientTideAccess && !isReloaded && !isAutoCallEnded && isVideoStreamDisconnected && finalendcall && tokenSessionExpire){
        kycStatusTitle = "kyc_completed_and_under_review"
        kycStatusTxt = "";
        kycStatusImg = "images/Application-pending.png"
       }
       if(isAgentStatusUdpatedDiscrepancy == false && !isClientTideAccess && !isReloaded && !isAutoCallEnded && !isVideoStreamDisconnected && finalendcall){
        // kycStatusTitle = "KYC Processing";
        // kycStatusTitle = "KYC Interrupted";
        kycStatusTxt = "";
        kycStatusTitle = "kyc_completed_and_under_review"
        kycStatusImg = "images/KYC_process_icon.svg";
       }else if(isAgentStatusUdpatedDiscrepancy == false && isClientTideAccess && !isReloaded && !isAutoCallEnded && !isVideoStreamDisconnected && finalendcall){
        kycStatusTitle = "kyc_completed_and_under_review"
        kycStatusTxt = "";
        kycStatusImg = "images/Application-pending.png"
       }
      if (isReloaded || (tokenSessionExpire && !agentendcall)) {
        clearInterval(intervalClearId);
        if(isClientTideAccess){
          kycStatusTxt = (
            <p>
              <Text tid='screen_has_been_reloaded' />
              <br /> <Text tid='reinitiate_your_kyc_to_continue' />
            </p>
          );
        }else{
          kycStatusTxt = (
            <p>
               <Text tid='oops_looks_like_page_is_reloaded' />
              <br /> <Text tid='we_recommend_you_reinitiate_kyc' />
            </p>
          );
        }
        
      } else if(isAgentStatusUdpatedDiscrepancy == false && isClientTideAccess && !isReloaded && !isAutoCallEnded && isVideoStreamDisconnected && finalendcall){
        kycStatusTitle = "kyc_completed_and_under_review"
        kycStatusTxt = "";
        kycStatusImg = "images/Application-pending.png"
      }
      else if (isVideoStreamDisconnected) {
        clearInterval(intervalClearId);
        kycStatusTxt = (
          <p>
            <Text tid='oops_looks_like_video_call_was_dropped' />
            <br /> <Text tid='we_recommend_you_reinitiate_kyc' />
          </p>
        );
      } else if (isAutoCallEnded) {
        clearInterval(intervalClearId);
        if(isClientTideAccess){
          kycStatusTxt = (
            <p>
              <Text tid='unable_to_connect_with_agent_right_now' />
              <br /> <Text tid='we_recommend_you_to_reinitiate_kyc_process' />
            </p>
          );
        }else{
          kycStatusTxt = (
            <p>
              <Text tid='looks_like_agent_could_not_take_the_call' />
              <br /> <Text tid='we_recommend_you_reinitiate_kyc' />
            </p>
          );
        }
      } else if(isAgentStatusUdpatedDiscrepancy == true){
        clearInterval(intervalClearId);
        if(isClientTideAccess){
          kycStatusTxt = (
            <p>
              <Text tid='your_call_was_disconnected' />
              <br /><Text tid='reinitiate_your_kyc' />
            </p>
          );
        }else{
          kycStatusTxt = (
            <p>
              <Text tid='your_call_was_disconnected' />

              <br /> <Text tid='we_recommend_you_reinitiate_kyc' />
            </p>
          );
        }
      
      }else if(isAgentStatusUdpatedDiscrepancy === false && !finalendcall) {
        if(isCashbook){
          kycStatusTitle = "successfully_completed_kyc_process_check_after_24hrs";
          kycStatusTxt = "";
        }else if (isAgentStatusUdpatedDiscrepancy === false){
          kycStatusTitle = "kyc_interrupted";
          kycStatusTxt = (
            <p>
              <Text tid='your_call_was_disconnected' />
              <br /><Text tid='reinitiate_your_kyc' />
            </p>
          );
          kycStatusImg = "../images/incomplete.png";
        }else{
          kycStatusTitle = "kyc_completed_and_under_review";
          kycStatusTxt = "";
        }
        // kycStatusTxt = "In case, your process was interrupted or your call was disconnected, we recommend you reinitiate your KYC.";
        if (isClientTideAccess) {
          if(streamingType === customeCodes.STREAMING_TYPE_OPENVIDU_VALUE){
            kycStatusTxt = (
              <p>
                <Text tid='your_call_was_disconnected' />
                <br /><Text tid='reinitiate_your_kyc' />
              </p>
            );
            kycStatusImg = "../images/incomplete.png";
            kycStatusTitle = "kyc_interrupted";
          }else{
            kycStatusImg = "/images/Application-pending.png";
          }         
        } else {
          kycStatusImg = "../images/KYC_process_icon.svg";
        }
      }
    } else{
      if(isClientTideAccess){
        kycStatusTxt = (
          <p>
            <Text tid='your_call_was_disconnected' />
            <br /><Text tid='reinitiate_your_kyc' />
          </p>
        );
        kycStatusImg = "../images/incomplete.png";
        kycStatusTitle = "kyc_interrupted";
      }else{
        kycStatusTxt = (
          <p>
            <Text tid='your_call_was_disconnected' />

            <br /> <Text tid='we_recommend_you_reinitiate_kyc' />
          </p>
        );
        kycStatusImg = "../images/incomplete.png";
        kycStatusTitle = "kyc_interrupted";
      }
    }
  } else if (isUnderReview) {
    if (isClientTideAccess) {
      kycStatusTitle = "kyc_under_review";
      kycStatusTxt =
      <Text tid="we_will_notify_once_your_kyc_is_approved_and_your_data_is_stored" />;
      kycStatusImg = "/images/Application-review@2x.png";
    } else if(iskinabank){
      kycStatusTitle = "kyc_under_review";
      kycStatusTxt = <Text tid="digital_kyc_is_verified_by_agent_waiting_for_auditor" />
      kycStatusImg = "../images/auditor_pending.svg";
    }else{
      kycStatusTitle = "kyc_under_review";
      kycStatusTxt = customerVcipDetails?.respdesc;
      kycStatusImg = "../images/auditor_pending.svg";
    }
  } else if (isRejected) {
    if (isClientTideAccess) {
      kycStatusTitle = "kyc_rejected";
      kycStatusTxt = <Text tid="we_will_notify_once_your_kyc_is_approved_and_your_data_is_stored" /> ;
      kycStatusImg = "/images/Application-error.png";
    } else {
      kycStatusTitle = "kyc_rejected";
      kycStatusTxt = <Text tid="your_kyc_got_rejected" /> ;
      kycStatusImg = "../images/google-docs.png";
    }
  } else if (isCompleted) {
    if (isClientTideAccess) {
      kycStatusTitle = "kyc_completed";
      kycStatusTxt = <Text tid="we_will_notify_once_your_kyc_is_approved_and_your_data_is_stored" />
      kycStatusImg = "../images/Frame 1607.png";
    }else if(isusfb){
      kycStatusTitle = "kyc_completed";
      kycStatusTxt = "Your Video KYC is completed & forward for further processing"
      kycStatusImg = "/images/completed.svg";
    }else{
      kycStatusTitle = "kyc_completed";
      kycStatusTxt = <Text tid="your_verification_is_success_and_data_stored" />
      kycStatusImg = "/images/completed.svg";
    }
  }
  let retryimage;
  if (
    (isPending && isAgentStatusUdpatedDiscrepancy == true) ||
    isReloaded ||
    isAutoCallEnded ||
    tokenSessionExpire ||
    isVideoStreamDisconnected ||
    !agentendcall
  ) {
    retryimage = 1;
  }
  return (
    <>
      {
        (customerVcipDetails.respcode != "200")&& (
          <Loading/>
        )
      }
      {isClientTideAccess ? (
        <Header title={" "} navigate={""} hideHeader={false} />
      ) : (
        <Header
          title={<Text tid="status" />}
          navigate={""}
          hideHeader={false}
        />
      )}
      <article className="app-body kycsubmitstaus">
        <KYCCompletedCmp
          customerVcipDetails={customerVcipDetails}
          counter={counter}
          ischoose={ischoose}
          kycStatusImg={kycStatusImg}
          kycStatusTitle={<Text tid={kycStatusTitle} />}
          kycStatusTxt={kycStatusTxt}
          retryKYC={retryKYC}
          isClientTideAccess={isClientTideAccess}
          isPending={isPending}
          retryimage={retryimage}
          isUnderReview={isUnderReview}
        />

        {(customerVcipDetails.respcode == "200") ? (
          <div
            className={"kyccombtn"}
            style={{
              backgroundColor:isClientTideAccess
                ? "rgb(241, 243, 244)"
                : "#FFFFFFF2",
              paddingBottom:"3%"
            }}
          >
            {(isPending && isAgentStatusUdpatedDiscrepancy == true) || isAgentStatusUdpatedDiscrepancy == false ||
            (agentendcall && !isUnderReview)||
            isReloaded ||
            isAutoCallEnded ||
            tokenSessionExpire && !agentendcall ||
            agentendcall && finalendcall ||
            isVideoStreamDisconnected && agentendcall  
             // ||
            // isAgentStatusUdpatedDiscrepancy == false ||
            // (isAgentStatusUdpatedDiscrepancy == false &&
            //     (!isReloaded) &&
            //     !agentendcall&&
            //     !isAutoCallEnded &&
            //     !tokenSessionExpire &&
            //     !isVideoStreamDisconnected) 
             ? (
              <Button
                className="btn mb-3"
                type="button"
                click={retryKYC}
                title={isClientTideAccess ? <Text tid="retry_button"/>:<Text tid="Retry_KYC_button" />}
                isClientTideAccess={isClientTideAccess}
                color={cssProperties?.button?.text_color}
                fontSize={cssProperties?.button?.font_size}
                backgroundColor={cssProperties?.button?.color}
              />
            ) : null}
          </div>
        ) : null}

        {(customerVcipDetails.respcode == "200" )? (
          <div
            className={"kyccombtn"}
            style={{
              backgroundColor: isClientTideAccess
                ? "rgb(241, 243, 244)"
                : "#FFFFFFF2",
              paddingBottom:"3%"
            }}
          >
            {!isPending|| (agentendcall && !isReloaded && isAgentStatusUdpatedDiscrepancy == false && !isAutoCallEnded && !isVideoStreamDisconnected)
             || isUnderReview || isCompleted
             ||isAgentStatusUdpatedDiscrepancy == false && !isReloaded && !isAutoCallEnded && !isVideoStreamDisconnected && finalendcall
             || isAgentStatusUdpatedDiscrepancy == false && isClientTideAccess && !isReloaded && !isAutoCallEnded && isVideoStreamDisconnected && finalendcall
              ? (
              <AppFooter
                btnName="Done"
                navigate={nextPage}
                isClientTideAccess={isClientTideAccess}
              />
            ) : null}
          </div>
        ) : null}
      </article>
    </>
  );
};

export default KYCCompleted;
