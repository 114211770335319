import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Text } from "../../../Pages/Language/Language";
import H4 from "../../Elements/H4";
import P from "../../Elements/P";
import "./EmploymentDropdownComp.css";
import CleintNames from "../../../Constants/ClientNames";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Modal from "../../Modal";
import Button from "../../Elements/Button";


const PEPDeclaration = [{
  PEP_Info: "You are a Politically Exposed Person (PEP) if you hold or you have held a high public position of a foreign country, such as:",
PEP_Points: ["President", "Minister","Military", "Senior Government Official",
 "Judicial", "State-Owned Company Executive",
"Political Party Leader or senior politician"],
PEP_Consent: "If you do not fall under this category, kindly select No."
},
{
  PEP_Info: "यदि आप किसी विदेशी देश में उच्च सार्वजनिक पद पर हैं या रह चुके हैं, तो आप एक राजनीतिक रूप से प्रभावशाली व्यक्ति (PEP) माने जाएंगे, जैसे:",
  PEP_Points: ["राष्ट्रपति","मंत्री","सैन्य", "वरिष्ठ सरकारी अधिकारी","न्यायिक", "राज्य-नियंत्रित कंपनी के कार्यकारी अधिकारी",
    "राजनीतिक दल के नेता या वरिष्ठ राजनीतिज्ञ"],
  PEP_Consent: "यदि आप इस श्रेणी में नहीं आते हैं, तो कृपया 'नहीं' चुनें।"
}
]

const getModalBody = () => {
  return (
    <>
      {PEPDeclaration.map((pep, index) => (
        <div key={index} className="pep-section">
          <div>{pep.PEP_Info}</div>
          <ul className="pep-list">
            {pep.PEP_Points.map((point, idx) => (
              <li key={idx}>{point}</li>
            ))}
          </ul>
          <div>{pep.PEP_Consent}</div>
        </div>
      ))}
    </>
  );

};

export default function EmploymentDropdownComp(props) {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const {clientName, dynamicSettingObj } = useSelector((state) => state.HomeReducer);
  const { app_env } = dynamicSettingObj;
  const [showPEPInfo, setPEPInfo] = useState(true)
  const showInputField = props?.showOccupationalInputField === "true" &&
    props?.selectSubOccupation?.data?.length === props?.selectedValues?.length;
  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  const showPEPInfoDialog = app_env == "lq_uat" || app_env =="lq_prod" ;
      
  return (
    <section className="occupations-dropdown-options-container" style={{marginBottom:`${props?.showOccupationNewUI? "30px":"0px"}`}}>

      {showPEPInfoDialog ? <Modal
        isOpen ={showPEPInfo}
        bodyContent={getModalBody()}
        onClose={setPEPInfo}
      />:""}
      {props?.showOccupationNewUI ? (
        <>
          {/* <div className="form-group">
                        <label htmlFor="businessName" className="form-label">Business name</label>
                        <input
                            type="text"
                            name="businessName"
                            className="form-input"
                            value={props?.bussinessName}
                            onChange={props?.handleAdditionalInfoChange}
                        />
                        <p className="form-help-text">If you're a freelancer, enter your full name</p>
                    </div> */}
          <div className="employment-dropdown-container mt-3">
            {/* <div className="select-wrapper"> */}
            <label htmlFor="occupation" className="form-label">
              Occupation
            </label>
            <div className="form-dropdown">
              <img
                src="../images/dropdownarrow.png"
                alt="arrow"
                className="dropdown-arrow"
              />
              <select
                value={props?.selectedOccupation?.code}
                onChange={props?.handleSelectChange}
                className={`form-select_tide application-type ${isClientTideAccess ? "occupationalDropDownTc" : ""
                  }`}
                id="inputGroupSelect01"
              >
                <option value="">Select occupation</option>
                {props?.ocupationList?.map((occupation, index) => (
                  <option key={index} value={occupation.code}>
                    {occupation.name}
                  </option>
                ))}
              </select>
            </div>
            {props?.selectSubOccupation?.data?.length > 0 &&
              props?.selectSubOccupation?.data?.map((item, index) => {
                if (index > props?.selectedIndex) return null;
                const isCurrencyDropdown = item.name === "T4";
                const defaultValue = isCurrencyDropdown
                  ? props?.selectedValues[index]?.code || "INR"
                  : props?.selectedValues[index]?.code || "";
                return (
                  <div key={index} className="mt-3">
                    <label
                      htmlFor={props?.showLabelNames(
                        props?.selectSubOccupation?.data[index]?.name
                      )}
                      className="form-label"
                    >
                      {" "}
                      {props?.showLabelNames(
                        props?.selectSubOccupation?.data[index]?.name
                      )}
                    </label>
                    <div className="form-dropdown">
                      <img
                        src="../images/dropdownarrow.png"
                        alt="arrow"
                        className="dropdown-arrow"
                      />
                      <select
                        className={`form-select_tide application-type ${isClientTideAccess ? "occupationalDropDownTc" : ""
                        }`}
                        id="inputGroupSelect01"
                        // style={{ marginTop: "10px" }}
                        value={defaultValue}
                        // value={props?.selectedValues[index]?.code || ""}
                        onChange={(e) =>
                          props?.handleSelectOccupationChange(e, index)
                        }
                      >
                        <option value="">Select value</option>
                        {item?.value.map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                );
              })}
          </div>
          <p className="form-note">
            Note: Tide may ask you to verify these details by a supporting
            document.
          </p>
        </>
      ) : (
        <>
          <div
            className="app-body-imgs"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src="../images/employement_Details.png" alt="vkyc" />
          </div>
          <div className="app-body-data">
            <H4
              className="title"
              title={<Text tid="occupation_details" />}
              color={cssProperties?.pagetitle_heading?.font_color}
              fontSize={cssProperties?.pagetitle_heading?.font_size}
            />

            <P
              className="txt inline"
              txt={"Please Select the occupation details from the drop down"}
              color={cssProperties?.body?.font_color}
              fontSize={cssProperties?.body?.font_size}
              styles={{dispaly:"inline"}}
            />
            <hr />
          </div>
          <div className="employment-dropdown-container">
            {/* <div className="select-wrapper"> */}
            <select
              value={props?.selectedOccupation?.code}
              onChange={props?.handleSelectChange}
              className={`form-select application-type ${isClientTideAccess ? "occupationalDropDownTc" : ""
              }`}
              id="inputGroupSelect01"
            >
              <option value="">Select value</option>
              {props?.ocupationList?.map((occupation, index) => (
                <option key={index} value={occupation.code}>
                  {occupation.name}
                </option>
              ))}
            </select>
            {props?.selectSubOccupation?.data?.length > 0 &&
              props?.selectSubOccupation?.data?.map((item, index) => {
                if (index > props?.selectedIndex) return null;
                return (
                  <div key={index}>
                    <select
                      className={`form-select application-type ${isClientTideAccess ? "occupationalDropDownTc" : ""
                      }`}
                      id="inputGroupSelect01"
                      style={{ marginTop: "10px" }}
                      value={props?.selectedValues[index]?.code || ""}
                      onChange={(e) =>
                        props?.handleSelectOccupationChangeLQ(e, index, item)
                      }
                    >
                      <option value="">Select value</option>
                      {item.value.map((option) => (
                        <option key={option.code} value={option.code}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              })}
            {showInputField && (
              <div className="form-group">
                <label
                  className="additional-info-label"
                  style={{ marginTop: "10px", fontSize: "16px", color: "black", fontWeight: 600 }}
                >
                  Please enter Annual salary amount :
                </label>
                <input
                  type="number"
                  className="form-control add-customer"
                  placeholder=""
                  value={props?.additionalInfo}
                  onChange={props?.handleAdditionalInfoChange}
                  style={{ height: "45px" }}
                />
              </div>
            )}
            {props?.showPepOptions &&
              <>
                <div>
                  <label className={"w-100 mt-3 mb-1"}>
                    Are you politically exposed person
                    {showPEPInfoDialog ? <span style={{padding:"5px"}}onClick={()=>setPEPInfo(true)} >
                  <IoMdInformationCircleOutline />
                  </span> : ""}
                  </label>
                  
                  <div className="row w-100 box-border">
                    <div className="col d-flex align-items-center">
                      <input
                        type={"radio"}
                        value="Y"
                        name="isPoliticallyExposed"
                        id="pep-yes"
                        checked={props?.politicalInfo?.isPoliticallyExposed == "Y"}
                        onChange={props?.handleInputRadioChange}
                      />
                      <label htmlFor="pep-yes">Yes</label>
                    </div>
                    <div className="col d-flex align-items-center">
                      <input
                        type={"radio"}
                        value="N"
                        name="isPoliticallyExposed"
                        id="pep-no"
                        checked={props?.politicalInfo?.isPoliticallyExposed == "N"}
                        onChange={props?.handleInputRadioChange}
                      />
                      <label htmlFor="pep-no">No</label>
                    </div>
                  </div>
                </div>
                <div>
                  <label className={"w-100 mt-3 mb-1"}>
                    Are you related to a PEP?
                  </label>
                  <div className="row w-100 box-border">
                    <div className="col d-flex align-items-center">
                      <input
                        type={"radio"}
                        value="Y"
                        name="isRelatedToPep"
                        id="related-pep-yes"
                        checked={props?.politicalInfo?.isRelatedToPep == "Y"}
                        onChange={props?.handleInputRadioChange}
                      />
                      <label htmlFor="related-pep-yes">Yes</label>
                    </div>
                    <div className="col d-flex align-items-center">
                      <input
                        type={"radio"}
                        value="N"
                        name="isRelatedToPep"
                        id="related-pep-no"
                        checked={props?.politicalInfo?.isRelatedToPep == "N"}
                        onChange={props?.handleInputRadioChange}
                      />
                      <label htmlFor="related-pep-no">No</label>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </>
      )}
    </section>
  );
}
