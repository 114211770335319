import React from 'react'
import './TideMergeScreen.css'


const TideMergeScreen = (props) => {
    const sections = [
        {
            title: "Please ensure that you have:",
            items: [
                { icon: 'fa-credit-card', text: 'Original PAN Card' },
                { icon: 'fa-id-card', text: 'Aadhaar Card' },
                { icon: 'fa-wifi', text: 'Stable Internet Connection' },
            ],
        },
        {
            title: "And the following permissions are enabled:",
            items: [
                { icon: 'fa-camera', text: 'Camera' },
                { icon: 'fa-microphone', text: 'Microphone' },
                { icon: 'fa-location-dot', text: 'Location' },
            ],
        },
    ];

    const renderItems = (items) => {
        return items.map((item, index) => (
            <div className="col-4 text-center icons" key={index}>
                <div className="requirements-circle">
                    <i className={`fa-solid ${item.icon}`}></i>
                </div>
                <p className="mb-0">{item.text}</p>
            </div>
        ));
    };


    return (
        <div className="kyc-container" style={{height: props?.waitingTimeTxt ? "auto" : "90vh"}}>
            <h1 className="text-center mb-4 fw-bold main-heading">Thanks for signing up</h1>

            <p className="text-center mb-4 accessing-heading">
                You're just a Video KYC away from accessing your Tide business account.
            </p>

            {sections.map((section, index) => (
                <React.Fragment key={index}>
                    <h2 className="text-center mb-4 sub-heading">{section.title}</h2>
                    <div className="row g-4 mb-4 icon-text">
                        {renderItems(section.items)}
                    </div>
                </React.Fragment>
            ))}
           
          {props?.waitingTimeTxt ? "" :
           <div className="consent-box">
                <input
                    type="checkbox"
                    className="consent-checkbox"
                    id="consent"
                    checked={props?.isChecked}
                    onChange={props?.handleCheckbox}
                />
                <label htmlFor="consent" className="mb-0 consent-font">
                    I, <span className='font-weight-bold'>{props?.cust_name}</span> with the VCIP ID - <span className='font-weight-bold'>{props?.vcipid}</span>, agree by clicking on Next, for the above given information and consent to,
                    <a className="terms-link" href='https://transcorpint.com/wp-content/uploads/vkyc_terms.pdf' target='_blank'>Transcorp VKYC terms.</a>
                </label>
            </div>}
        </div>
    )
}

export default TideMergeScreen