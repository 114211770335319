import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { detect } from "detect-browser";
import TokenNumberCmp from "../../../Components/VideoCallCmp/TokenNumberCmp/TokenNumberCmp";
import RouteNames from "../../../Constants/RouteNames";
import Button from "../../../Components/Elements/Button";
import {
  actionAddSingleCustomer,
  actionGetInitiateConferenceQueueSagaAction,
  actionGetSingleCustomer,
  actionGetUpdatedTokenSagaAction,
  joinVideoSessionAction,
} from "../../../Store/SagaActions/InitiateVideoCallSagaActions";
import AppFooter from "../../Common/AppFooter";
import Reschedule from "../Reschedule/Reschedule_stage";
import { Text } from "../../Language/Language";
import Header from "../../../Layout/Header";
import H4 from "../../../Components/Elements/H4";
import Instructions from "../../../Components/InstructionsCmp/Instructions";
import CleintNames from "../../../Constants/ClientNames";
import { actionGetVcipDetails } from "../../../Store/SagaActions/GetVcipDetailsSagaActions";
import moment from "moment";
import MicvideocheckmodelLivekit from "../../Modals/MicvideocheckmodelLivekit";
import { actionCreateManagementToken, create100msLiveRoomCodeSagaAction, create100msLiveRoomSagaAction, createLivekitTokenSagaAction } from "../../../Store/SagaActions/VideoSagaActions";
import customeCodes from "../../../Constants/customeCodes";
import Micvideocheckmodel from "../../Modals/Micvideocheckmodel";
import MicvideocheckModal100msLive from "../100msLive/MicVideocheckModal100msLive";
import { isFeatureFlagEnabled } from "../../../utils/validations";

const browser = detect();
const browserDetails = `Name:${browser?.name},Version:${browser?.version},OS:${browser?.os}`


var updateTokenInterval = null;
var expSetTimeOutVal = null;

const TokenNumber = () => {
  const [InitiateVCFQ, setInitiateVCFQ] = useState({});
  const [isRescheduled, setIsRescheduled] = useState(false);
  const [intervalConferenceId, setIntervalConferenceId] = useState(null);
  const [timer, setTimer] = useState(null);
  const [isAPIProcessing, setIsAPIProcessing] = useState(false);
  const [isopen, setIsopen] = useState(false);
  const [vcipStatus, setVcipStatus] = useState("");
  const [tokenExpireTime, setTokenExpireTime] = useState(
    sessionStorage.getItem("tokenEpireTime") || null
  );
  const [isTokenZero, setIsTokenZero] = useState(false);
  const [hasTokenExpired, updateHasTokenExpired] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  window.onpopstate = () => {
    navigate(null);
  };
  let timeoutForUpdateToken;

  // var intervalConferenceId;
  const vcipkeyData = sessionStorage.getItem("vcipkey");
  const langidData = sessionStorage.getItem("langid");
  const userTokendata = sessionStorage.getItem("UserTokendata");
  const InitiateVCFQStorage = sessionStorage.getItem("InitiateVCFQStorage");

  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { streamingType, streamingURL, enableStreamLoadBalance, HundredMsTemplateId, enableGetCutomerKey, showBookSlotOption = true } = dynamicSettingObj;
  const isEnableStreamLoadBalalnce = enableStreamLoadBalance == "true"
  const isEnableGetCutomerKey = enableGetCutomerKey == "true"
  const showBookSlotButton = isFeatureFlagEnabled("","",showBookSlotOption)
  const agentDiscrepancyStatus = useSelector((state)=>state.HomeReducer.storeAgentDiscrepancyStatus)
  const agentDiscrepancy = sessionStorage.getItem("agentDiscrepancyStatus")
  

  // const streamingType = process.env.REACT_APP_STREAMING_TYPE;

  useEffect(() => {
    if(agentDiscrepancyStatus == "0" || agentDiscrepancy =="0"){
      sessionStorage.setItem("backButtonHappenAferEnd",true)
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    }
    if (tokenExpireTime) {
      expSetTimeOutVal = setTimeout(() => {
        setTokenExpireTime(0);
        updateHasTokenExpired(true);
        sessionStorage.removeItem("UserTokendata");
      }, tokenExpireTime * 1000);
    }
    return () => {
      clearTimeout(expSetTimeOutVal);
      sessionStorage.removeItem("tokenEpireTime");
    };
  }, []);

  useEffect(() => {
    getVcipDetails();
    sessionStorage.removeItem("isVideoStreamDisconnected");
    if (userTokendata || InitiateVCFQStorage) {
      const InitiateVCFQStorageParse = JSON.parse(InitiateVCFQStorage);
      if (
        InitiateVCFQStorageParse?.tokennumber === "0" ||
        InitiateVCFQStorageParse?.isscheduled === "1"
      ) {
        setInitiateVCFQ(InitiateVCFQStorageParse);
      } else if (vcipStatus?.isdovkyc != "0" ) {
        getUpdatedTokenDetailsEvent();
      } 
      // else {
      //   setInitiateVCFQ(InitiateVCFQStorageParse);
      //   // getSingleCustomer(InitiateVCFQStorageParse?.videoconfsessionurl, InitiateVCFQStorageParse?.videoconfsessionid)
      // }
    } else if (vcipStatus?.isdovkyc != "0") {
      initiateVideoConference();
    }
    //   if (userTokendata || InitiateVCFQStorage){
    //     var tokenValue = JSON.parse(InitiateVCFQStorage);
    //     setIsTokenZero(tokenValue?.tokennumber === "0")
    //  }
    return () => {
      // clearInitiateConference();
      clearInterval(updateTokenInterval);
      setIntervalConferenceId(null);
      sessionStorage.removeItem("UserTokendata");
    };
  }, []);

  const getVcipDetails = () => {
    const refId = sessionStorage.getItem("vcipref");
    const model = {
      vcipref: refId,
    };
    console.log("*** Getting VCIP details");

    dispatch(
      actionGetVcipDetails({ model: model, callback: getVcipDetailsData })
    );
  };

  const getVcipDetailsData = (data) => {
    // console.log(data, "Vcipstatus");
    console.log("*** VCIP details received");
    setVcipStatus(data);
  };

  useEffect(() => {
    if (
      InitiateVCFQ?.tokennumber === "0" ||
      isRescheduled ||
      InitiateVCFQ?.isscheduled === "1"
    ) {
      clearInitiateConference();
    }
    if (InitiateVCFQ?.isscheduled === "1" || isRescheduled) {
      // SEND USER TO SCHEDULE
      navigate(RouteNames.RESCHEDULE, {
        replace: true,
        state: { InitiateVCFQ: InitiateVCFQ },
      });
    }
    if (InitiateVCFQ?.respcode === "425") {
      // toast(InitiateVCFQ?.respdesc);
      clearInterval(updateTokenInterval);
      sessionStorage.removeItem("UserTokendata");
      sessionStorage.setItem("tokenSessionExpire", "SessionExpire");
      setTimeout(() => {
        navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      }, 2000);
    }
  }, [InitiateVCFQ, isRescheduled]);

  const initiateVideoConference = () => {
    const model = {
      vcipkey: vcipkeyData,
      langid: langidData,
    };
    console.log("*** initiating video conference");

    dispatch(
      actionGetInitiateConferenceQueueSagaAction({
        model: model,
        callback: getInitiateVCFQData,
      })
    );
  };

  const clearInitiateConference = () => {
    clearInterval(updateTokenInterval);
    setIntervalConferenceId(null);
  };

  const addsinglecustomer = (data) => {
    const customerData = sessionStorage.getItem("user")
    const customerdetails = JSON.parse(customerData)
    const userData = sessionStorage.getItem("user")
    const ParesedData = JSON.parse(userData)
    const ref4Data =  ParesedData?.priority || "";
    const model = {
      streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(data?.videoconfsessionurl) : streamingURL,
      body: {
        "acccode": customerdetails?.acccode,
        "accname": customerdetails?.clientname,
        "name": customerdetails?.cust_name,
        "vcipkey": customerdetails?.vcipkey,
        "vcipid": customerdetails?.vcipid,
        "videoconfsessionid": data?.videoconfsessionid,
        "videoconfsessionurl": isEnableStreamLoadBalalnce ? data?.videoconfsessionurl : streamingURL,
        "mobileno": customerdetails?.cust_mobile,
        "cuslang": sessionStorage.getItem("langid"),
        "customerloc": sessionStorage.getItem("location"),
        "joinstatus": "1",
        "isscheduled": "0",
        "sdate": "NA",
        "stime": "NA",
        "customeripstatus": "sss",
        "isalert": "1",
        "assignedAgent": "",
        "customerJoinStatus":"0",
        "priority":ref4Data
      }
    };
    dispatch(actionAddSingleCustomer({
      model: model,
      callback: (res) => addSingleCustomerRes(res, data?.videoconfsessionid, data?.videoconfsessionurl)
    }))
  }

  const getInitiateVCFQData = (data) => {
    if (isEnableGetCutomerKey) {
      setIsTokenZero(true)
      sessionStorage.setItem("InitiateVCFQStorage", JSON.stringify(data));
      sessionStorage.setItem("videoconfsessionurl", JSON.stringify(data?.videoconfsessionurl))
      setInitiateVCFQ(data);
      if (timer === null || timer !== data?.tokenwaittimer) {
        setTimer(parseInt(data?.tokenwaittimer));
        const expTime = (data?.tokenwaittimer) || 300;
        setTokenExpireTime(expTime);
        updateHasTokenExpired(false);
        expSetTimeOutVal = setTimeout(() => {
          setTokenExpireTime(0);
          updateHasTokenExpired(true);
          sessionStorage.removeItem("UserTokendata");
        }, expTime * 1000);
      }
      getUpdatedTokenDetailsEvent();
    } else {
      setIsTokenZero(parseInt(data?.tokennumber) == 0)
      sessionStorage.setItem("InitiateVCFQStorage", JSON.stringify(data));
      sessionStorage.setItem("videoconfsessionurl", JSON.stringify(data?.videoconfsessionurl))
      setInitiateVCFQ(data);
      if (timer === null || timer !== data?.tokenwaittimer) {
        setTimer(parseInt(data?.tokenwaittimer));
      }
      if (
        parseInt(data?.tokennumber) >= 0 &&
        data?.isscheduled === "0" &&
        data?.respcode !== "425" &&
        (vcipStatus?.isdovkyc != "0")
      ) {
        getUpdatedTokenDetailsEvent();
      } else {
        clearInitiateConference();
        setIntervalConferenceId(null);
      }
    }
  };

  const getSingleCustomer = (videoconfsessionurl, videoconfsessionid) => {
    const model = {
      streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
      body: {
        "videoconfsessionid": videoconfsessionid
      }
    };
    dispatch(actionGetSingleCustomer({
      model: model,
      callback: getSingleCustomerRes
    }))
  }

  const addSingleCustomerRes = (res, videoconfsessionurl, videoconfsessionid) => {
    // getSingleCustomer(videoconfsessionurl, videoconfsessionid)
    const initialDateTime = moment();
    localStorage.setItem('initialDateTime', initialDateTime.format());
    setTimeout(() => {
      console.log("*** Navigating to video call session route");
      navigate("/session/" + InitiateVCFQ?.videoconfsessionid, {
        replace: true,
      });
    }, 1000);
  }

  const getSingleCustomerRes = () => {

  }


  const handleRetry = () => {
    console.log("*** clicked on retry button");
    const url = sessionStorage.getItem("link");
    sessionStorage.removeItem("isReloaded");
    const urlArr = url?.split("=");
    localStorage.clear();
    sessionStorage.clear();
    navigate("/d=" + urlArr[1], { replace: true });
  };

  const getUpdatedTokenData = (data) => {
    setIsTokenZero(parseInt(data?.tokennumber) === 0)
    if (data?.respcode === "425") {
      clearInterval(updateTokenInterval);
      sessionStorage.removeItem("UserTokendata");
    } else if (data?.respcode === "522") {
      sessionStorage.setItem("tokenSessionExpire", "SessionExpire");
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    } else {
      if (parseInt(data?.tokennumber) === 0) {
        const expTime = (data?.tokenwaittimer * 60) || 300;
        sessionStorage.setItem("waittimer", data?.waittimer)
        setTokenExpireTime(expTime);
        updateHasTokenExpired(false);
        expSetTimeOutVal = setTimeout(() => {
          setTokenExpireTime(0);
          updateHasTokenExpired(true);
          sessionStorage.removeItem("UserTokendata");
        }, expTime * 1000);
      }
    }
    sessionStorage.setItem("InitiateVCFQStorage", JSON.stringify(data));
    setInitiateVCFQ(data);
    const isDataAvailable = Object.values(InitiateVCFQ).length;
    if (
      (isDataAvailable === 0 && data?.respcode === "200") ||
      InitiateVCFQ?.tokennumber !== data?.tokennumber
    ) {
      sessionStorage.setItem("UserTokendata", JSON.stringify(data));
      if (data?.tokennumber != "0") {
        let sen = data?.tokenwaittimer;
        let number;
        let timerreduce;
        let pat = /\d+/; // this matches any sequence of digits
        const match = sen.match(pat);
        if (match) {
          number = parseInt(match[0]); // convert the matched string to a number
        }
        const tokentimerreduce = setInterval(() => {

          if (number == 0) {
            clearInterval(tokentimerreduce);
            timeoutForUpdateToken = setTimeout(() => {
              getUpdatedTokenDetailsEvent()
            }, 1000)
          } else {
            timerreduce = (parseInt(number) - 1);
            // console.log("222222",timerreduce)
            number = timerreduce;
            clearTimeout(timeoutForUpdateToken);
          }
        }, 1000);
      }
    }
    if (timer === null || timer !== data?.tokenwaittimer) {
      setTimer(parseInt(data?.tokenwaittimer));
    }
    // if (data?.tokennumber === "0") {
    //   if (updateTokenInterval) {
    //     clearInterval(updateTokenInterval);
    //   }
    // }
  };

  const getUpdatedTokenDetailsEvent = () => {
    // let intervalId = setInterval(() => {
    // updateTokenInterval = setInterval(() => {
    // if (intervalConferenceId === "" || intervalConferenceId === null || intervalConferenceId === undefined) {
    //   setIntervalConferenceId(intervalId);
    // }
    const model = {
      vcipkey: vcipkeyData,
      langid: langidData,
    };
    dispatch(
      actionGetUpdatedTokenSagaAction({
        model: model,
        callback: getUpdatedTokenData,
      })
    );
    // }, 1000);
  };

  const bookslot = async () => {
    // navigate(RouteNames.RESCHEDULE)
    // clearInitiateConference();
    await clearInterval(updateTokenInterval);
    await setIntervalConferenceId(null);
    await setIsRescheduled(true);
    navigate(RouteNames.RESCHEDULE, {
      replace: true,
      state: { InitiateVCFQ: InitiateVCFQ },
    });
  };

  const cancelledSchedule = (data) => {
    setIsRescheduled(false);
    initiateVideoConference();
  };

  // const joinVideoSession = () => {
  //   setIsAPIProcessing(false);
  //   if (intervalConferenceId) {
  //     clearInterval(intervalConferenceId);
  //   }
  //   sessionStorage.setItem(
  //     "videoconfsessionid",
  //     InitiateVCFQ?.videoconfsessionid
  //   );
  //   sessionStorage.removeItem("InitiateVCFQStorage");
  //   setTimeout(() => {
  //     navigate("/session/" + InitiateVCFQ?.videoconfsessionid);
  //   }, 1000);
  // };

  // LIVEKIT - CREATE TOKEN
  const createTokenLiveKit = () => {
    const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
    if (InitiateVCFQ?.videoconfsessionid) {
      const model = {
        streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
        body: {
          room: InitiateVCFQ?.videoconfsessionid,
          participantName: `customer_${InitiateVCFQ?.videoconfsessionid}`,
          canSubscribe: true,
        }
      };
      dispatch(
        createLivekitTokenSagaAction({
          model: model,
          callback: getLivekitToken,
        })
      );
    } else {
      toast.error("Video session failed");
    }
  };

  const getLivekitToken = (data) => {
    if (data?.token && data?.wssURL) {
      sessionStorage.setItem('token', data?.token);
      sessionStorage.setItem('wssURL', data?.wssURL);
      // if (isEnableGetCutomerKey) {
      //   setIsAPIProcessing(false);
      //   if (intervalConferenceId) {
      //     clearInterval(intervalConferenceId);
      //   }
      //   sessionStorage.setItem(
      //     "videoconfsessionid",
      //     InitiateVCFQ?.videoconfsessionid
      //   );
      //   sessionStorage.removeItem("InitiateVCFQStorage");
      //   addsinglecustomer(InitiateVCFQ)
      //   setTimeout(() => {
      //     console.log("*** Navigating to video call session route");
      //     navigate("/session/" + InitiateVCFQ?.videoconfsessionid, {
      //       replace: true,
      //     });
      //   }, 1000);
      // } else {
        joinVideoSession();
      // }
    } else {
      toast.error("Video session response failed");
    }
  };

  //100msLive
  // createroom 
  const create100msLiveRoom = () => {
    const model = {
      "name": InitiateVCFQ?.videoconfsessionid,
      "description": "original room",
      "template_id": HundredMsTemplateId
    }
    dispatch(create100msLiveRoomSagaAction({
      model,
      callback: (res) => get100msliveCreatedRoomData(res),
    }))
  }

  const get100msliveCreatedRoomData = (data) => {
    if (data?.enabled == true) {
      createRooomCode(data)
    }
    sessionStorage.setItem("actualroomid", data?.id)
  }

  //create roomcode
  const createRooomCode = (data) => {
    const model = {
      roomid: data?.id,
      role: "customer"
    }
    dispatch(create100msLiveRoomCodeSagaAction({
      model,
      callback: (res) => get100msLiveRoomCodedata(res)
    }))
  }

  const get100msLiveRoomCodedata = async (data) => {
    if (data?.enabled == true) {
      sessionStorage.setItem("roomcodefor100mslive", data?.code)
      joinVideoSession();
    }
  }

  const joinVideoSession = () => {
    setIsAPIProcessing(true);
    const location = sessionStorage.getItem("location");
    const geolocation = sessionStorage.getItem("geolocation");
    const internetspeed = document.getElementById("customerinternet").innerText;
    const customernet = internetspeed.split(" ")[1];
    const ip = sessionStorage.getItem("ip");
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    let outcoming = "";
    if (connection) {
      outcoming =
        connection.rtt +
        " " +
        (connection.effectiveType === "4g"
          ? connection.effectiveType
          : "Low Speed");
    }
    const model = {
      vcipkey: vcipkeyData,
      custdetails: {
        internetspeed: `${customernet} Mb/s`,
        location: location,
        geolocation: geolocation,
        nw_incoming: connection ? `${connection.downlink}` : "",
        nw_outgoing: outcoming,
        videoresolution: `${window.screen.width} x ${window.screen.height}`,
        browserdetails: browserDetails,
        // os: browser?.os,
      },
      agentdetails: {
        ip: "10.10.10.10",
        location: "",
        geolocation: "",
        nw_incoming: "",
        nw_outgoing: "",
        videoresolution: "",
      },
    };
    console.log("*** Video session request success");
    dispatch(
      joinVideoSessionAction({ model: model, callback: joinVideoSessionData })
    );
  };

  const joinVideoSessionData = (data) => {
    if (data?.respcode === "200") {
     
      console.log("*** Video session response");
      console.log("*** Video session request success");
      setIsAPIProcessing(false);
      if (intervalConferenceId) {
        clearInterval(intervalConferenceId);
      }
      sessionStorage.setItem(
        "videoconfsessionid",
        InitiateVCFQ?.videoconfsessionid
      );
      sessionStorage.removeItem("InitiateVCFQStorage");

      if(isEnableGetCutomerKey){
        addsinglecustomer(InitiateVCFQ)
      }else{
        setTimeout(() => {
          console.log("*** Navigating to video call session route");
          navigate("/session/" + InitiateVCFQ?.videoconfsessionid, {
            replace: true,
          });
        }, 1000);
      }
    
    } else {
      console.log("*** Video session request failed with", data?.respcode);
      sessionStorage.removeItem("UserTokendata");
      sessionStorage.setItem("tokenSessionExpire", "SessionExpire");
      setTimeout(() => {
        console.log("*** Navigating to KYC completed route");
        navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      }, 2000);
    }
  };

  const movetovideocheckpage = () => {
    console.log("*** customer clicked on join now");
    setIsTokenZero(true);
    // setIsopen(true);
    // joinVideoSession()
  };

  const handleTokenExpire = () => {
    console.log("*** Token expired");
    clearTimeout(expSetTimeOutVal);
    setTokenExpireTime(0);
    updateHasTokenExpired(true);
  };

  const clientName = useSelector((state) => state.HomeReducer.clientName);

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;
  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;
  const isippb = clientName === CleintNames?.IPPB ? true : false;

  let starttime;
  let endtime;
  if (vcipStatus) {
    starttime = vcipStatus?.vkyctime?.split("T")[0];
    endtime = vcipStatus?.vkyctime?.split("T")[1];
  }

  return (
    <>
      {(vcipStatus?.isdovkyc == "0" || vcipStatus?.isholiday == "1") &&
        !isRescheduled &&
        InitiateVCFQ?.isscheduled === "0" ? (
        <>
          <Header
            title={"Agents Unavailable"}
            navigate={() => navigate(-1)}
            isClientTideAccess={isClientTideAccess}
            hideHeader={false}
          />
          <article className="app-body">
            <div className="agentunavailableimg">
              <img src="images/agentunavaailable.png" />
            </div>
            {isClientTideAccess && vcipStatus?.isholiday == "1" ? (
              <div className="agentunavailabletxt"><Text tid="holiday" /></div>
            ) : (
              <div className="agentunavailabletxt"><Text tid="agents_unavailable" /></div>
            )}
            {isClientTideAccess ? (
              <>
                {vcipStatus?.isholiday == "1" ? (
                  <div className="agentunavailableinfo">{vcipStatus?.holiday_reason}</div>
                ) : (
                  <div className="agentunavailableinfo">
                    <Text tid="sorry_we_missed_you" /> <br />
                    <Text tid="please_open_the_app_at_a_time_that_works_best_for_you_between" />{" "}
                    <span
                      style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}
                    >
                      {starttime}
                    </span>{" "}
                    to{" "}
                    <span
                      style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}
                    >
                      {endtime}
                    </span>{" "}
                    <Text tid="to_complete_the_sign_up" />
                  </div>
                )}
              </>
            ) : (
              <div className="agentunavailableinfo">
                <Text tid="agents_currently_unavailable_Please_try_again_between" />{" "}
                <span style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}>
                  {starttime}
                </span>{" "}
                <Text tid="to" />{" "}
                <span style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}>
                  {endtime}
                </span>{" "}
                <Text tid="or_Book_a_new_time_slot" />
              </div>
            )}
            <div
              className="joinnowbtntide"
              style={{
                paddingBottom: "4%",
                backgroundColor: isClientTideAccess ? "#F1F2F3" : "#FFFFFFF2",
              }}
            >
              {isClientTideAccess || isippb ? null : (
                <AppFooter
                  // btnName= {isClientTideAccess ? "Book slot for later" : "Book a slot"}
                  btnName={!isClientTideAccess && showBookSlotButton && <Text tid="book_slot" />}
                  // isClientTideAccess = {isClientTideAccess}
                  navigate={bookslot}
                />
              )}
            </div>
          </article>
        </>
      ) : (
        <>
          {InitiateVCFQ?.isscheduled === "0" && !isRescheduled ? (
            <>
              <Header
                title={isClientTideAccess ? " " : <Text tid="title_token" />}
                // navigate={() => navigate(-1)}
                navigate={null}
                // isClientTideAccess={isClientTideAccess}
                hideHeader={false}
              />
              <article className="app-body">
                <div className="tidetokenpageadjust">
                  {hasTokenExpired ? (
                    <>
                      {!isClientTideAccess ? (
                        <Text tid="Token has been expired" />
                      ) : (
                        <TokenNumberCmp
                          InitiateVCFQ={InitiateVCFQ}
                          timer={timer}
                          isClientTideAccess={isClientTideAccess}
                          expireTime={tokenExpireTime}
                          hasTokenExpired={hasTokenExpired}
                          starttime={starttime}
                          endtime={endtime}
                          isEnableGetCutomerKey={isEnableGetCutomerKey}
                        />
                      )}
                    </>
                  ) : (
                    <TokenNumberCmp
                      InitiateVCFQ={InitiateVCFQ}
                      timer={timer}
                      isClientTideAccess={isClientTideAccess}
                      expireTime={tokenExpireTime}
                      starttime={starttime}
                      endtime={endtime}
                      isEnableGetCutomerKey={isEnableGetCutomerKey}
                    />
                  )}
                  {!isClientTideAccess && (
                    <AppFooter
                      btnName={<Text tid="book_slot" />}
                      isJoinCall={
                        isEnableGetCutomerKey ? true : InitiateVCFQ?.tokennumber === "0" ? true : false
                      }
                      showBookSlotOption={!isEnableGetCutomerKey}
                      isippb={isippb}
                      isDisabled={
                        InitiateVCFQ?.enablebookslot !== "1" ||
                          InitiateVCFQ?.isscheduled === "1" ||
                          // !updateTokenInterval ||
                          isAPIProcessing
                          ? true
                          : false
                      }
                      navigate={
                        InitiateVCFQ?.enablebookslot !== "1" ||
                          InitiateVCFQ?.isscheduled === "1"
                          ? null
                          : bookslot
                      }
                      joinVideoSession={
                        isEnableGetCutomerKey && tokenExpireTime || InitiateVCFQ?.tokennumber === "0" && tokenExpireTime
                          ? movetovideocheckpage
                          : handleRetry
                      }
                      hasTokenExpired={hasTokenExpired}
                    />
                  )}
                  <div
                    className="joinnowbtntide"
                    style={{
                      backgroundColor: isClientTideAccess
                        ? "#F1F2F3"
                        : "#FFFFFFF2",
                      paddingBottom: isClientTideAccess ? "8%" : "",
                    }}
                  >
                    {isClientTideAccess && (
                      <>
                        <AppFooter
                          btnName={hasTokenExpired ? "Retry" : "Join Now"}
                          isJoinCall={
                            isEnableGetCutomerKey ? true : InitiateVCFQ?.tokennumber === "0" ? true : false
                          }
                          isClientTideAccess={isClientTideAccess}
                          isDisabled={
                            // InitiateVCFQ?.enablebookslot !== "1" ||
                            // InitiateVCFQ?.isscheduled === "1" ||
                            // // !updateTokenInterval ||
                            // isAPIProcessing
                            InitiateVCFQ?.tokennumber !== "0" ? true : false
                          }
                          // navigate={
                          //   InitiateVCFQ?.enablebookslot !== "1" ||
                          //   InitiateVCFQ?.isscheduled === "1"
                          //     ? null
                          //     : bookslot
                          // }
                          joinVideoSession={
                            isEnableGetCutomerKey && tokenExpireTime || InitiateVCFQ?.tokennumber === "0" && tokenExpireTime
                              ? movetovideocheckpage
                              : handleRetry
                            // InitiateVCFQ?.tokennumber === "0" ? joinVideoSession : null
                          }
                          hasTokenExpired={hasTokenExpired}
                        />
                        {/* <button
                          className="btn "
                          type="button"
                          style={{
                            background: "transparent",
                            borderRadius: "256px",
                            height: "44px",
                            fontWeight: "500",
                            fontSize: "16px",
                            lineHeight: "20px",
                            width: "99%",
                            border: "none",
                            color: "#1929D6",
                            fontFamily: "ProximaNova-bold",
                            marginTop: "-10px",
                          }}
                          onClick={bookslot}
                          isClientTideAccess={isClientTideAccess}
                        >
                          Book slot for later
                        </button> */}
                      </>
                    )}
                  </div>
                </div>
                {!isClientTideAccess && (
                  <></>
                  // <H4
                  //   className="title mb-2"
                  //   title={"Note:"}
                  //   color={cssProperties?.pagetitle_heading?.font_color}
                  //   fontSize={cssProperties?.pagetitle_heading?.font_size}
                  // />
                )}

                {!isClientTideAccess && (
                  <Instructions iskinabank={iskinabank} />
                )}
              </article>
            </>
          ) : null}
        </>
      )}

      {/* {isopen && (
        <Micvideocheckmodel
          setIsopen={setIsopen}
          cssProperties={cssProperties}
          RouteNames={RouteNames}
          isClientTideAccess={isClientTideAccess}
          joinVideoSessionreq={joinVideoSession}
          id={InitiateVCFQ?.videoconfsessionid}
          handleTokenExpire={handleTokenExpire}
          hasTokenExpired={hasTokenExpired}
        />
      )} */}

      {/* 100msLive */}
      {(isTokenZero && vcipStatus?.isdovkyc != "0" && (streamingType === customeCodes.STREAMING_TYPE_100MS_LIVE_VALUE)) && (
        <MicvideocheckModal100msLive
          setIsopen={setIsTokenZero}
          cssProperties={cssProperties}
          RouteNames={RouteNames}
          isClientTideAccess={isClientTideAccess}
          joinVideoSessionreq={isEnableGetCutomerKey ? joinVideoSession : create100msLiveRoom}
          id={InitiateVCFQ?.videoconfsessionid}
          handleTokenExpire={handleTokenExpire}
          hasTokenExpired={hasTokenExpired}
        />
      )}

      {/* LIVEKIT */}
      {(isTokenZero && vcipStatus?.isdovkyc != "0" && (streamingType === customeCodes.STREAMING_TYPE_LIVEKIT_VALUE)) && (
        <MicvideocheckmodelLivekit
          setIsopen={setIsTokenZero}
          cssProperties={cssProperties}
          RouteNames={RouteNames}
          isClientTideAccess={isClientTideAccess}
          joinVideoSessionreq={createTokenLiveKit}
          id={InitiateVCFQ?.videoconfsessionid}
          handleTokenExpire={handleTokenExpire}
          hasTokenExpired={hasTokenExpired}
        />
      )}

      {((isTokenZero === true) && vcipStatus?.isdovkyc != "0" && (streamingType === customeCodes.STREAMING_TYPE_OPENVIDU_VALUE)) && (
        <Micvideocheckmodel
          setIsopen={setIsTokenZero}
          cssProperties={cssProperties}
          RouteNames={RouteNames}
          isClientTideAccess={isClientTideAccess}
          joinVideoSessionreq={joinVideoSession}
          id={InitiateVCFQ?.videoconfsessionid}
          handleTokenExpire={handleTokenExpire}
          hasTokenExpired={hasTokenExpired}
        />
      )
      }
      {/* {(InitiateVCFQ?.isscheduled === "1" || isRescheduled) && (
        <Reschedule
          InitiateVCFQ={InitiateVCFQ}
          isRescheduled={isRescheduled}
          setIsRescheduled={setIsRescheduled}
          cancelledSchedule={cancelledSchedule}
          joinVideoSession={joinVideoSession}
          isClientTideAccess={isClientTideAccess}
          id={InitiateVCFQ?.videoconfsessionid}
        />
      )} */}
    </>
  );
};

export default TokenNumber;
