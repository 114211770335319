import { isDisabled } from "@testing-library/user-event/dist/utils";
import { number } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Text } from "../../Pages/Language/Language";
import Button from "../Elements/Button";
import H4 from "../Elements/H4";

const PanDetailsEditCmp = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  // console.log(props);

  const ButtonDisabledButtonConditionals = () => {
    let temp = false

    if (props?.isClientTideAccess) {
      return temp
    }

    if (!props?.panDetails?.name || !props?.panDetails?.fname || !props?.panDetails?.dob || props?.panDetails?.pannumber.length != 10) {
      temp = false
    }
    return temp

  }

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };


  return (
    <>
      {!props?.isClientTideAccess && (
        <>
          <H4
            className="title mb-2"
            title={<Text tid="ocr_info" />}
            color={cssProperties?.pagetitle_heading?.font_color}
            fontSize={cssProperties?.pagetitle_heading?.font_size}
          />
          {props?.iskinabank && <div>{props?.panDetails?.idtype}</div>}
          <div className="ocr-pht mx-auto">
            <img
              src={
                props.panDetails?.pht
                  ? "data:image/png;base64," + props.panDetails?.pht
                  : "/images/no-image-icon.png"
              }
              alt="photo"
            />
          </div>
        </>
      )}

      {props?.isClientTideAccess ? (
        <>
          {props?.photo ? (
            <div className="tideaadhaarphoto">
              <img
                src={"data:image/png;base64," + props?.photo}
                alt="photo"
                width={80}
                height={80}
                className="tideaadhharimg"
              />
            </div>
          ) : (
            <div className="tideaadhaarphoto">
              <img
                src="/images/OIP.jfif"
                alt="photo"
                width={80}
                height={80}
                className="tideaadhharimg"
              />
            </div>
          )}
          {/* {props?.iskinabank ?
<div><h5>{props?.idtype}</h5></div>:""} */}
          <div>
            <label className="tablelabel">
              <Text tid="name" />
            </label>
            <input
              type="text"
              className="tableinput"
              defaultValue={props?.edtname}
              readOnly
            />
          </div>
          <div>
            <label className="tablelabel">
              <Text tid="father_name" />{" "}
            </label>
            <input
              type="text"
              className="tableinput"
              defaultValue={props?.edtfname}
              readOnly
            />
          </div>
          <div>
            <label className="tablelabel">
              <Text tid="date_of_birth" />
            </label>
            <input
              type="text"
              className="tableinput"
              defaultValue={props?.edtdob}
              readOnly
            />
            <div className="calendericon">
              {props?.aadharDetails?.dob ? <img src="/images/Union.png" /> : ""}
            </div>
          </div>
          <div>
            <label className="tablelabel">
              <Text tid="pan_number" />{" "}
            </label>
            <input
              type="text"
              className="tableaddressinput"
              defaultValue={props?.edtpannumber}
              readOnly
            />
          </div>
        </>
      ) : (
        <div>
          <div className="form-floating mb-4 cus-ocrInput">
            <input
              type="text"
              className={`form-control ${props?.defname ? "" : "border-danger"
                }`}
              name="edtname"
              onChange={props.handleChange}
              defaultValue={props?.defname}
              id="floatingInput"
            />
            <i className="fa-solid fa-pen cus-PencilIcon"></i>
            <label htmlFor="floatingInput">
              <Text tid="name" />
            </label>
            {(!props?.defname) && (
              <p className="errorTxt">
                * <Text tid="please_enter_your_name" />{" "}
              </p>
            )}
          </div>
          <div className="form-floating mb-4 position-relative ">
            <input
              type="text"
              className={`form-control ${props?.deffname ? "" : "border-danger"
                }`}
              name="edtfname"
              onChange={props.handleChange}
              defaultValue={props?.deffname}
              id="floatingInput2"
            />
            <i className="fa-solid fa-pen cus-PencilIcon"></i>
            <label htmlFor="floatingInput2">
              <Text tid="father_name" />
            </label>
            {(!props?.deffname) && (
              <p className="errorTxt">
                * <Text tid="please_enter_father_name" />{" "}
              </p>
            )}
          </div>
          <div className="form-floating mb-4 position-relative">
            <input
              type="date"
              className={`form-control ${(props?.defdob)
                  // (props?.iskinabank && (props?.dob == "NA" || props?.dob))
                  ? ""
                  : "border-danger"
                }`}
              name="edtdob"
              onChange={props.handleChange}
              defaultValue={props?.defdob}
              id="edtdob"
              max={getTodayDate()}
            />
            <label htmlFor="edtdob">
              <Text tid="dob" />
            </label>
            <p className="errorTxt">
            {!props?.defdob ? (
              <span>* <Text tid="please_enter_dob" /></span>
            ) : (
              <span>{props?.errors}</span>
            )}
            </p>
            {/* {(!props?.defdob) && (
              <p className="errorTxt">
                * <Text tid="please_enter_dob" />{" "}
              </p>
            )}
            {(props?.errors) && (
              <p className="errorTxt">
                {props?.errors}
              </p>
            )} */}
            {/* {props?.iskinabank && props?.edtdob == "" && (
              <p className="errorTxt">
                * <Text tid="please_enter_dob" />{" "}
              </p>
            )} */}
            {/* {(!props?.edtdob || props?.iskinabank && !(props?.edtdob =="NA"))? <p className="errorTxt">*Please enter DOB</p>:null} */}
          </div>
          <div className="form-floating mb-4 position-relative">
            <input
              type="name"
              className={`form-control ${(props?.defpannumber) ? "" : "border-danger"
                }`}
              name="edtpannumber"
              onChange={props.handleChange}
              defaultValue={props?.defpannumber}
              id="edtpannumber"
            />
            <i className="fa-solid fa-pen cus-PencilIcon"></i>
            <label htmlFor="edtpannumber">
              {props?.iskinabank ? (
                <Text tid="id_number" />
              ) : (
                <Text tid="pan_number" />
              )}
            </label>
            {!props?.iskinabank &&
              (!props?.defpannumber || props?.defpannumber.length !== 10) && (
                <p className="errorTxt">
                  * <Text tid="please_check_pan" />{" "}
                </p>
              )}
          </div>


          {props?.iskinabank && (
            <div className="form-floating mb-4 position-relative ">
              <input
                type="text"
                className={`form-control ${props?.edtgender ? "" : "border-danger"
                  }`}
                name="edtgender"
                onChange={props.handleChange}
                defaultValue={props?.edtgender}
                id="floatingInput2"
              />
              <i className="fa-solid fa-pen cus-PencilIcon"></i>
              <label htmlFor="floatingInput2">
                <Text tid="gender" />
              </label>
              {!props?.edtgender && (
                <p className="errorTxt">
                  * <Text tid="please_enter_gender" />{" "}
                </p>
              )}
            </div>
          )}

          {props?.iskinabank && props?.edtcountry !== "NA" && (
            <div className="form-floating mb-4 position-relative ">
              <input
                type="text"
                className={`form-control ${props?.edtcountry ? "" : "border-danger"
                  }`}
                name="edtcountry"
                // onChange={props.handleChange}
                defaultValue={props?.edtcountry.split(":")[1]}
                id="floatingInput2"
                readOnly
              />
              {/* <i className="fa-solid fa-pen cus-PencilIcon"></i> */}
              <label htmlFor="floatingInput2">
                {props?.edtcountry.split(":")[0]}
              </label>
              {/* {!props?.edtcountry && (
              <p className="errorTxt">*Please enter country</p>
            )} */}
            </div>
          )}
          {props?.iskinabank && props?.edtdateofexpiry !== "NA" && (
            <div className="form-floating mb-4 position-relative ">
              <input
                type="date"
                className={`form-control ${props?.edtdateofexpiry ? "" : "border-danger"
                  }`}
                name="edtdateofexpiry"
                // onChange={props.handleChange}
                defaultValue={props?.kinadateofexpiry}
                id="floatingInput2"
                readOnly
              />
              {/* <i className="fa-solid fa-pen cus-PencilIcon"></i> */}
              <label htmlFor="floatingInput2">
                {props?.edtdateofexpiry.split(":")[0]}
                {/* <Text tid="gender" /> */}
              </label>
              {/* {!props?.edtdateofexpiry && (
              <p className="errorTxt">*Please enter date of expiry</p>
            )} */}
            </div>
          )}
        </div>
      )}
      {props?.iskinabank && (
        <>
          {props?.ref3 !== "NA" && (
            <div className="form-floating mb-4 position-relative ">
              <input
                type="text"
                className={`form-control ${props?.ref3 ? "" : "border-danger"}`}
                name="ref3"
                // onChange={props.handleChange}
                defaultValue={props?.ref3?.split(":")[1]}
                id="floatingInput2"
                readOnly
              />
              {/* <i className="fa-solid fa-pen cus-PencilIcon"></i> */}
              <label htmlFor="floatingInput2">
                {props?.ref3.split(":")[0]}
              </label>
            </div>
          )}
          {props?.ref4 !== "NA" && (
            <div className="form-floating mb-4 position-relative ">
              <input
                type="text"
                className={`form-control ${props?.ref4 ? "" : "border-danger"}`}
                name="ref4"
                // onChange={props.handleChange}
                defaultValue={props?.ref4?.split(":")[1]}
                id="floatingInput2"
                readOnly
              />
              {/* <i className="fa-solid fa-pen cus-PencilIcon"></i> */}
              <label htmlFor="floatingInput2">
                {props?.ref4.split(":")[0]}
              </label>
            </div>
          )}
          {props?.ref5 !== "NA" && (
            <div className="form-floating mb-4 position-relative ">
              <input
                type="text"
                className={`form-control ${props?.ref5 ? "" : "border-danger"}`}
                name="ref5"
                // onChange={props.handleChange}
                defaultValue={props?.ref5?.split(":")[1]}
                id="floatingInput2"
                readOnly
              />
              {/* <i className="fa-solid fa-pen cus-PencilIcon"></i> */}
              <label htmlFor="floatingInput2">
                {props?.ref5.split(":")[0]}
              </label>
            </div>
          )}
          {props?.ref6 !== "NA" && (
            <div className="form-floating mb-4 position-relative ">
              <input
                type="text"
                className={`form-control ${props?.ref6 ? "" : "border-danger"}`}
                name="ref6"
                // onChange={props.handleChange}
                defaultValue={props?.ref6?.split(":")[1]}
                id="floatingInput2"
                readOnly
              />
              {/* <i className="fa-solid fa-pen cus-PencilIcon"></i> */}
              <label htmlFor="floatingInput2">
                {props?.ref6.split(":")[0]}
              </label>
            </div>
          )}
          {props?.ref7 !== "NA" && (
            <div className="form-floating mb-4 position-relative ">
              <input
                type="text"
                className={`form-control ${props?.ref7 ? "" : "border-danger"}`}
                name="ref7"
                // onChange={props.handleChange}
                defaultValue={props?.ref7?.split(":")[1]}
                id="floatingInput2"
                readOnly
              />
              {/* <i className="fa-solid fa-pen cus-PencilIcon"></i> */}
              <label htmlFor="floatingInput2">
                {props?.ref7.split(":")[0]}
              </label>
            </div>
          )}
          {props?.ref8 !== "NA" && (
            <div className="form-floating mb-4 position-relative ">
              <input
                type="text"
                className={`form-control ${props?.ref8 ? "" : "border-danger"}`}
                name="ref8"
                // onChange={props.handleChange}
                defaultValue={props?.ref8?.split(":")[1]}
                id="floatingInput2"
                readOnly
              />
              <i className="fa-solid fa-pen cus-PencilIcon"></i>
              <label htmlFor="floatingInput2">
                {props?.ref8.split(":")[0]}
              </label>
            </div>
          )}
        </>
      )}

      <div
        className={"pankycbutton text-center"}
        style={{
          backgroundColor: props?.isClientTideAccess
            ? "rgb(241, 243, 244)"
            : "#FFFFFFF2",
          paddingBottom: "7%",
          paddingTop: props?.isClientTideAccess ? "" : "2%",
        }}
      >
        <Button
          className="btn"
          type="button"
          disabled={
            ButtonDisabledButtonConditionals()
            // props?.iskinabank ? (props?.edtdob =="NA") ? "":props?.edtdob =="" ?!props?.edtdob :"" : !props?.edtdob ||
            // (props?.edtdob ==" " || (props?.iskinabank && (props?.edtdob =="NA"))) ? "" : "border-danger"
            // props?.edtpannumber.length !== 10
          }
          isClientTideAccess={props?.isClientTideAccess}
          click={props?.submitEditPanEdtails}
          title={<Text tid="submit" />}
          color={cssProperties?.button?.text_color}
          fontSize={cssProperties?.button?.font_size}
          backgroundColor={cssProperties?.button?.color}
        />
      </div>
    </>
  );
};
export default PanDetailsEditCmp;
