import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import OccupationDropDown from './EmploymentDropDown';
import SelectEmployementDetails from './EmployementDetail';
import { useNavigate } from 'react-router-dom';
import RouteNames from '../../../Constants/RouteNames';

const EmploymentLayout = () => {
  let navigate = useNavigate();
  const agentDiscrepancyStatus = useSelector((state)=>state.HomeReducer.storeAgentDiscrepancyStatus)
  const agentDiscrepancy = sessionStorage.getItem("agentDiscrepancyStatus")

  useEffect(()=>{
    if(agentDiscrepancyStatus == "0" || agentDiscrepancy =="0"){
      sessionStorage.setItem("backButtonHappenAferEnd",true)
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    }
  },[])
    const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
    const { streamingType, streamingURL,enableOccupationdropdown } = dynamicSettingObj;
    const ShowEmploymentDropdown = enableOccupationdropdown == "true" ? true : false

    const element = ShowEmploymentDropdown
        ? < OccupationDropDown/>
        :<SelectEmployementDetails />
  return (
    <>{element}</>
  )
}

export default EmploymentLayout
