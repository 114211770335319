import React from "react";
import { Text } from '../../../Pages/Language/Language';

import "./TideSlelectKyc.css";

export default function TideSelectKycProcessCmp() {
  return (
    <div className="kycmpmain">
      <div className="selfiimg">
        <img src="/images/Selfie.png" />
      </div>
      <div className="paragraph">
        <Text tid="please_ensure_you_have_following_in_hand"  /> :
        </div>
        <div className="textmainss">
          <img src="/images/Vector172.svg" className="tickicon"></img>
          <div className="hometext">
            <Text tid="aadhar_id" />
          </div>
        </div>
        <div className="subtext">
          <img src="/images/Vector172.svg" className="tickicon"></img>
          <div className="hometext">
            <Text tid="physical_pan_card_not_e_pan_card" />
          </div>
        </div>
    </div>
  );
}
