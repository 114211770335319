import React, { Suspense, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// import Gleap from 'gleap';
import Tracker from '@openreplay/tracker';
import trackerAxios from '@openreplay/tracker-axios';
import * as Sentry from "@sentry/react";
import HyperDX from '@hyperdx/browser';

import RouteNames from "./Constants/RouteNames";
import routes from "./PageRoutes/PageRoutes";
import { LanguageProvider } from "./Pages/Language/Language";
import { storeDynamicURLAction, storePageData, getCustomerEnvDataSagaAction } from "./Store/SagaActions/CommonSagaActions";
import Loading from "./Components/Loading/Loading";
import CleintNames from "./Constants/ClientNames";
import { selectPeers, useHMSStore, selectLocalPeer, selectRemotePeers, useHMSActions, selectBroadcastMessages, selectHMSMessages, selectTrackByID, selectPeerAudioByID, selectSpeakers, useHMSNotifications, HMSNotificationTypes, useScreenShare, useDevices, DeviceType } from "@100mslive/react-sdk";

import { getVcipStatusesAction } from "./Store/SagaActions/GetVcipDetailsSagaActions";
// const Home = React.lazy(() => import("./Pages/Home/Home"));
// const Layout = React.lazy(() => import("./Layout/Layout"));
// const PageNotFound = React.lazy(() =>
//   import("./Pages/PageNotFound/PageNotFound")
// );
import Home from "./Pages/Home/Home";
import Layout from "./Layout/Layout";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import CosmosEKYC from "./Pages/SelectKYCProcess/AadhaarKYC/CosmosEKYC";
// import Gleap from 'gleap';


function getFaviconEl() {
  return document.getElementById("favicon");
}
var parsedUser;
var tracker;

function App() {

  const [isNetworkAvailable, setIsNetworkAvailable] = useState(false);
  const vcipDetails = useSelector((state) => state.VcipReducer.vcipDetails);
  const [intiOpenReplay, setIntiOpenReplay] = useState(false)
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { openreplayURL, openreplayKey, gleapId, app_env } = dynamicSettingObj;
  const [envError, setEnvError] = useState("");
  // const customerVcipStatues = useSelector((state) => state.VcipReducer.customerVcipStatues);
  const isPageDataAvailable = useSelector(
    (state) => state.HomeReducer.isPageDataAvailable
  );

  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  let navigate = useNavigate();
  let location = useLocation();
  // const params = useParams();
  let dispatch = useDispatch();

  const pathName = location?.pathname;
  const vcipref = sessionStorage.getItem("vcipref");
  const data = sessionStorage.getItem('user');
  const accName = JSON.parse(data)?.acccode;


  window.onpopstate = () => {
    navigate(null);
  }


  useEffect(() => {
    window.addEventListener("online", function () {
      console.log("I am connected to the internet");
      setIsNetworkAvailable(false);
    })

    window.addEventListener("offline", function () {
      console.log("Disconnected...so sad!!!");
      setIsNetworkAvailable(true);
    })
    if (pathName?.includes("/session/")) {
      sessionStorage.setItem("isReloaded", true);
    }
    if (!vcipref && !pathName?.includes("/d=")) {
      sessionStorage.clear()
      // if (!vcipref) {
      navigate("/", { replace: true });
      // clearInterval(inter)

    }

    // const baseURL = process.env.REACT_APP_BASE_URL || "https://vcipapi.syntizen.com/uatv2/api/vkyc/";
    // console.log("iniasdfasdfsdf================================");
    // HyperDX.init({
    //   apiKey: '971e7ea7-763c-4068-93c4-15962ccb9b1c',
    //   service: 'LQ-UAT-CUSTOMER',
    //   tracePropagationTargets: [baseURL], // Set to link traces from frontend to backend requests
    //   consoleCapture: true, // Capture console logs (default false)
    //   advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
    // });

    dispatch(getCustomerEnvDataSagaAction({ model: { acccode: accName || "" }, callback: getCustomerEnvDataResp }));
  }, []);

  const getCustomerEnvDataResp = (data) => {

    if (data?.respcode === "200") {
      dispatch(storeDynamicURLAction(data));
      setEnvError(false);

    } else {
      // toast.error("Something went wrong, Environment data missing")
      setEnvError(true);
    }
  }

  useEffect(() => {
    const user = sessionStorage.getItem("user");
    parsedUser = JSON.parse(user);
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = "data:image/png;base64," + parsedUser?.acclogo;
    if (pathName !== RouteNames.TOKEN_NUMBER) {
      sessionStorage.removeItem("UserTokendata");
    }
    if (pathName !== "/" && (isPageDataAvailable !== pathName) && !pathName?.includes("/session/")) {
      dispatch(storePageData(pathName))
    }
  }, [location]);

  // useEffect(() => {
  //   if(location?.pathname != RouteNames.KYC_PROCESS_COMPLETED){
  //     const unloadCallback = (event) => {
  //       event.preventDefault();
  //       event.returnValue = "Are you sure you want to leave the page?";
  //       return "";
  //     };

  //     window.addEventListener("beforeunload", unloadCallback);
  //     return () => {
  //       window.removeEventListener("beforeunload", unloadCallback);
  //     };
  //   }
  // }, []);

  // useEffect(() => {
  // document.addEventListener("keydown", function (event) {
  //   var key = event.key || event.keyCode;
  //   if (key == 123) {
  //     return false;
  //   } else if ((event.ctrlKey && event.shiftKey && key == 73)
  //     || (event.ctrlKey && event.shiftKey && key == 74)
  //     || (event.ctrlKey && event.shiftKey && key == 67)) {
  //     return false;
  //   }
  // }, false);
  // }, []);

  // TO CHECK STATUS OF AADHAR AND PAN 
  // useEffect(() => {
  //   console.log("pathName", pathName);
  //   console.log("___>", pathName === RouteNames.TOKEN_NUMBER
  //     , pathName !== RouteNames.KYC_PROCESS_COMPLETED
  //     , pathName !== '/'
  //     // || (pathName !== "/" && (isPageDataAvailable !== pathName) && !pathName?.includes("/session/"))
  //     , customerVcipStatues?.panstatus === "0"
  //     , customerVcipStatues?.kycstatus === "0");
  //   if ((pathName === RouteNames.TOKEN_NUMBER
  //     && pathName !== RouteNames.KYC_PROCESS_COMPLETED
  //     && pathName !== '/')
  //     // || (pathName !== "/" && (isPageDataAvailable !== pathName) && !pathName?.includes("/session/"))
  //     // || customerVcipStatues?.panstatus === "0"
  //     // || customerVcipStatues?.kycstatus === "0"
  //   ) {
  //     console.log("=========>pathNamepathNamepathNamepathNamepathName", pathName);
  //     // getVcipStatusesUpdate();
  //   }

  //   // CHECK ROUTE ACCESS
  //   if ((
  //     (pathName === RouteNames.SELECT_KYC_PROCESS
  //       || pathName === RouteNames.AADHAR_KYC_KYC_DIGILOCCKER
  //       || pathName === RouteNames.AADHAR_KYC_PROCESS
  //       || pathName === RouteNames.AADHAR_KYC_PROCESS_UPLOAD
  //       || pathName === RouteNames.AADHAR_KYC_PROCESS_DOWNLOAD
  //       || pathName === RouteNames.SELECT_PAN_KYC_PROCESS
  //       || pathName === RouteNames.PAN_KYC_PROCESS
  //       || pathName === RouteNames.PAN_CAPTURE
  //       || pathName === RouteNames.PAN_DECLARATION)
  //     && (customerVcipStatues?.panstatus === "1"
  //       && customerVcipStatues?.kycstatus === "1"))) {
  //     console.log("********===========> yes");
  //     const url = sessionStorage.getItem("link");
  //     window.location.replace(url);
  //   }
  // }, [
  //   pathName,
  //   customerVcipStatues?.panstatus,
  //   customerVcipStatues?.kycstatus
  // ]);


  // const getVcipStatusesUpdate = () => {
  //   const model = {
  //     refid: parsedUser?.vcipid,
  //     reftype: "1",
  //     acccode: "",
  //   };
  //   dispatch(
  //     getVcipStatusesAction({ model: model, callback: getVcipStatusesData })
  //   );
  // };

  // const getVcipStatusesData = (data) => { };

  const getRoutes = (routes) => {
    const authkey = sessionStorage.getItem("authkey");
    const vcipkey = sessionStorage.getItem("vcipkey");
    if (
      (vcipDetails?.vcipkey && vcipDetails?.authkey) ||
      (authkey && vcipkey)
    ) {
      return routes.map((route, i) => {
        return route.element ? (
          <Route
            key={i}
            path={route.path}
            element={route.element}
          >
            {route.children && route.children.length > 0
              ? route.children.map((childRoute, j) => (
                <Route
                  key={childRoute.path + j}
                  path={childRoute.path}
                  element={childRoute.element}
                />
              ))
              : null}
          </Route>
        ) : null;
      });
    }
    return;
  };

  // console.log("performance?.navigation", performance);
  const clientName = useSelector((state) => state.HomeReducer.clientName);

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false

  const getLoader = () => {
    return envError  ?
      <div className="container w-100 vh-100 d-flex justify-content-center align-items-center">
        <div style={{textAlign:"center"}}>
          
        There was an error with the connection. <p>Please click refresh or reload from your browser</p>

        <span 
          style={{
            backgroundColor: "#1929d6",
            padding: "5px 15px 6px 15px",
            letterSpacing: "0.5px",
            borderRadius:"20px",
            color: "#ffffff",
          }}
          onClick={()=>window?.location?.reload()}>Refresh</span>
        </div>
      </div> : <Loading
        text={"Loading..."}
        isClientTideAccess={isClientTideAccess}
      />
  }

  return (
    <>
      <div
        className="warning-popup text-center h-100 w-100 align-items-center justify-content-center flex-column"
        style={{ display: isNetworkAvailable ? 'flex' : 'none' }}>
        <img
          src="../images/wifi-off-svg.svg"
          className="mb-2 w-50"
          alt=""
        />
        <p className="text-center text-white mb-0">Try to connect...!</p>
      </div>
      <LanguageProvider>
        <>
          <Toaster
            toastOptions={{
              duration: 3000,
              style: { 
                zIndex: 99999999 ,
                background: '#e5e5e5',
              },
              success: {
                style: {
                  background: 'white',
                },
              },
            }}
          />
          {envError === false ? <Suspense fallback={<Loading text={"Loading..."} isClientTideAccess={isClientTideAccess} />}>
            <Routes>
              <Route path="/" element={<Layout />}>
              {/* <Route path="/" element={<CosmosEKYC />}> */}
                <Route path="/d=:id" element={<Home tracker={tracker} intiOpenReplay={intiOpenReplay} />} />
                {/* <Route index element={<Home />} /> */}

                {getRoutes(routes)}
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Outlet />
          </Suspense> : getLoader()}
        </>
      </LanguageProvider>
    </>
  );
}

export default App;
